"use client"

import { Cabin, Holiday, Itinerary } from "@/app/types/lambda-types";
import React, { useState } from "react";
import Image from '@/app/components/globals/image';
import styles from './HolidayResult.module.css';
import FormattedDate from "@/app/components/globals/offer-date-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faShip } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Promotions from "../../promotions";
import Included from "./Included";
import getIncluded from "@/utils/data-manipulation/getIncluded";
import CustomLink from "../../link";
import PackageIcon from "../package-icon";
import FromPrice from "../../from-price";
import PriceGrid from "./PriceGrid";
import clsx from "clsx";
import PricePerNight from "./PricePerNight";

const ItineraryDisplay = ({ itinerary }: { itinerary?: { collection: Itinerary[] } }) => {
  if (!itinerary?.collection) return null;

  const filteredPorts = itinerary.collection.filter(port => port.type !== 'sailing');

  return (
    <div className={styles.itineraryContainer}>
      <div className={styles.itinerary}>
        {filteredPorts.map((port, key, array) => (
          <React.Fragment key={key}>
            <span>{port.name}</span>
            {key < array.length - 1 && <span> | </span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const HolidayResult = ({ holiday, portGuideImageIndex, style }: { holiday: Holiday, portGuideImageIndex?: "" | "1" | "2" | "3" | "4" | undefined, style?: 'result' | 'starbuy' }) => {
  const initialCabin = holiday.cheapestCabin || {} as Cabin;
  const [selectedCabin, setSelectedCabin] = useState<Cabin>(initialCabin);
  const [selectedDate, setSelectedDate] = useState<string>(holiday.start_date);

  const getBackgroundImage = () => {
    if (portGuideImageIndex !== undefined && portGuideImageIndex !== "") {
      let index = Number(portGuideImageIndex) - 1;
      const collection = holiday.port_guide_lifestyle_images?.collection;
      if (collection && collection.length > 0) {
        index = (Number(index) % collection.length);
        return collection[index]?.image_url;
      }
    }
    return holiday.port_guides?.collection[0]?.image_path || holiday.background_image || holiday.ship?.search_results_image;
  }

  return (
    <>
      <article className={clsx(styles.wrapper, style === 'starbuy' ? styles.starbuy : '')}>
        <header className={styles.header}>
          <Image asset={getBackgroundImage() || ''} />
          <div className={styles.darkGradientOverlay} />
          <section className={styles.headerTop}>
            <PackageIcon holidayType={holiday.type} />
            <div className={styles.headerDetails}>
              <h3>{holiday.name}</h3>
              <ItineraryDisplay itinerary={holiday.itinerary} />
              <div className={styles.details}>
                <span>
                  <FontAwesomeIcon icon={faCalendarDay as IconProp} />
                  <FormattedDate date={selectedDate} />
                </span>
                <span>
                  <FontAwesomeIcon icon={faShip as IconProp} />
                  {holiday.sail_nights}-night full-board cruise aboard {holiday.ship?.name}
                </span>
              </div>
            </div>
          </section>

          <section className={styles.headerBottom}>
            <div className={styles.promotions}>
              <Promotions promotions={holiday.promotions} />
            </div>
            {holiday.type !== 'cruiseonly' ?
              <div className={styles.includedSection}>
                <span className={styles.includedLabel}>
                  INCLUDED:
                </span>
                <div className={styles.includeds}>
                  {holiday.extras ? getIncluded(holiday.extras).map((extra, key) => (
                    <Included key={key} holidayExtra={extra} />
                  )) : null}
                </div>
              </div> : null}
          </section>



        </header>
        <footer className={styles.footer}>
          <div className={styles.footerTop}>
            <div className={styles.cruiseLineLogo}>
              <Image
                asset={holiday.line?.svg_logo || ''}
                className={styles.cruiseLineLogoImage}
              />
            </div>
            <FromPrice
              price={
                selectedCabin.price_pp ?
                  Number(selectedCabin.price_pp).toFixed(0) :
                  holiday.from_price ?
                    Number(holiday.from_price).toFixed(0) :
                    'Check'
              }
              fontSize="58px"
              style={style}
            />
          </div>
          <PricePerNight nights={holiday.duration} price={selectedCabin.price_pp ? Number(selectedCabin.price_pp) : holiday.from_price ? Number(holiday.from_price) : 0} style={style} />
          <PriceGrid
            holiday={holiday}
            style={style}
            selectedCabin={selectedCabin}
            setSelectedCabin={setSelectedCabin}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <div className={styles.buttonContainer}>
            <CustomLink href={holiday.url} className={styles.viewButton} buttonSize="large" asButton>
              View Deal
            </CustomLink>
          </div>
        </footer>
      </article>
      <p className={styles.disclaimer}>
        *Prices on display are from and per person based on 2 sharing the lowest cabin grade unless otherwise stated. Gratuities are not included in any of these offers unless otherwise stated. Booking fee & Baggage charge may apply. No Booking fee is payable when you book online. Cruise Nation and Cruise Line Terms & Conditions apply. E&OE.
      </p>
    </>
  )
};

export default HolidayResult;