const startPorts = [
  { label: "Any Port", value: null },
  { label: "Abu Dhabi", value: "Abu Dhabi" },
  { label: "Adelaide", value: "Adelaide" },
  { label: "Alesund", value: "Alesund" },
  { label: "Alexandria", value: "Alexandria" },
  { label: "Alicante", value: "Alicante" },
  { label: "Amador", value: "Amador" },
  { label: "Amsterdam", value: "Amsterdam" },
  { label: "Anchorage", value: "Anchorage" },
  { label: "Ancona", value: "Ancona" },
  { label: "Antigua", value: "Antigua" },
  { label: "Antwerp", value: "Antwerp" },
  { label: "Aqaba", value: "Aqaba" },
  { label: "Arles", value: "Arles" },
  { label: "Arrecife", value: "Arrecife" },
  { label: "Ashdod", value: "Ashdod" },
  { label: "Athens", value: "Athens" },
  { label: "Auckland", value: "Auckland" },
  { label: "Bali", value: "Bali" },
  { label: "Baltimore", value: "Baltimore" },
  { label: "Baltra", value: "Baltra" },
  { label: "Barbados", value: "Barbados" },
  { label: "Bangkok", value: "Bangkok" },
  { label: "Baoshan", value: "Baoshan" },
  { label: "Barcelona", value: "Barcelona" },
  { label: "Bari", value: "Bari" },
  { label: "Barranquilla", value: "Barranquilla" },
  { label: "Basel", value: "Basel" },
  { label: "Bayonne", value: "Bayonne" },
  { label: "Beijing", value: "Beijing" },
  { label: "Belem", value: "Belem" },
  { label: "Belfast", value: "Belfast" },
  { label: "Bali", value: "Bali" },
  { label: "Bergen", value: "Bergen" },
  { label: "Bilbao", value: "Bilbao" },
  { label: "Bodrum", value: "Bodrum" },
  { label: "Bordeaux", value: "Bordeaux" },
  { label: "Boston", value: "Boston" },
  { label: "Bratislava", value: "Bratislava" },
  { label: "Bridgetown", value: "Bridgetown" },
  { label: "Brindisi", value: "Brindisi" },
  { label: "Brisbane", value: "Brisbane" },
  { label: "Bristol", value: "Bristol" },
  { label: "Broome", value: "Broome" },
  { label: "Budapest", value: "Budapest" },
  { label: "Buenos Aires", value: "Buenos Aires" },
  { label: "Busan", value: "Busan" },
  { label: "Cadiz", value: "Cadiz" },
  { label: "Cagliari", value: "Cagliari" },
  { label: "Cairns", value: "Cairns" },
  { label: "Cairo", value: "Cairo" },
  { label: "Caldera", value: "Caldera" },
  { label: "Callao", value: "Callao" },
  { label: "Cannes", value: "Cannes" },
  { label: "Cape Liberty", value: "Cape Liberty" },
  { label: "Cape Town", value: "Cape Town" },
  { label: "Cartagena", value: "Cartagena" },
  { label: "Casa De Campo", value: "Casa De Campo" },
  { label: "Casablanca", value: "Casablanca" },
  { label: "Catania", value: "Catania" },
  { label: "Chalon-Sur-Saone", value: "Chalon-Sur-Saone" },
  { label: "Charleston", value: "Charleston" },
  { label: "Chicago", value: "Chicago" },
  { label: "Churchill", value: "Churchill" },
  { label: "Civitavecchia", value: "Civitavecchia" },
  { label: "Cococay", value: "Cococay" },
  { label: "Cochin", value: "Cochin" },
  { label: "Cologne", value: "Cologne" },
  { label: "Colon", value: "Colon" },
  { label: "Copacabana", value: "Copacabana" },
  { label: "Copenhagen", value: "Copenhagen" },
  { label: "Corfu", value: "Corfu" },
  { label: "Cork", value: "Cork" },
  { label: "Crete", value: "Crete" },
  { label: "Dakar", value: "Dakar" },
  { label: "Darwin", value: "Darwin" },
  { label: "Denali", value: "Denali" },
  { label: "Dijon", value: "Dijon" },
  { label: "Doha", value: "Doha" },
  { label: "Dover", value: "Dover" },
  { label: "Dubai", value: "Dubai" },
  { label: "Dublin", value: "Dublin" },
  { label: "Dubrovnik", value: "Dubrovnik" },
  { label: "Dun Laoghaire", value: "Dun Laoghaire" },
  { label: "Dundee", value: "Dundee" },
  { label: "Durban", value: "Durban" },
  { label: "Durnstein", value: "Durnstein" },
  { label: "Edinburgh", value: "Edinburgh" },
  { label: "Falmouth", value: "Falmouth" },
  { label: "Fort de France", value: "Fort de France" },
  { label: "Fort Lauderdale", value: "Fort Lauderdale" },
  { label: "Fortaleza", value: "Fortaleza" },
  { label: "Frankfurt", value: "Frankfurt" },
  { label: "Fremantle", value: "Fremantle" },
  { label: "Fuerte Amador", value: "Fuerte Amador" },
  { label: "Funchal", value: "Funchal" },
  { label: "Fusina", value: "Fusina" },
  { label: "Galveston", value: "Galveston" },
  { label: "Genoa", value: "Genoa" },
  { label: "Giurgiu", value: "Giurgiu" },
  { label: "Gran Canaria", value: "Gran Canaria" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Haifa", value: "Haifa" },
  { label: "Half Moon Cay", value: "Half Moon Cay" },
  { label: "Halifax", value: "Halifax" },
  { label: "Halkidiki", value: "Halkidiki" },
  { label: "Hamburg", value: "Hamburg" },
  { label: "Hamburg", value: "Hamburg" },
  { label: "Hamilton", value: "Hamilton" },
  { label: "Helsinki", value: "Helsinki" },
  { label: "Heraklion", value: "Heraklion" },
  { label: "Hilo", value: "Hilo" },
  { label: "Ho Chi Minh City", value: "Ho Chi Minh City" },
  { label: "Hobart", value: "Hobart" },
  { label: "Hong Kong Kai Tak", value: "Hong Kong Kai Tak" },
  { label: "Hong Kong, China", value: "Hong Kong, China" },
  { label: "Honiara", value: "Honiara" },
  { label: "Honolulu", value: "Honolulu" },
  { label: "Ibiza", value: "Ibiza" },
  { label: "Ijmuiden", value: "Ijmuiden" },
  { label: "Incheon", value: "Incheon" },
  { label: "Istanbul", value: "Istanbul" },
  { label: "Itajai", value: "Itajai" },
  { label: "Izmir", value: "Izmir" },
  { label: "Jacksonville", value: "Jacksonville" },
  { label: "Jeddah", value: "Jeddah" },
  { label: "Jerusalem", value: "Jerusalem" },
  { label: "Johannesburg", value: "Johannesburg" },
  { label: "Juneau", value: "Juneau" },
  { label: "Kampong Cham", value: "Kampong Cham" },
  { label: "Kangerlussuaq", value: "Kangerlussuaq" },
  { label: "Keelung", value: "Keelung" },
  { label: "Kiel, Germany", value: "Kiel, Germany" },
  { label: "King George Falls", value: "King George Falls" },
  { label: "King George Island", value: "King George Island" },
  { label: "Kobe", value: "Kobe" },
  { label: "Kos", value: "Kos" },
  { label: "Kuala Lumpur", value: "Kuala Lumpur" },
  { label: "Kusadasi", value: "Kusadasi" },
  { label: "Kyoto, Japan", value: "Kyoto, Japan" },
  { label: "La Goulette", value: "La Goulette" },
  { label: "La Rochelle", value: "La Rochelle" },
  { label: "La Romana", value: "La Romana" },
  { label: "La Spezia", value: "La Spezia" },
  { label: "La Valetta", value: "La Valetta" },
  { label: "Laem Chabang", value: "Laem Chabang" },
  { label: "Lahaina", value: "Lahaina" },
  { label: "Lake Garda", value: "Lake Garda" },
  { label: "Las Palmas", value: "Las Palmas" },
  { label: "Las Vegas", value: "Las Vegas" },
  { label: "Lautoka", value: "Lautoka" },
  { label: "Lavrion", value: "Lavrion" },
  { label: "Le Havre", value: "Le Havre" },
  { label: "Leith", value: "Leith" },
  { label: "Lima", value: "Lima" },
  { label: "Limassol", value: "Limassol" },
  { label: "Lisbon", value: "Lisbon" },
  { label: "Liverpool", value: "Liverpool" },
  { label: "Livorno", value: "Livorno" },
  { label: "London", value: "London" },
  { label: "Long Beach", value: "Long Beach" },
  { label: "Longyearbyen", value: "Longyearbyen" },
  { label: "Los Angeles", value: "Los Angeles" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Luxor", value: "Luxor" },
  { label: "Lyon", value: "Lyon" },
  { label: "Maceio", value: "Maceio" },
  { label: "Mahe", value: "Mahe" },
  { label: "Majorca", value: "Majorca" },
  { label: "Malaga", value: "Malaga" },
  { label: "Male", value: "Male" },
  { label: "Maloy", value: "Maloy" },
  { label: "Malta", value: "Malta" },
  { label: "Manaus", value: "Manaus" },
  { label: "Manila", value: "Manila" },
  { label: "Maputo", value: "Maputo" },
  { label: "Marghera", value: "Marghera" },
  { label: "Marseille", value: "Marseille" },
  { label: "Martinique", value: "Martinique" },
  { label: "Melbourne", value: "Melbourne" },
  { label: "Melk", value: "Melk" },
  { label: "Memphis", value: "Memphis" },
  { label: "Messina", value: "Messina" },
  { label: "Miami", value: "Miami" },
  { label: "Mobile", value: "Mobile" },
  { label: "Monaco", value: "Monaco" },
  { label: "Monfalcone", value: "Monfalcone" },
  { label: "Monte Carlo", value: "Monte Carlo" },
  { label: "Monterey", value: "Monterey" },
  { label: "Montevideo", value: "Montevideo" },
  { label: "Montreal", value: "Montreal" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Muscat", value: "Muscat" },
  { label: "Niagara Falls", value: "Niagara Falls" },
  { label: "Naha (Okinawa Is.)", value: "Naha (Okinawa Is.)" },
  { label: "Naples", value: "Naples" },
  { label: "Natal", value: "Natal" },
  { label: "New Orleans", value: "New Orleans" },
  { label: "New York", value: "New York" },
  { label: "Newcastle", value: "Newcastle" },
  { label: "Newhaven", value: "Newhaven" },
  { label: "Nice", value: "Nice" },
  { label: "Nome", value: "Nome" },
  { label: "Norfolk, USA", value: "Norfolk, USA" },
  { label: "Nuremberg", value: "Nuremberg" },
  { label: "Nuuk", value: "Nuuk" },
  { label: "Ocean Cay", value: "Ocean Cay" },
  { label: "Orlando", value: "Orlando" },
  { label: "Olbia", value: "Olbia" },
  { label: "Oranjestad", value: "Oranjestad" },
  { label: "Osaka", value: "Osaka" },
  { label: "Oslo", value: "Oslo" },
  { label: "Palermo", value: "Palermo" },
  { label: "Palma De Mallorca", value: "Palma De Mallorca" },
  { label: "Panama City", value: "Panama City" },
  { label: "Papeete", value: "Papeete" },
  { label: "Paranagua", value: "Paranagua" },
  { label: "Paris", value: "Paris" },
  { label: "Passau (Munich)", value: "Passau (Munich)" },
  { label: "Perth", value: "Perth" },
  { label: "Philadelphia", value: "Philadelphia" },
  { label: "Philipsburg", value: "Philipsburg" },
  { label: "Piraeus", value: "Piraeus" },
  { label: "Point-a-Pitre", value: "Point-a-Pitre" },
  { label: "Pond Inlet", value: "Pond Inlet" },
  { label: "Port Canaveral", value: "Port Canaveral" },
  { label: "Port Elizabeth, South Africa", value: "Port Elizabeth, South Africa" },
  { label: "Port Everglades", value: "Port Everglades" },
  { label: "Port Klang", value: "Port Klang" },
  { label: "Port Louis", value: "Port Louis" },
  { label: "Port Victoria, Seychelles", value: "Port Victoria, Seychelles" },
  { label: "Porta Praia", value: "Porta Praia" },
  { label: "Porto", value: "Porto" },
  { label: "Portsmouth", value: "Portsmouth" },
  { label: "Princess Cays", value: "Princess Cays" },
  { label: "Puerto Limon", value: "Puerto Limon" },
  { label: "Puerto Williams", value: "Puerto Williams" },
  { label: "Punta Arenas", value: "Punta Arenas" },
  { label: "Punta Cana", value: "Punta Cana" },
  { label: "Punta del Este", value: "Punta del Este" },
  { label: "Puntarenas", value: "Puntarenas" },
  { label: "Quebec City, Canada", value: "Quebec City, Canada" },
  { label: "Quito", value: "Quito" },
  { label: "Ravenna", value: "Ravenna" },
  { label: "Recife", value: "Recife" },
  { label: "Reykjavik", value: "Reykjavik" },
  { label: "Rhodes", value: "Rhodes" },
  { label: "Rio de Janeiro", value: "Rio de Janeiro" },
  { label: "Rome", value: "Rome" },
  { label: "Rosyth, Edinburgh", value: "Rosyth, Edinburgh" },
  { label: "Rotterdam", value: "Rotterdam" },
  { label: "Safaga", value: "Safaga" },
  { label: "Salvador", value: "Salvador" },
  { label: "Salou", value: "Salou" },
  { label: "Samana", value: "Samana" },
  { label: "San Antonio", value: "San Antonio" },
  { label: "Santorini", value: "Santorini" },
  { label: "San Cristobal Island, Galapagos Islands", value: "San Cristobal Island, Galapagos Islands" },
  { label: "San Diego", value: "San Diego" },
  { label: "San Francisco", value: "San Francisco" },
  { label: "San Juan", value: "San Juan" },
  { label: "Santa Cruz de la Palma", value: "Santa Cruz de la Palma" },
  { label: "Santa Cruz de Tenerife", value: "Santa Cruz de Tenerife" },
  { label: "Santo Domingo", value: "Santo Domingo" },
  { label: "Santos", value: "Santos" },
  { label: "Savona", value: "Savona" },
  { label: "Seattle", value: "Seattle" },
  { label: "Seoul", value: "Seoul" },
  { label: "Seville", value: "Seville" },
  { label: "Seward", value: "Seward" },
  { label: "Shanghai", value: "Shanghai" },
  { label: "Sharm El Sheikh", value: "Sharm El Sheikh" },
  { label: "Singapore", value: "Singapore" },
  { label: "Sir Bani Yas Island", value: "Sir Bani Yas Island" },
  { label: "Sokhna", value: "Sokhna" },
  { label: "Southampton", value: "Southampton" },
  { label: "Split", value: "Split" },
  { label: "St John's, Antigua", value: "St John's, Antigua" },
  { label: "St. John's, Newfoundland, Canada", value: "St. John's, Newfoundland, Canada" },
  { label: "Stockholm", value: "Stockholm" },
  { label: "Sydney", value: "Sydney" },
  { label: "Syracuse", value: "Syracuse" },
  { label: "Taipei", value: "Taipei" },
  { label: "Takoradi", value: "Takoradi" },
  { label: "Tampa", value: "Tampa" },
  { label: "Taranto", value: "Taranto" },
  { label: "Tarragona", value: "Tarragona" },
  { label: "Tel Aviv", value: "Tel Aviv" },
  { label: "Tenerife", value: "Tenerife" },
  { label: "Thessaloniki", value: "Thessaloniki" },
  { label: "Tilbury", value: "Tilbury" },
  { label: "Tokyo", value: "Tokyo" },
  { label: "Toulon", value: "Toulon" },
  { label: "Trieste", value: "Trieste" },
  { label: "Tromso", value: "Tromso" },
  { label: "Ushuaia", value: "Ushuaia" },
  { label: "Valencia", value: "Valencia" },
  { label: "Valparaiso", value: "Valparaiso" },
  { label: "Vancouver", value: "Vancouver" },
  { label: "Verona", value: "Verona" },
  { label: "Vega De Terron", value: "Vega De Terron" },
  { label: "Venice", value: "Venice" },
  { label: "Victoria", value: "Victoria" },
  { label: "Vienna", value: "Vienna" },
  { label: "Vigo", value: "Vigo" },
  { label: "Vilshofen", value: "Vilshofen" },
  { label: "Wachau Valley", value: "Wachau Valley" },
  { label: "Walvis Bay", value: "Walvis Bay" },
  { label: "Warnemunde", value: "Warnemunde" },
  { label: "Whittier", value: "Whittier" },
  { label: "Willemstad", value: "Willemstad" },
  { label: "Wurzburg", value: "Wurzburg" },
  { label: "Yokohama", value: "Yokohama" },
  { label: "Zadar", value: "Zadar" },
  { label: "Zanzibar", value: "Zanzibar" },
  { label: "Zeebrugge", value: "Zeebrugge" }
];

export default startPorts;