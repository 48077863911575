import { Holiday } from "@/app/types/lambda-types"
import Image from '@/app/components/globals/image';
import styles from './DynamicGroup.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDay, faLocationDot, faShip } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import PriceCircle from "../price-circle";
import CustomLink from "../link";
import Included from "../holiday/result/Included";
import getIncluded from "@/utils/data-manipulation/getIncluded";
import { getOfferDateFormat } from "@/app/components/globals/offer-date-format";
import ItineraryModal from "./itinerary-modal";
import ShipModal from "./ship-modal";
import { useState } from 'react';
import ExpandableHeading from "../expandable-heading";

type Props = {
  holiday: Holiday
}

const GroupItem = ({ holiday }: Props) => {
  const [clickPosition, setClickPosition] = useState<{ x: number; y: number }>();

  const handleOpenItinerary = (event: React.MouseEvent) => {
    const modal = document.getElementById(`itinerary-modal-${holiday.cn_uid}`) as HTMLDialogElement;
    if (modal) {
      setClickPosition({ x: event.clientX, y: event.clientY });
      modal.showModal();
    }
  };

  const handleOpenShipModal = (event: React.MouseEvent) => {
    const modal = document.getElementById(`ship-modal-${holiday.cn_uid}`) as HTMLDialogElement;
    if (modal) {
      setClickPosition({ x: event.clientX, y: event.clientY });
      modal.showModal();
    }
  };

  const getBackgroundImage = () => {
    return holiday.background_image || holiday.ship?.search_results_image;
  }

  getBackgroundImage();

  return (
    <article className={styles.wrapper}>
      <div className={styles.backgroundImage}>
        <Image asset={getBackgroundImage() || ''} />
      </div>
      <div className={styles.darkGradientOverlay}></div>
      <header className={styles.header}>
        <div className={styles.cruiseNationLogo}>
          <Image asset='https://a.storyblok.com/f/306333/150x150/6e56b55986/cn-icon-sqaure.svg' alt="Cruise Nation Logo" />
        </div>
        <div className={styles.itemHeader}>
          <ExpandableHeading text={holiday.name} />
          <p className={styles.detail}>
            <span>
              <FontAwesomeIcon icon={faCalendarDay as IconProp} />
              {getOfferDateFormat(holiday.start_date)}
            </span>
            <span>
              <FontAwesomeIcon icon={faShip as IconProp} />
              <span
                className={styles.clickableItem}
                onClick={handleOpenShipModal}
              >
                {holiday.ship?.name}
              </span>
            </span>
            <span className={styles.itemHeaderItem}>
              <FontAwesomeIcon icon={faLocationDot as IconProp} />
              <span
                className={styles.clickableItem}
                onClick={handleOpenItinerary}
              >
                View Itinerary
              </span>
            </span>
          </p>
          <ItineraryModal
            holiday={holiday}
            clickPosition={clickPosition}
          />
          <ShipModal
            holiday={holiday}
            clickPosition={clickPosition}
          />
        </div>
      </header>

      <div className={styles.body}>
        <PriceCircle price={holiday.from_price || 'Check'} />
        <footer className={styles.footer}>
          <div className={styles.includeds}>
            {
              holiday.extras && getIncluded(holiday.extras).length > 0 ?
                <>
                  <p>Included</p>
                  <div className={styles.included}>
                    {holiday.extras ? getIncluded(holiday.extras).map((extra, key) => (
                      <Included key={key} holidayExtra={extra} mergeHotels={true} />
                    )) : null}
                  </div>
                </> :
                <>
                  <p>Included</p>
                  <div className={styles.included}>
                    <Included holidayExtra={{
                      option_id: 0,
                      holiday_cn_uid: '',
                      cluster_id: 0,
                      category: 'cruise',
                      day: 1,
                      date: new Date,
                      nights: holiday.sail_nights,
                      type: 'cruise',
                      description: 'Full-board Cruise'
                    }} mergeHotels={true} />
                  </div>
                </>
            }
          </div>
        </footer>

        <CustomLink href={holiday.url} variant="primary" color="red" className={styles.viewButton} asButton>
          View Deal
        </CustomLink>
      </div>
    </article>
  );
}

export default GroupItem;