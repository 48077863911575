import React from 'react';
import { storyblokEditable } from '@storyblok/react/rsc';
import { render } from 'storyblok-rich-text-react-renderer';
import type { ContentStoryblok } from '@/app/types/component-types-sb';
import { blokReslover } from '../content_split_2_images/ContentSplitTwoImageTextImage';

const Content = ({ blok }: { blok: ContentStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className='text-content'>
      {
        blok.header ?
          (<h2 className='section-header'>{blok.header}</h2>) : null
      }
      {render(blok.content, {
        defaultBlokResolver: (name, props) => blokReslover(name, props)
      })}
    </div>
  );
}

export default Content;