import withErrorHandling from "@/app/lib/withErrorHandling";
import { ScheduledContentStoryblok } from "@/app/types/component-types-sb";
import { StoryblokServerComponent } from "@storyblok/react/rsc";
import dayjs from "dayjs";

const ScheduledContent = ({ blok }: { blok: ScheduledContentStoryblok }) => {
  const { hide_until, hide_after, content, content_when_hidden, force_hidden } = blok;
  const now = dayjs();
  const hideUntilDate = hide_until ? dayjs(hide_until) : null;
  const hideAfterDate = hide_after ? dayjs(hide_after) : null;

  const isBeforeHideUntil = hideUntilDate ? now.isBefore(hideUntilDate) : false;
  const isAfterHideAfter = hideAfterDate ? now.isAfter(hideAfterDate) : false;

  const isWithinVisibleTimeframe = (!hideUntilDate || !isBeforeHideUntil) &&
    (!hideAfterDate || !isAfterHideAfter);

  const shouldShowContent = !force_hidden && isWithinVisibleTimeframe;
  if (!shouldShowContent || !content || content.length === 0) {
    if (content_when_hidden && content_when_hidden.length > 0) {
      return (
        <>
          {content_when_hidden.map((nestedBlok) => (
            <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </>
      )
    }
    return null;
  }

  return (
    <>
      {content.map((nestedBlok) => (
        <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </>
  );
};

export default withErrorHandling(ScheduledContent, 'Unable to load scheduled content');


