'use client'
import { FuzzySearchStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from "./Search.module.css"
import { faSearch } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import React from 'react'
import Container from '@/app/components/globals/container';
import TextField from '@/app/components/globals/textfield';

const FuzzySearch = ({ blok }: { blok: FuzzySearchStoryblok }) => {

  const onSearch = async (value: string) => {
    const allItems = document.getElementsByClassName('grid_item');
    for (let i = 0; i < allItems.length; i++){
        const item = allItems[i] as HTMLElement;
        const stringExist = new RegExp(value, 'i').test(item.id);
        if(!stringExist && value.trim().length > 0){
            item.style.display = 'none'
        }else{
            item.style.display = 'block'
        }
    }
  }


  return (
    <div {...storyblokEditable(blok)} >
      <Container style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px'
      }}>
        <div className={styles.mainSearch}>
          <TextField
            placeholder="Search..."
            icon={faSearch}
            onKeyUp={e => onSearch(e.currentTarget.value)}
            className={styles.search}
            iconSize='lg'
            inputClassName={styles.inputStyle}
          />
        </div>
      </Container>
    </div>
  )
}

export default FuzzySearch