import {RefObject, useEffect} from 'react';

/**
 * Registers an event listener for clicks outside of the element
 * referenced by the passed ref.
 *
 * @param {RefObject<T>} ref - The ref to the element.
 * @param {() => void} callback - The function to call when an outside click happens.
 * @param {boolean} [addEventListener=true] - If false, the event listener will not
 *   be added. Useful if you want to control the adding of the listener yourself.
 * @returns {void}
 */

export const useClickOutside = <T extends HTMLElement>(ref: RefObject<T>,
  callback: () => void,
  addEventListener: boolean = true): void => {
  /**
   * Handles a click event. If the ref is defined and the click is outside
   * of the element, calls the callback function.
   *
   * @param {MouseEvent} event - The event to handle.
   * @returns {void}
   */
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    if (addEventListener) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [addEventListener, ref, callback]);
};