import { Holiday, Itinerary } from "@/app/types/lambda-types"
import styles from './ItineraryModal.module.css';
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/thin";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatDate } from "@/utils/configs";
import getOrdinal from "@/utils/data-manipulation/getOrdinal";
import { faClock, faHotel, faLocationDot, faPlane, faWater } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { faCalendar } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import CustomLink from "../../link";

// Types
type Props = {
  holiday: Holiday,
  clickPosition?: { x: number, y: number }
}

// Define our processed itinerary item types
type ConsecutiveSeaItem = {
  isConsecutiveSea: boolean,
  firstDay: number,
  lastDay: number,
  firstDate: string,
  lastDate: string
}

type EnhancedItineraryItem = Itinerary & {
  hideArriveTime?: boolean,
  hideDepartTime?: boolean
}

type ProcessedItem = EnhancedItineraryItem | ConsecutiveSeaItem;

const ItineraryModal = ({ holiday, clickPosition }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const itinerary = holiday.itinerary?.collection;

  // Modal handling functions
  const getScrollbarWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
  };

  const lockScroll = () => {
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    document.body.classList.add('lock-scroll');
  };

  const unlockScroll = () => {
    document.body.classList.remove('lock-scroll');
    document.body.style.removeProperty('--scrollbar-width');
  };

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.classList.add(styles.closing);
      dialogRef.current.addEventListener('animationend', () => {
        dialogRef.current?.close();
        dialogRef.current?.classList.remove(styles.closing);
        unlockScroll();
      }, { once: true });
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const dialogDimensions = dialogRef.current?.getBoundingClientRect();
    if (dialogDimensions) {
      const clickedInDialog = e.clientY >= dialogDimensions.top &&
        e.clientY <= dialogDimensions.bottom &&
        e.clientX >= dialogDimensions.left &&
        e.clientX <= dialogDimensions.right;

      if (!clickedInDialog) {
        handleClose();
      }
    }
  };

  // const handleViewDeal = () => {
  //   console.log(`Viewing deal for ${holiday.name}`);
  //   // Add your view deal logic here
  // };

  // Formatting functions
  const getDate = (date: string, time?: string): string => {
    const day = time ? formatDate(date + ' ' + time) : formatDate(date);
    const dayOfMonth = getOrdinal(day.date());
    return time ?
      `${dayOfMonth} ${day.format('MMM YYYY')} at ${day.format('HH:mm')}` :
      `${dayOfMonth} ${day.format('MMM YYYY')}`;
  };

  const formatTime = (timeString: string): string => {
    const parts = timeString.split(":");
    return `${parts[0]}:${parts[1]}`;
  };

  // Process itinerary to handle special cases
  const processItinerary = (itinerary: Itinerary[]): ProcessedItem[] => {
    if (!itinerary || itinerary.length === 0) return [];

    const processed: ProcessedItem[] = [];
    let i = 0;

    while (i < itinerary.length) {
      const current = itinerary[i];

      // Handle ports
      if (current.type !== 'sailing') {
        // Check for consecutive same ports
        if (current.type === 'port' &&
          i + 1 < itinerary.length &&
          itinerary[i + 1].type === 'port' &&
          current.name === itinerary[i + 1].name) {

          // Add first port without departure time
          processed.push({ ...current, hideDepartTime: true });

          // Add second port without arrival time
          processed.push({ ...itinerary[i + 1], hideArriveTime: true });

          i += 2; // Skip both ports as we've processed them
        } else {
          // Regular non-sea day item
          processed.push(current);
          i++;
        }
        continue;
      }

      // Handle sea days
      let consecutiveCount = 1;
      let j = i + 1;
      let hasSameDay = false;

      // Count consecutive sea days
      while (j < itinerary.length && itinerary[j].type === 'sailing') {
        if (itinerary[j].day === itinerary[j - 1].day) {
          hasSameDay = true;
        }
        consecutiveCount++;
        j++;
      }

      if (hasSameDay) {
        // If consecutive sea days with the same day number, only show the first one
        processed.push(current);
        i = j; // Skip duplicate days
      } else if (consecutiveCount >= 2) {
        // If 2 or more consecutive sea days, combine them
        processed.push({
          isConsecutiveSea: true,
          firstDay: itinerary[i].day,
          lastDay: itinerary[j - 1].day,
          firstDate: itinerary[i].arrive_date,
          lastDate: itinerary[j - 1].arrive_date
        });
        i = j; // Skip all these days
      } else {
        // Just add this sea day normally
        processed.push(current);
        i++;
      }
    }

    return processed;
  };

  // Effects
  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    // Watch for dialog open attribute
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
          if (dialog.hasAttribute('open')) {
            lockScroll();
          }
        }
      });
    });

    observer.observe(dialog, { attributes: true });

    return () => {
      observer.disconnect();
      unlockScroll(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') handleClose();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (dialogRef.current && clickPosition) {
      const translateX = clickPosition.x - (window.innerWidth / 2);
      const translateY = clickPosition.y - (window.innerHeight / 2);

      dialogRef.current.style.setProperty('--start-x', `${translateX}px`);
      dialogRef.current.style.setProperty('--start-y', `${translateY}px`);
    }
  }, [clickPosition]);

  // Process the itinerary once
  const processedItinerary = itinerary ? processItinerary(itinerary) : [];

  // Helper function to render icon based on type
  const renderLocationIcon = (itemType: string) => {
    switch (itemType) {
      case 'sailing':
        return <FontAwesomeIcon icon={faWater as IconProp} className={styles.portIcon} />;
      case 'other':
      case 'port':
        return <FontAwesomeIcon icon={faLocationDot as IconProp} className={styles.portIcon} />;
      case 'flight':
        return <FontAwesomeIcon icon={faPlane as IconProp} className={styles.portIcon} />;
      case 'accommodation':
        return <FontAwesomeIcon icon={faHotel as IconProp} className={styles.portIcon} />;
      default:
        return null;
    }
  };

  // Helper function to render arrival and departure times
  const renderTimesInfo = (item: EnhancedItineraryItem) => {
    // First day special handling
    if (item.day === 1 && item.depart_time && !item.hideDepartTime) {
      return (
        <>
          <br />
          <span className={styles.arriveDepart}>
            <FontAwesomeIcon icon={faClock as IconProp} style={{ width: '1em', height: '1em' }} />
            Depart {formatTime(item.depart_time)}
          </span>
        </>
      );
    }

    // Other days with arrival or departure times
    const showArrival = item.arrive_time && !item.hideArriveTime;
    const showDeparture = item.depart_time && !item.hideDepartTime;

    if (!showArrival && !showDeparture) return null;

    return (
      <span className={styles.arriveDepart}>
        <br />
        <FontAwesomeIcon icon={faClock as IconProp} style={{ width: '1em', height: '1em' }} />
        {showArrival && (
          <>Arrive {formatTime(item.arrive_time)}{showDeparture && <>&nbsp;&nbsp;&nbsp;</>}</>
        )}
        {showDeparture && (
          <>Depart {formatTime(item.depart_time)}</>
        )}
      </span>
    );
  };

  // Helper function to render dates
  const renderDates = (item: Itinerary) => {
    return (
      <>
        <FontAwesomeIcon icon={faCalendar as IconProp} />&nbsp;
        {item.arrive_date !== item.depart_date ? (
          <>
            {getDate(item.arrive_date)}<br />
            {getDate(item.depart_date)}
          </>
        ) : (
          getDate(item.arrive_date)
        )}
      </>
    );
  };

  // Render consecutive sea days
  const renderConsecutiveSeaDays = (item: ConsecutiveSeaItem, index: number) => (
    <tr key={`sea-${index}`} className={styles.sailingDay}>
      <td className={styles.dayNumber} style={{ minWidth: '42px' }}>
        <FontAwesomeIcon icon={faWater as IconProp} className={styles.portIcon} />
        {item.firstDay}-{item.lastDay}
      </td>
      <td style={{ height: '64px' }}>
        <strong>At sea</strong>
      </td>
      <td>
        <FontAwesomeIcon icon={faCalendar as IconProp} />&nbsp;
        {getDate(item.firstDate)} - {getDate(item.lastDate)}
      </td>
    </tr>
  );

  // Render regular itinerary item
  const renderRegularItineraryItem = (item: EnhancedItineraryItem, index: number) => (
    <tr key={index} className={item.type === 'sailing' ? styles.sailingDay : ''}>
      <td className={styles.dayNumber} style={{ minWidth: '42px' }}>
        {renderLocationIcon(item.type)}
        {item.day}
      </td>
      <td style={{ height: '64px' }}>
        <strong>{item.type === 'sailing' ? 'At sea' : item.name}</strong>
        {renderTimesInfo(item)}
      </td>
      <td>
        {renderDates(item)}
      </td>
    </tr>
  );

  return (
    <dialog
      id={`itinerary-modal-${holiday.cn_uid}`}
      ref={dialogRef}
      className={styles.modal}
      onClick={handleClick}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h4>{holiday.name}</h4>
          <button
            onClick={handleClose}
            className={styles.closeButton}
          >
            <FontAwesomeIcon icon={faXmark as IconProp} />
          </button>
        </div>

        <div className={styles.modalContent}>
          <p>
            {holiday.duration} night holiday departing on {getDate(holiday.start_date)} from {holiday.start_port} with {holiday.line?.name} aboard the {holiday.ship?.name}.
          </p>
          <h5>Cruise Itinerary:</h5>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Day</th>
                <th></th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {processedItinerary.length > 0 ? (
                processedItinerary.map((item, index) => (
                  'isConsecutiveSea' in item
                    ? renderConsecutiveSeaDays(item, index)
                    : renderRegularItineraryItem(item, index)
                ))
              ) : (
                <tr><td colSpan={3}>No itinerary available for this holiday.</td></tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={styles.modalFooter}>
          <CustomLink href={holiday.url} variant="primary" color="red" className={styles.viewButton} asButton>
            View Deal
          </CustomLink>
        </div>
      </div>
    </dialog>
  );
}

export default ItineraryModal;