import { SearchResultsStoryblok } from '@/app/types/component-types-sb'
import removeMd from 'remove-markdown';
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from './SearchResults.module.css'
import React from 'react'
import { Article, Category } from '@/app/types/lambda-types';
import { knowledgeBaseSearchFilter } from '@/utils/queries';
import Container from '@/app/components/globals/container';
import SearchFilterDisplay from '../header/SearchFilterDisplay';
import PageSection from '@/app/components/globals/page_section';
import { serverStore } from '@/utils/lib/serverstore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import withErrorHandling from '@/app/lib/withErrorHandling';


const SearchResults = async ({ blok }: { blok: SearchResultsStoryblok }) => {
  const query = serverStore.get('query');
  const generateSubstringFromArticle = (article: Article, value: string) => {
    const descriptionWithoutMarkdown = removeMd(article.description);
    const indexOfSearch = descriptionWithoutMarkdown.toLowerCase().indexOf(value.toLowerCase());
    const substring = descriptionWithoutMarkdown.substring(indexOfSearch, indexOfSearch + value.length);
    const description = "..." + descriptionWithoutMarkdown.substring(indexOfSearch - 45, indexOfSearch) +
      `<span style="font-weight: bolder">${substring}</span>` +
      descriptionWithoutMarkdown.substring(indexOfSearch + value.length, indexOfSearch + 45) + "...";

    article.description = description

    return article;
  }

  const fetchData = async () => {
    const data = await knowledgeBaseSearchFilter(query as string);
    return data
  };

  const searchContent = await fetchData()

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container className={styles.container}>
          <h1 className={styles.title}>
            Results for &apos;{query}&apos; Articles
          </h1>
          {((searchContent.articles.length > 0) || (searchContent.categories.length > 0)) ? (
            <div>
              {searchContent?.articles.map((article: Article, key: number) => (
                <div key={key} className={styles.searchResults}>
                  <SearchFilterDisplay
                    key={article.id}
                    article={generateSubstringFromArticle(article, query as string)}
                  />
                </div>
              ))}
              {searchContent?.categories.map((category: Category, key: number) => (
                <div key={key} className={styles.searchResults}>
                  <SearchFilterDisplay
                    key={category.id}
                    category={category}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Container style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'center',
            }}>
              <FontAwesomeIcon color='#da071b' icon={faSearch as IconProp} size="3x" />
              <h1 style={{
                textTransform: 'initial'
              }}>No results found for &apos;{query}&apos;</h1>
            </Container>
          )}
        </Container>
      </PageSection>
    </div>
  )
}

export default withErrorHandling(SearchResults, "Could not load the search results")