import React from 'react'
import {
  EmblaOptionsType
} from 'embla-carousel'
import styles from './Carousel.module.css'
import useEmblaCarousel from 'embla-carousel-react'
// import {
//   NextButton,
//   PrevButton,
//   usePrevNextButtons
// } from './EmblaCarouselArrowButtons'
// import { DotButton, useDotButton } from './EmblaCarouselDotButton'


type PropType = {
  options?: EmblaOptionsType
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode[] | React.ReactNode
}

const Carousel: React.FC<PropType> = (props) => {
  const { options, className, style, children } = props
  const [emblaRef] = useEmblaCarousel({
    ...options
  })

  // const { selectedIndex, scrollSnaps, onDotButtonClick } =
  //   useDotButton(emblaApi)

  // const {
  //   prevBtnDisabled,
  //   nextBtnDisabled,
  //   onPrevButtonClick,
  //   onNextButtonClick
  // } = usePrevNextButtons(emblaApi)

  return (
    <div className={`${styles["embla"]} ${className}`} style={style}>
      <div className={styles["embla__viewport"]} ref={emblaRef}>
        <div className={styles["embla__container"]}>
          {Array.isArray(children) ? children.map((child, index) => (
            <div className={styles["embla__slide"]} key={index}>
              {child}
            </div>
          )) : (
            <div className={styles["embla__slide"]}>
              {children}
            </div>
          )}
        </div>
      </div>

      {/* <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div> */}
    </div>
  )
}

export default Carousel
