'use client'
import React, { useCallback, useEffect, useState, useRef, CSSProperties } from 'react'
import {
  EmblaCarouselType,
  EmblaEventType,
  EmblaOptionsType
} from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import styles from "./Slider.module.css";
import { useEmblaPrevNextButtons } from '@/utils/hooks/useEmblaCarouselButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from '@awesome.me/kit-d4c82d9167/icons/classic/solid';
import Container from '../../globals/container';
import Image from '../../globals/image';
import { AssetStoryblok, SliderItemStoryblok } from '@/app/types/component-types-sb';
import useResponsive, { ResponsiveOutput } from '@/utils/hooks/useResponsive';

const TWEEN_FACTOR_BASE = 0.52
const MAX_TRANSLATE_Y = 25
const FOCUS_THRESHOLD = 0.9

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max)

type PropType = {
  children: React.ReactNode
  options?: EmblaOptionsType
  setSelectedImage?: React.Dispatch<React.SetStateAction<AssetStoryblok[]>>
  blok?: SliderItemStoryblok[]
}

const Carousel: React.FC<PropType> = ({ children, options, blok, setSelectedImage }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [changeImage, setChangeImage] = useState({
    first: false,
    second: false
  })
  const tweenFactor = useRef(0)
  const tweenNodes = useRef<HTMLElement[]>([])
  const widths = useResponsive();
  const [focusedIndices, setFocusedIndices] = useState<Set<number>>(new Set())
  const [animatedIndices, setAnimatedIndices] = useState<Set<number>>(new Set())

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = useEmblaPrevNextButtons(emblaApi)

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector(`.${styles["embla__slide__number"]}`) as HTMLElement
    })
  }, [])

  const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }, [])

  const tweenScaleAndTranslate = useCallback(
    (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
      const engine = emblaApi.internalEngine()
      const scrollProgress = emblaApi.scrollProgress()
      const slidesInView = emblaApi.slidesInView()
      const isScrollEvent = eventName === 'scroll'
      const newFocusedIndices = new Set<number>()

      emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress
        const slidesInSnap = engine.slideRegistry[snapIndex]

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !slidesInView.includes(slideIndex)) return

          if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach((loopItem) => {
              const target = loopItem.target()

              if (slideIndex === loopItem.index && target !== 0) {
                const sign = Math.sign(target)

                if (sign === -1) {
                  diffToTarget = scrollSnap - (1 + scrollProgress)
                }
                if (sign === 1) {
                  diffToTarget = scrollSnap + (1 - scrollProgress)
                }
              }
            })
          }

          const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
          const scale = numberWithinRange(tweenValue, widths.isMdAndUp ? 1 : 0.9, 1)
          const translateY = numberWithinRange((1 - tweenValue) * MAX_TRANSLATE_Y, 0, MAX_TRANSLATE_Y)
          const tweenNode = tweenNodes.current[slideIndex]

          if (tweenValue > FOCUS_THRESHOLD) {
            newFocusedIndices.add(slideIndex)
          }

          if (tweenNode) {
            tweenNode.style.transform = `scale(${scale}) translateY(${translateY}%)`
            tweenNode.style.opacity = `${tweenValue}`
          }
        })
      })

      setFocusedIndices(newFocusedIndices)
    },
    [widths.isMdAndUp]
  )

  useEffect(() => {
    if (!emblaApi) return

    setTweenNodes(emblaApi)
    setTweenFactor(emblaApi)
    tweenScaleAndTranslate(emblaApi)

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenScaleAndTranslate)
      .on('scroll', tweenScaleAndTranslate)
      .on('slideFocus', tweenScaleAndTranslate)
  }, [emblaApi, setTweenNodes, setTweenFactor, tweenScaleAndTranslate])

  // Effect to handle animation timing
  useEffect(() => {
    const newAnimatedIndices = new Set(animatedIndices)
    focusedIndices.forEach(index => {
      if (!animatedIndices.has(index)) {
        setTimeout(() => {
          newAnimatedIndices.add(index)
          setAnimatedIndices(new Set(newAnimatedIndices))
        }, 100) // Slight delay to ensure slide is in view
      }
    })
    // Remove indices that are no longer focused
    animatedIndices.forEach(index => {
      if (!focusedIndices.has(index)) {
        newAnimatedIndices.delete(index)
      }
    })
    setAnimatedIndices(newAnimatedIndices)
  }, [focusedIndices])

  const slideStyles = getSlideStyles(widths);

  const showItem = (item: any, index: number) => {
    if (!widths.isMdAndUp) {
      return (
        <div className={styles["embla__slide__number"]}>
          {focusedIndices.has(index) ? (
            <Container style={{ width: '100%', padding: 0 }}>
              {item}
            </Container>
          ) : (
            <Container style={{ width: '100%', padding: 0 }}>
              {item}
            </Container>
          )}
        </div>
      )
    } else {
      return (
        <div className={styles["embla__slide__number"]}>
          {focusedIndices.has(index) ? (
            <Container style={{ width: '100%' }}>
              {item}
            </Container>
          ) : (
            <Container style={{ width: '100%' }}>
              {item}
            </Container>
          )}
        </div>
      )
    }
  }

  const childrenArray = React.Children.toArray(children);

  return (
    <div className={styles["embla"]}>
      <div className={styles["embla__viewport"]} ref={emblaRef}>
        <div className={styles["embla__container"]}>
          {childrenArray.map((item, index) => (
            <div className={styles["embla__slide"]} key={index}>
              {showItem(item, index)}
            </div>
          ))}
        </div>
        {blok && childrenArray.map((item, index) => (
          <React.Fragment key={index}>
            {focusedIndices.has(index) && (
              <>
                {((blok[index]?.sub_image1 && blok[index]?.sub_image1.filename) && blok[index]?.sub_image2 && blok[index]?.sub_image2.filename) && (
                  <div
                    style={{
                      ...slideStyles.subImages as CSSProperties | undefined,
                      ...(animatedIndices.has(index) ? slideStyles.subImagesVisible : {})
                    }}
                  >
                    <div onClick={() => {
                      if(!setSelectedImage) return;
                      setChangeImage({ first: !changeImage.first, second: false })
                      setSelectedImage!((prev) => {
                        if (!prev) return [];

                        const newArray = [...prev]
                        if (blok[index]?.sub_image1) {
                          newArray[index] = changeImage.first ? blok[index].background_image : blok[index].sub_image1;
                        }
                        return newArray
                      })
                    }} style={slideStyles.subImage as CSSProperties | undefined}>
                      <Image
                        size={{ width: 250, height: 150 }}
                        asset={changeImage.first ? blok[index].background_image : blok[index].sub_image1}
                      />
                    </div>
                    <div onClick={() => {
                      if(!setSelectedImage) return;
                      setChangeImage({ second: !changeImage.second, first: false })
                      setSelectedImage!((prev) => {
                        if (!prev) return [];

                        const newArray = [...prev]
                        if (blok[index]?.sub_image2) {
                          newArray[index] = changeImage.second ? blok[index].background_image : blok[index].sub_image2;
                        }
                        return newArray
                      })
                    }} style={slideStyles.subImage as CSSProperties | undefined}>
                      <Image
                        size={{ width: 250, height: 150 }}
                        asset={changeImage.second ? blok[index].background_image : blok[index].sub_image2}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>

      <button aria-label='Previous' className={styles["embla__prev"]} onClick={onPrevButtonClick} disabled={prevBtnDisabled}>
        <FontAwesomeIcon icon={faChevronLeft as IconProp} size={!widths.isMdAndUp ? '3x' : '5x'} color='white' />
      </button>
      <button aria-label='Next' className={styles["embla__next"]} onClick={onNextButtonClick} disabled={nextBtnDisabled}>
        <FontAwesomeIcon icon={faChevronRight as IconProp} size={!widths.isMdAndUp ? '3x' : '5x'} color='white' />
      </button>
    </div>
  )
}



const getSlideStyles = (widths: ResponsiveOutput) => {
  // MD styles (768-1023px)
  if (widths.isLgAndUp) {
    return {
      subImages: {
        position: 'absolute',
        bottom: '-50px',
        right: widths.width > 2173 ? '400px' : '300px',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        padding: '20px',
        backgroundColor: 'white',
        cursor: 'pointer',
        transition: 'all 0.6s ease-out',
        opacity: 0,
        transform: 'translateY(50px)',
      },
      subImage: {
        width: '250px',
        height: '150px',
        position: 'relative',
      },
      subImagesVisible: {
        opacity: 1,
        transform: 'translateY(0)',
      }
    }
  }

  if (widths.isMd) {
    return {
      subImages: {
        position: 'absolute',
        bottom: '-50px',
        left: '17%',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        padding: '20px',
        backgroundColor: 'white',
        cursor: 'pointer',
        transition: 'all 0.6s ease-out',
        opacity: 0,
        transform: 'translateY(50px)',
      },
      subImage: {
        width: '250px',
        height: '150px',
        position: 'relative',
      },
      subImagesVisible: {
        opacity: 1,
        transform: 'translateY(0)',
      }
    }
  }

  // SM styles (640-767px)
  if (widths.isSm) {
    return {
      subImages: {
        position: 'absolute',
        bottom: '-50px',
        display: 'flex',
        flexDirection: 'row',
        left: '14%',
        gap: '20px',
        padding: '15px',
        backgroundColor: 'white',
        cursor: 'pointer',
        transition: 'all 0.6s ease-out',
        opacity: 0,
        transform: 'translateY(50px)',
      },
      subImage: {
        width: '200px',
        height: '150px',
        position: 'relative',
      },
      subImagesVisible: {
        opacity: 1,
        transform: 'translateY(0)',
      }
    }
  }

  // XS styles (576-639px) and below
  return {
    subImages: {
      position: 'absolute',
      bottom: '-50px',
      display: 'flex',
      flexDirection: 'row',
      left: '5.8%',
      gap: '20px',
      padding: '15px',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.6s ease-out',
      opacity: 0,
      transform: 'translateY(50px)',
    },
    subImage: {
      width: '150px',
      height: '100px',
      position: 'relative',
    },
    subImagesVisible: {
      opacity: 1,
      transform: 'translateY(0)',
    }
  }
}

export default Carousel