'use client'
import React, { useRef } from 'react'
import { storyblokEditable } from "@storyblok/react/rsc"
import { SelectStoryblok } from '@/app/types/component-types-sb'
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from '../../globals/form'
import { useFormContext } from 'react-hook-form'
import Select from '../../globals/select'
import useCheckForm from '@/utils/hooks/useCheckForm'

const FormSelect = ({ blok }: { blok: SelectStoryblok }) => {
  const { control, setValue } = useFormContext()
  const defaultValue = blok.data.length >= 1 ? blok.data[0].value : undefined
  const componentRef = useRef<HTMLDivElement>(null)
  const branch = process.env.NEXT_PUBLIC_BRANCH

  const {hasFormParent, fieldPath} = useCheckForm(componentRef, blok, setValue);

  if ((hasFormParent === false) && (branch !== 'staging' && branch !== 'dev')) return null

  return (
    <div ref={componentRef} {...storyblokEditable(blok)} data-field-name={blok.name.toLowerCase().replaceAll(" ", "_")}>
      <FormField
        control={control}
        name={fieldPath || blok.name.toLowerCase().replaceAll(" ", "_")}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormItem>
            {blok.showLabel !== false && (
              <FormLabel className='font-bold'>{blok.label} {blok.required && <span className="text-destructive-foreground">*</span>}</FormLabel>
            )}
            <FormControl>
              <Select
                options={
                  blok.data.map((option) => ({
                    label: option.name,
                    value: option.value
                  }))
                }
                {...field}
                required={blok.required}
              />
            </FormControl>
            {blok.showLabel !== false && (
              <FormDescription>
                {blok.description}
              </FormDescription>
            )}
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export default FormSelect