'use client'

import { AlternativeCabins, Cabin, Holiday } from "@/app/types/lambda-types";
import { useRef, useState } from "react";
import styles from './PriceGrid.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faCalendarDay, faChevronDown } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import { faCheck } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useClickOutside } from '@/utils/hooks/click-outside';
import { getAlternativeCabins, getStaticAlternativeCabins } from "@/utils/queries";
import FormattedDate from "@/app/components/globals/offer-date-format";
import clsx from "clsx";
import AirportName from "@/app/components/globals/airport-name";
import { faDoorOpen } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";

interface PriceGridProps {
  holiday: Holiday;
  style?: 'result' | 'starbuy';
  selectedCabin: Cabin;
  setSelectedCabin: (cabin: Cabin) => void;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
}

const PriceGrid = ({ holiday, style, selectedCabin, setSelectedCabin, selectedDate, setSelectedDate }: PriceGridProps) => {
  const initialCabin = holiday.cheapestCabin || {} as Cabin;
  const [cabins, setCabins] = useState<Cabin[]>(Array.isArray(holiday.cabins) ? holiday.cabins : holiday.cabins?.collection || []);
  const [selectedAirport, setSelectedAirport] = useState<string | null>(initialCabin.airport_code || 'cruiseonly');
  const [alternativeCabins, setAlternativeCabins] = useState<AlternativeCabins>({});
  const [loadingAlternatives, setLoadingAlternatives] = useState<boolean>(false);
  const [airportDropdownOpen, setAirportDropdownOpen] = useState<boolean>(false);
  const [airports, setAirports] = useState<string[]>(Object.keys(holiday.cabinsByAirport || {}));
  const [dateDropdownOpen, setDateDropdownOpen] = useState<boolean>(false);

  const checkSpan = <span style={{ fontSize: '0.75em' }}>check</span>;

  const airportDropdownRef = useRef<HTMLDivElement>(null);
  const dateDropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(airportDropdownRef, () => setAirportDropdownOpen(false))
  useClickOutside(dateDropdownRef, () => setDateDropdownOpen(false))


  const formatPrice = (price: number | string | null | undefined, comparisonPrice?: number | string | null | undefined, useStyledCheck: boolean = true) => {
    if (price == null || comparisonPrice == null) return useStyledCheck ? checkSpan : 'check';
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
    const numericComparisonPrice = typeof comparisonPrice === 'string' ? parseFloat(comparisonPrice) : comparisonPrice;

    if (isNaN(numericPrice) || isNaN(numericComparisonPrice)) return useStyledCheck ? checkSpan : 'check';
    const difference = numericPrice - numericComparisonPrice;
    const prefix = difference >= 0 ? '+' : '';

    return `${prefix}${difference.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      useGrouping: false
    })}`;
  }


  const getCurrentCabins = () => {
    if (!selectedAirport || !selectedDate || !cabins) return [];
    const currentCabins = cabins.filter((cabin: Cabin) => cabin.airport_code === selectedAirport);
    return currentCabins.length > 0 ? currentCabins : cabins.slice(0, 4);
  }


  const fetchAlternativeCabins = async () => {
    setLoadingAlternatives(true);
    let newAlternativeCabins = {};
    if (holiday.type === 'csi') {
      newAlternativeCabins = await getAlternativeCabins(holiday.cid, holiday.cluster_id, holiday.tt_ship_id);
    } else {
      newAlternativeCabins = await getStaticAlternativeCabins(holiday.cn_uid);
      newAlternativeCabins = { "cruiseonly": newAlternativeCabins };
    }
    setAlternativeCabins(newAlternativeCabins);
    setLoadingAlternatives(false);
  }


  const toggleAirportDropdown = () => {
    setAirportDropdownOpen(!airportDropdownOpen);
    if (dateDropdownOpen) {
      setDateDropdownOpen(false);
    }
  }


  const toggleDateDropdown = () => {
    if (!loadingAlternatives && Object.keys(alternativeCabins).length === 0)
      fetchAlternativeCabins();
    setDateDropdownOpen(!dateDropdownOpen);
    if (airportDropdownOpen && holiday.type === 'csi') {
      setAirportDropdownOpen(false);
    }
  }


  const onSelectedAirport = (airportCode: string) => {
    const newCabin = cabins.find((cabin: Cabin) => cabin.type === selectedCabin.type && cabin.airport_code === airportCode) || initialCabin;
    setSelectedCabin(newCabin);
    setSelectedAirport(airportCode);
    setAirportDropdownOpen(false);
  }

  const onSelectedDate = (date: string) => {
    const result: Cabin[] = [];

    // Loop through all airports in the original data
    for (const airportCode in alternativeCabins) {
      // Check if this airport has the requested date
      if (alternativeCabins[airportCode][date]) {
        // Extract cabin data for this airport at the specified date
        result.push(...alternativeCabins[airportCode][date]);
      }
    }

    setAirports(Object.keys(alternativeCabins));
    setCabins(result);
    setSelectedDate(date);
    // set selected cabin (matching the current selected cabin type and airport)
    let newCabin = {} as Cabin;
    if (holiday.type === 'csi') {
      newCabin = result.find((cabin: Cabin) => cabin.type === selectedCabin.type && cabin.airport_code === selectedAirport) || initialCabin;
    } else {
      newCabin = result.find((cabin: Cabin) => cabin.type === selectedCabin.type) || initialCabin;
    }
    setSelectedCabin(newCabin);
    setDateDropdownOpen(false);
  }

  const getAlternativeCabinPrice = (date: string, cabinType: string) => {
    if (!alternativeCabins[selectedAirport || '']) return 'check';
    const cabin = alternativeCabins[selectedAirport || ''][date].find((cabin: Cabin) => cabin.type === cabinType);

    // Show tick if this is the selected date and cabin type
    if (date === selectedDate && cabinType === selectedCabin.type) {
      return <FontAwesomeIcon icon={faCheck as IconProp} />;
    }

    return formatPrice(cabin?.price_pp, selectedCabin.price_pp || selectedCabin.price, false);
  }

  const getAirportPrice = (airportCode: string) => {
    const cabin = cabins.find((cabin: Cabin) => cabin.type === selectedCabin.type && cabin.airport_code === airportCode);
    return cabin?.price_pp || null;
  }

  return (
    <div className={styles.priceGridContainer}>
      <div className={styles.alternativeDropdowns}>
        {holiday.type === 'csi' && (
          <div className={clsx(styles.dropdown, airportDropdownOpen && styles.dropdownOpen)} ref={airportDropdownRef}>
            <label>
              <FontAwesomeIcon icon={faPlane as IconProp} />
              Flying From
            </label>
            <button className={styles.dropdownButton} onClick={() => toggleAirportDropdown()}>
              <span>
                <span><AirportName code={selectedAirport || ''} /></span>
              </span>
              <FontAwesomeIcon icon={faChevronDown as IconProp} />
            </button>
            {airportDropdownOpen && (
              <div className={clsx(styles.dropdownContent, styles.dropdownContentLeft)}>
                {loadingAlternatives && (
                  <p>Loading...</p>
                )}
                {airports.map((airportCode: string) => (
                  <button className={clsx(styles.dropdownItem, selectedAirport === airportCode && styles.dropdownItemSelected)} key={airportCode} onClick={() => onSelectedAirport(airportCode)}>
                    <div className={styles.dropdownItemContent}>
                      <AirportName code={airportCode} />
                      {airportCode !== selectedAirport && (
                        <span className={styles.dropdownItemPrice}>
                          {formatPrice(getAirportPrice(airportCode), selectedCabin.price_pp || selectedCabin.price)}
                        </span>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        <div className={clsx(styles.dropdown, holiday.type !== 'csi' && styles.dropdownDateOnly, dateDropdownOpen && styles.dropdownOpen)} ref={dateDropdownRef}>
          <label>
            <FontAwesomeIcon icon={faCalendarDay as IconProp} />
            Departure Date
          </label>
          <button className={styles.dropdownButton} onClick={() => toggleDateDropdown()}>
            <span>
              <FormattedDate date={selectedDate} />
            </span>
            <FontAwesomeIcon icon={faChevronDown as IconProp} />
          </button>
          {dateDropdownOpen && (
            <div className={clsx(styles.dropdownContent, styles.dropdownContentRight)}>
              {loadingAlternatives ? (
                <p>Loading...</p>
              ) :
                <table className={styles.alternativeCabinTable}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Inside</th>
                      <th>Outside</th>
                      <th>Balcony</th>
                      <th>Suite</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(alternativeCabins[selectedAirport || '']).map((date: string) => (
                      <tr key={date} onClick={() => onSelectedDate(date)} className={clsx(styles.alternativeCabinTableRow, date === selectedDate && styles.alternativeCabinTableRowSelected)}>
                        <td>
                          <FormattedDate date={date} />
                        </td>
                        <td>
                          {getAlternativeCabinPrice(date, 'inside')}
                        </td>
                        <td>
                          {getAlternativeCabinPrice(date, 'outside')}
                        </td>
                        <td>
                          {getAlternativeCabinPrice(date, 'balcony')}
                        </td>
                        <td>
                          {getAlternativeCabinPrice(date, 'suite')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </div>
          )}
        </div>
      </div>

      <label className={styles.priceGridLabel}>
        <FontAwesomeIcon icon={faDoorOpen as IconProp} />
        Cabins
      </label>
      <div className={clsx(styles.priceGrid, style === 'starbuy' ? styles.starbuy : '')}>
        {getCurrentCabins().map((cabin: Cabin, index: number) => (
          <button
            className={clsx(styles.priceGridItem, cabin.type === selectedCabin?.type && styles.priceGridItemSelected)}
            key={index}
            onClick={() => setSelectedCabin(cabin)}
          >
            <label>{cabin.type}</label>
            <span className={styles.priceGridItemPrice}>
              {
                cabin.type === selectedCabin?.type ?
                  <FontAwesomeIcon icon={faCheck as IconProp} /> :
                  formatPrice(cabin.price_pp, selectedCabin.price_pp || selectedCabin.price)
              }
            </span>
          </button>
        ))}
      </div>
      <p className={styles.upgradePrices}>
        Upgrade prices displayed are for reference only. You can choose a specific cabin in the next stage.
      </p>
    </div>
  )
}

export default PriceGrid;
