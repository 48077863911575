import clsx from 'clsx'
import React, { FC, HTMLAttributes, ReactElement, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
  style?: React.CSSProperties
  notExpanded?: boolean
}


/**
 * A container component that wraps its children in a div with a class of 'container' and 'container--expanded'.
 * This applies max-width and horizontal padding styles defined in the global CSS.
 *
 * @param {ReactNode} children - The content to be rendered inside the container.
 * @param {string} [className] - Additional CSS class names to be applied to the container.
 * @param {React.CSSProperties} [style] - Additional styles to apply to the container.
 * @param {HTMLAttributes<HTMLDivElement>} [props] - Any additional HTML attributes to apply to the container element.
 *
 * @returns {ReactElement} - The container element.
 */
const Container: FC<Props & HTMLAttributes<HTMLDivElement>> = ({ children, className, notExpanded, style, ...props }: Props): ReactElement => {
  return (
    <div {...props} style={style} className={clsx('container', notExpanded !== true ? 'container--expanded' : "", className)}>
      {children}
    </div>
  )
}

export default Container