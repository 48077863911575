import { HotRightNowFeaturedDealsStoryblok } from "@/app/types/component-types-sb";
import { Holiday } from "@/app/types/lambda-types";
import FormattedDate from "@/app/components/globals/offer-date-format";
import { getFeaturedDeals } from "@/utils/queries";
import React from "react";
import styles from './HotRightNow.module.css';
import FromPrice from "../../globals/from-price";
import CustomLink from "../../globals/link";
import withErrorHandling from "@/app/lib/withErrorHandling";

const HotRightNowFeaturedDeals = async ({ blok }: { blok: HotRightNowFeaturedDealsStoryblok }) => {

  const getResults = async (uuid: string) => {
    const results = await getFeaturedDeals(uuid);
    return results as unknown as Holiday[];
  }

  const group = await getResults(blok.group_uuid);


  return (
    <div className={styles.list}>
      {group?.map((holiday: Holiday) => (
        <CustomLink key={holiday.cn_uid} className={styles.listItem} href={holiday.url}>
          <div className={styles.details}>
            {holiday.name}
            <span className={styles.date}>
              <FormattedDate date={holiday.start_date} />
            </span>
          </div>
          <div className={styles.price}>
            <FromPrice
              price={holiday.from_price?.split('.')[0] || ''}
              fontSize="42px"
            />
          </div>
        </CustomLink>
      ))}
    </div>
  )
}

export default withErrorHandling(HotRightNowFeaturedDeals, 'Could not load the hot right now featured deals');