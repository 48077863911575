import React from "react";
import { SbBlokData, StoryblokServerComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { ColumnStoryblok } from "@/app/types/component-types-sb";
import Container from "../../globals/container";
import PageSection from "../../globals/page_section";

const Column = ({ blok }: { blok: ColumnStoryblok }) => {
  return (
    <PageSection {...storyblokEditable(blok)}>
      <Container>
        {
          blok.content?.map((nestedBlok: SbBlokData) => (
            <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))
        }
      </Container>
    </PageSection>
  )
}

export default Column;