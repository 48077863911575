"use client"
import React, { useMemo, useState } from "react";
import { storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc";
import type {
  FormStoryblok,
  InputfieldStoryblok,
  TextareaStoryblok,
  SelectStoryblok,
  ValidationStoryblok,
  FieldValidationStoryblok,
  FieldStoryblok,
  FormSectionStoryblok
} from "@/app/types/component-types-sb";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Form } from "../../globals/form";
import Button from "../../globals/button";
import ReCAPTCHA from "react-google-recaptcha";
import { submitFormData } from "@/utils/queries";
import { toast } from "sonner"
import { FormProvider } from "@/utils/providers/storyblok-form-provider";

type FormFieldType = InputfieldStoryblok | TextareaStoryblok | SelectStoryblok | FieldStoryblok | FormSectionStoryblok;

// Helper function to check if a blok is a form field
const isFormField = (blok: any): blok is FormFieldType => {
  return ['inputfield', 'textarea', 'select', 'field'].includes(blok.component);
};

// Build schema recursively
const buildSchemaFromBlok = (blok: any): Record<string, z.ZodTypeAny> => {
  const schemaFields: Record<string, z.ZodTypeAny> = {};

  const traverse = (node: any) => {
    if (!node) return;

    if (isFormField(node)) {
      const fieldSchema = getFieldSchema(node);
      if (fieldSchema) {
        schemaFields[node.name.toLowerCase().replaceAll(" ", "_")] = fieldSchema;
      }
    }

    // Traverse children
    if (Array.isArray(node.children)) {
      node.children.forEach(traverse);
    }

    // Traverse body
    if (Array.isArray(node.body)) {
      node.body.forEach(traverse);
    }

    if (Array.isArray(node.content)) {
      node.content.forEach(traverse);
    }

    if (Array.isArray(node.left_column)) {
      node.left_column.forEach(traverse);
    }

    if (Array.isArray(node.columns)) {
      node.columns.forEach(traverse);
    }

    if (Array.isArray(node.footer)) {
      node.footer.forEach(traverse);
    }

    // Check other properties
    if (typeof node === 'object'  && !node.fields) {
      Object.values(node).forEach(value => {
        if (Array.isArray(value)) {
          value.forEach(traverse);
        } else if (typeof value === 'object' && value !== null) {
          traverse(value);
        }
      });
    }
  };

  traverse(blok);
  return schemaFields;
};

// Helper function to create field schema based on field type
const getFieldSchema = (field: FormFieldType): z.ZodTypeAny => {
  let schema;

  if (field.component === 'inputfield') {
    switch (field.type) {
      case 'email':
        schema = field.required ? z.string().email({ message: "Please enter a valid email address." }) : z.string().email().nullable();
        break;
      case 'number':
        schema = field.required ? z.string().transform((val) => Number(val))
          .pipe(z.number().min(-Infinity).max(Infinity)) : z.string().transform((val) => Number(val)).nullable();
        break;
      case 'url':
        schema = field.required ? z.string().url({ message: "Please enter a valid URL." }) : z.string().url().nullable();
        break;
      case 'checkbox':
      case 'switch':
        schema = z.string().transform((val) => val === 'true')
          .pipe(z.boolean());
        break;
      case 'file':
        schema = field.required ? z.string() : z.string().nullable();
        break;
      case 'text':
      default:
        schema = field.required ? z.string().min(4) : z.string().nullable();
        break;
    }
  } else if (field.component === 'select') {
    schema = field.required ? z.string().refine(
      (value) => field.data.some(option => option.value === value),
      { message: "Please select a valid option." }
    ) : z.string().refine(
      (value) => field.data.some(option => option.value === value),
      { message: "Please select a valid option." }
    ).nullable();
  } else if (field.component === 'field') {
      // Create an object schema for this field
      const fieldSchemas: Record<string, z.ZodTypeAny> = {};

      // Process each child field
      field.fields.forEach((childField) => {
        if(childField.component === 'form_section'){
         return getFieldSchema(childField as FormFieldType); 
        }else{
          fieldSchemas[childField.name.toLowerCase().replaceAll(" ", "_")] = getFieldSchema(childField as FormFieldType);
        }
      });
      
      // Return an object schema with all child fields
      schema = z.object(fieldSchemas);
  } else if(field.component === 'form_section'){

    const fieldSchemas: Record<string, z.ZodTypeAny> = {};

    if (!field.fields) return z.object({});

    // Process each child field
    field.fields.forEach((childField) => {
      fieldSchemas[childField.name.toLowerCase().replaceAll(" ", "_")] = getFieldSchema(childField as FormFieldType);
    });
    
    // Return an object schema with all child fields
    schema = z.object(fieldSchemas);
    
  } else {
    schema = field.required ? z.string().min(4) : z.string().nullable();
  }

  return schema;
};

const checkDataType = (value: any): string | number | boolean => {
  // If value is already a number or boolean, return it as is
  if (typeof value !== 'string') {
    return value;
  }

  // Check if it's a number
  if (!isNaN(Number(value)) && value.trim() !== '') {
    return Number(value);
  }

  // Check if it's a boolean
  if (value.toLowerCase() === 'true') {
    return true;
  }
  if (value.toLowerCase() === 'false') {
    return false;
  }

  // Otherwise, it's a string
  return value;
}

// Function to process the logic based on the operator
const processLogic = (field: any, value: any, operator: FieldValidationStoryblok["operator"]) => {
  if (operator === 'equals') {
    return checkDataType(field) === checkDataType(value);
  } else if (operator === 'not equals') {
    return checkDataType(field) !== checkDataType(value);
  } else if (operator === 'greater or equals') {
    return checkDataType(field) >= checkDataType(value);
  } else if (operator === 'lesser or equals') {
    return checkDataType(field) <= checkDataType(value);
  } else if (operator === 'greater') {
    return checkDataType(field) > checkDataType(value);
  } else if (operator === 'lesser') {
    return checkDataType(field) < checkDataType(value);
  } else if (operator === 'not') {
    return !checkDataType(field);
  }

  return false;
}

// Function to get the value from a nested object based on the field path
// This function will recursively search for the fieldName in the nested object
const getValueFromFieldPath = (values: Record<string, any>, fieldName: string): any => {
  // Base case: if values is null or undefined, return null
  if (values === null || values === undefined) {
    return null;
  }
  
  // Base case: if values is a primitive (not an object), return it as the value
  if (typeof values !== 'object') {
    return values;
  }
  
  // Check if the current object has the fieldName as a key
  if (fieldName in values) {
    const value = values[fieldName];
    
    // If the value is a primitive, return it
    if (value === null || value === undefined || typeof value !== 'object') {
      return value;
    }
    
    // Otherwise, recursively check if the value contains a value for the fieldName
    return getValueFromFieldPath(value, fieldName);
  }
  
  // If fieldName is not a direct key, recursively check all properties
  for (const key in values) {
    if (typeof values[key] === 'object' && values[key] !== null) {
      const result = getValueFromFieldPath(values[key], fieldName);
      if (result !== null) {
        return result;
      }
    }
  }
  
  // If no value is found, return null
  return null;
}

// Function to find the path of a field in a nested object
// This function will recursively search for the fieldName in the nested object
const findFieldPath = (values: Record<string, any>, fieldName: string, currentPath = '', /*parentPath = ''*/): string | null => {
   // Base case: if values is null or undefined, return null
   if (values === null || values === undefined) {
    return null;
  }
  
  // If the object has the fieldName as a direct key
  if (fieldName in values) {
    return currentPath ? `${currentPath}.${fieldName}` : fieldName;
  }
  
  // Recursively search in nested objects
  for (const key in values) {
    if (typeof values[key] === 'object' && values[key] !== null) {
      const newPath = currentPath ? `${currentPath}.${key}` : key;
      const result = findFieldPath(values[key], fieldName, newPath);
      if (result !== null) {
        return result;
      }
    }
  }
  
  // Not found in this branch
  return null;
}

// Function to validate the form based on the validations defined in Storyblok
const validateForm = (validations: ValidationStoryblok[], values: Record<string, any>) => {
  const errors: Record<string, string> = {};

  for (const validation of validations) {

    if (!validation.field) continue

    if (validation.field[0].component === 'field_validation') {

      const fieldName1 = validation.field[0].fieldname1.toLowerCase().replaceAll(" ", "_");
      const fieldName2 = validation.field[0].fieldname2.toLowerCase().replaceAll(" ", "_");
      const operator = validation.field[0].operator;

      const result = processLogic(getValueFromFieldPath(values, fieldName1), getValueFromFieldPath(values, fieldName2), operator);

      if (!result) {
        errors[findFieldPath(values, validation.field[0].fieldname1.toLowerCase().replaceAll(" ", "_")) || ''] = `Incorrect Field data for '${validation.field[0].fieldname1}' and '${validation.field[0].fieldname2}'`;
      }
    } else if (validation.field[0].component === 'unique_validation') {

      const fieldName = validation.field[0].fieldname.toLowerCase().replaceAll(" ", "_");
      const value = validation.field[0].value;
      const operator = validation.field[0].operator;

      const result = processLogic(getValueFromFieldPath(values, fieldName), value, operator);

      if (!result) {
        errors[findFieldPath(values, validation.field[0].fieldname.toLowerCase().replaceAll(" ", "_")) || ''] = `field '${validation.field[0].fieldname}' does not match '${value}', '${validation.field[0].fieldname}' should be ${operator} '${value}'`;
      }
    }
  }

  return errors;
};

const StoryblokForm = ({ blok }: { blok: FormStoryblok }) => {
  // Build schema from all nested fields
  const formSchema = useMemo(() => {
    const schemaFields = buildSchemaFromBlok(blok);
    return z.object(schemaFields);
  }, [blok]);

  const [token, setToken] = useState<string | null>(null)

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if(blok.useRecaptcha === true && token === null) {
      toast.error('Please complete the reCAPTCHA verification');
      return;
    }

    const send = {...values, ...form.getValues(),
      ...(
        blok.useRecaptcha === true ? {'g-recaptcha-response': token} : {}
      )
    }

    const errors = validateForm(blok.validations || [], values);
    if (Object.keys(errors).length > 0) {
      for (const key in errors) {
        form.setError(key, { message: errors[key] });
      }
      return;
    }

    const url = blok.endpoint; //valuesToEndpoints[blok.endpoint as keyof typeof valuesToEndpoints] || blok.endpoint;
    try {
      const data = submitFormData(url, send);

    toast.promise(data, {
      loading: 'Submitting...',
      success: (data) => {
       console.log(data);
        return data.message;
      },
      error: 'An error occurred while submitting the form',
    });

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
      <div {...storyblokEditable(blok)}>
        <FormProvider>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              {/* Render the original Storyblok structure */}
              {blok.children?.map((nestedBlok) => (
                <StoryblokServerComponent
                  blok={nestedBlok}
                  key={nestedBlok._uid}
                />
              ))}
              {blok.useRecaptcha === true && (
                <ReCAPTCHA
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                onChange={(value) => setToken(value)}
              />
              )}
              <Button
                type="submit"
                disabled={form.formState.isSubmitting ||
                   Object.keys(form.formState.errors).length > 0 ||
                  !form.formState.isValid ||
                  ((blok.useRecaptcha === true) && (token === null))}
              >
                {blok.submitButtonText || 'Submit'}
              </Button>
            </form>
          </Form>
          </FormProvider>
      </div>
  );
};

export default StoryblokForm;