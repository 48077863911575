import { MapboxStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react/rsc';
import React from 'react';
import styles from './StoryblokMapbox.module.css'
import MapBox from '../../globals/mapbox';
import PageSection from '../../globals/page_section';
import { getRegionMapCoordinates } from '@/utils/queries';
import withErrorHandling from '@/app/lib/withErrorHandling';

const StoryBlokMapBox = async ({ blok }: { blok: MapboxStoryblok }) => {

  const fetchData = async () => {
    if (blok.use_sub_region) {
      const response = await getRegionMapCoordinates({ region_id: undefined, sub_region_id: blok.sub_region_id })
      return response
    } else {
      const response = await getRegionMapCoordinates({ region_id: blok.region_id, sub_region_id: undefined })
      return response
    }
  }

  const data = await fetchData();

  return (
    <div {...storyblokEditable(blok)}>
      {data.length > 0 && (
        <PageSection>
          <h2 className='section-header'>{blok.title}</h2>
          <div className={styles.map}>
            <MapBox points={data?.map((item) => ({
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
              label: item.name,
              url: item.url,
              description: item.description,
              image_path: item.image_path
            }))} />
          </div>
        </PageSection>
      )}
    </div>
  )
}

export default withErrorHandling(StoryBlokMapBox, "Could not load the mapbox")