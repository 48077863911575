'use client'
import React, { useRef } from 'react'
import { storyblokEditable } from "@storyblok/react/rsc"
import { SectionSelectionStoryblok } from '@/app/types/component-types-sb'
import { FormLabel, FormControl, FormDescription } from '../../globals/form'
import Select from '../../globals/select'
import { useFormProvider } from '@/utils/providers/storyblok-form-provider'
import useCheckForm from '@/utils/hooks/useCheckForm'

const FormSectionSelection = ({ blok }: { blok: SectionSelectionStoryblok }) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const branch = process.env.NEXT_PUBLIC_BRANCH
  
  // Generate a unique key based on the component's unique identifier or UUID if available
  // If blok has a UUID or unique ID, use that instead for a more reliable key
  const componentKey = blok._uid || blok.name || 'section_selection'
  
  // Using just the first option's identifier for the key to avoid long keys
  const key = blok.option && blok.option.length > 0 
    ? `${componentKey}_${blok.option[0].section_identifier.toLowerCase().replaceAll(" ", "_")}` 
    : componentKey

  const {setValue, value, setDefaultValue} = useFormProvider<{
    [fieldPath: string]: string
  }>()

  const {hasFormParent} = useCheckForm(componentRef, blok, undefined, key, setValue, setDefaultValue);

  if ((hasFormParent === false) && (branch !== 'staging' && branch !== 'dev')) return null

  if(!blok.option || blok.option.length === 0) return null;

  // Get the current value or fallback to the first option
  const currentValue = value?.[key] || 
    (blok.option[0]?.section_identifier.toLowerCase().replaceAll(" ", "_"))

  return (
    <div style={{
      marginBottom: "20px",
    }} ref={componentRef} {...storyblokEditable(blok)}>
      {blok.showLabel !== false && (
        <FormLabel>{blok.label}</FormLabel>
      )}
      <FormControl>
        <Select
          options={
            blok.option.map((option) => ({
              label: option.section_identifier,
              value: option.section_identifier.toLowerCase().replaceAll(" ", "_")
            }))
          }
          value={currentValue}
          onChange={(text) => {
            setValue(prev => ({
              ...(prev || {}),
              [key]: text
            }))
          }}
        />
      </FormControl>
      {blok.showLabel !== false && (
        <FormDescription>
          {blok.description}
        </FormDescription>
      )}
    </div>
  )
}

export default FormSectionSelection