import { ShipOverviewStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React from 'react'
import { getShipDetailsByTTID } from '@/utils/queries';
import styles from './ShipOverview.module.css'
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import { Overview } from '../../../types/lambda-types';
import parse from 'html-react-parser';
import withErrorHandling from '@/app/lib/withErrorHandling';

const ShimmerLoading = () => {
  return (
    <div className={styles.shimmerWrapper}>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
    </div>
  );
};

const ShipOverview = async ({ blok }: { blok: ShipOverviewStoryblok }) => {
  let isLoading = true;

  const fetchShipDetails = async () => {
    try {
      const response = await getShipDetailsByTTID(parseInt(blok.ship || '0'));
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  const shipDetail = await fetchShipDetails();

  if (shipDetail) isLoading = false;

  return (
    <div {...storyblokEditable(blok)}>
      {
        shipDetail && (
          <PageSection>
            <Container>
              <h1 className={styles.title}>Your floating resort</h1>
              {isLoading ? (
                <ShimmerLoading />
              ) : (
                shipDetail?.overview.map((overview: Overview) => (
                  <div key={overview.id}>
                    {overview.description.map((description: string) => (
                      <p key={description}>{parse(description)}</p>
                    ))}
                  </div>
                ))
              )}
            </Container>
          </PageSection>
        )
      }
    </div>
  )
}

export default withErrorHandling(ShipOverview, "Could not load the ship overview")