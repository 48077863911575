import React from 'react'
import MotionWrapper from '../../globals/motion'
import styles from './ContentSplitTwoImages.module.css'
import { AssetStoryblok, ButtonStoryblok, LinkStoryblok, NestedLinkStoryblok, RichtextStoryblok } from '@/app/types/component-types-sb'
import Image from '../../globals/image'
import CustomLink from '../../globals/link'
import { render } from 'storyblok-rich-text-react-renderer'
import clsx from 'clsx'
import ScrollButton from './ScrollButton'
import components from '../../globalComponents'

type AnimationType = 'none' | 'fadeIn' | 'fadeInLeft' | 'fadeInRight' | 'irisIn' | 'wipeIn' | 'wipeInReverse'

type Props = {
  flip?: boolean
  title?: string
  textContent: RichtextStoryblok
  image: AssetStoryblok
  imageTitle?: string
  imageDecription?: string
  button?: (ButtonStoryblok | LinkStoryblok | NestedLinkStoryblok)[]
  wideImage?: boolean
  animation?: AnimationType
}

const animations = {
  fadeIn: {
    initial: { opacity: 0 },
    whileInView: { opacity: 1 },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1.5, ease: "easeInOut" }
  },
  fadeInLeft: {
    initial: { opacity: 0, x: -50 },
    whileInView: { opacity: 1, x: 0 },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1, ease: "easeInOut" }
  },
  fadeInRight: {
    initial: { opacity: 0, x: 50 },
    whileInView: { opacity: 1, x: 0 },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1 }
  },
  fadeInUp: {
    initial: { opacity: 0, y: -50 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1, ease: "easeInOut" }
  },
  fadeInDown: {
    initial: { opacity: 0, y: 50 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1 }
  },
  irisIn: {
    initial: { scale: 0, opacity: 0 },
    whileInView: { scale: 1, opacity: 1 },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1, ease: "easeInOut" }
  },
  wipeIn: {
    initial: { opacity: 0, clipPath: "inset(0 75% 0 0)" },
    whileInView: { opacity: 1, clipPath: "inset(0 0% 0 0)" },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1 }
  },
  wipeInReverse: {
    initial: { opacity: 0, clipPath: "inset(0 0 0 75%)" },
    whileInView: { opacity: 1, clipPath: "inset(0 0 0 0%)" },
    viewport: { once: true, margin: "-50px" },
    transition: { duration: 1 }
  }
}

export const blokReslover = (name: string, node: Record<string, unknown> & {
  _uid: string;
}) => {
  if (!node || !node._uid) return <></>;

  const RenderElement = components[name as keyof typeof components] as React.ComponentType<{ blok: any }>

  return <RenderElement blok={node} />
}

const ContentSplitTwoImageTextImage = ({
  flip,
  title,
  textContent,
  image,
  imageTitle,
  imageDecription,
  button,
  wideImage,
  animation = 'none'
}: Props) => {
  // const scrollToGroups = () => {
  //   const groups = document.getElementsByClassName('story-blokgroups')[0]
  //   if (!groups) return
  //   const offset = 100
  //   const elementTop = groups.getBoundingClientRect().top + window.scrollY
  //   const targetPosition = elementTop - offset
  //   window.scrollTo({ top: targetPosition, behavior: 'smooth' })
  // }

  const animationProps = animation === 'none' ? {} : animations[animation]

  return (
    <div className={clsx(flip ? styles.containerFlipped : styles.container, 'container', 'container--expanded')}>
      <MotionWrapper
        className={styles.textContent}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        {title ? <h2 className={styles.title}>{title}</h2> : null}
        <div className={`${styles.text} text-content`}>
          {render(textContent, {
            defaultBlokResolver: (name, props) => blokReslover(name, props)
          })}
        </div>
        {(button && (button.length > 0)) && (
          <>
            {(button[0].component === 'button') ? (
              <ScrollButton label={button[0].label} />
            ) : (
              <>
                {button[0].component === 'nested_link' ? (
                  <CustomLink
                    link={button[0]}
                    override_label={button[0].override_label}
                    variant='outline'
                    asButton
                  />
                ) : (
                  <CustomLink
                    link={button[0].url}
                    override_label={button[0].overide_label}
                    variant='outline'
                    asButton
                  />
                )}
              </>
            )}
          </>
        )}
      </MotionWrapper>
      <MotionWrapper
        className={styles.imageContent}
        {...animationProps}
      >
        <figure className={clsx(styles.imageContainer, wideImage ? styles.imageContainerWide : '')}>
          <figcaption className={flip ? styles.imageDescriptionFlipped : styles.imageDescription}>{imageTitle}{imageTitle && imageDecription ? ' - ' : ''}<b>{imageDecription}</b></figcaption>

          <Image
            lazyLoad={false}
            asset={image}
            useSource={false}
            quality={70}
            size={{
              width: wideImage ? 1000 : 800,
              height: 500
            }}
            sourceSet={{
              '992': {  // Add tablet breakpoint
                size: {
                  width: wideImage ? 700 : 500,
                  height: 500
                },
                crop: true
              },
              '1024': {  // Desktop size
                size: {
                  width: wideImage ? 1000 : 800,
                  height: 500
                },
                crop: false
              }
            }}
          />
        </figure>
      </MotionWrapper>
    </div>
  )
}

export default ContentSplitTwoImageTextImage