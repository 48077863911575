"use client"
import React, { forwardRef } from 'react';
import styles from '../Form.module.css';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputName?: string;
  label?: string;
}

const TextField = forwardRef<HTMLInputElement, TextInputProps>(
  ({ 
    inputName,
    label,
    value,
    placeholder = '',
    disabled,
    onChange,
    type = 'text',
    required = false,
    className,
    ...props
  }, ref) => {
    return (
      <div className={styles.formGroup}>
        <label className={styles.label}>
          {label && <span className={styles.labelText}>{label}</span>}
          <input
            ref={ref}
            className={`${styles.input} ${className || ''}`}
            type={type}
            name={inputName}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            {...props}
          />
        </label>
      </div>
    );
  }
);

// Add display name for better debugging
TextField.displayName = 'TextField';

export default TextField;