const airportCodes = [
  ["London Heathrow", "LHR"],
  ["London Gatwick", "LGW"],
  ["London Southend", "SEN"],
  ["London City", "LCY"],
  ["Manchester", "MAN"],
  ["East Midlands", "EMA"],
  ["Prestwick", "PIK"],
  ["Cardiff", "CWL"],
  ["Aberdeen", "ABZ"],
  ["Stansted", "STN"],
  ["Barcelona-El Prat", "BCN"],
  ["Birmingham", "BHX"],
  ["Glasgow", "GLA"],
  ["Inverness", "INV"],
  ["Durham", "MME"],
  ["Edinburgh", "EDI"],
  ["Luton", "LTN"],
  ["Belfast International", "BFS"],
  ["Belfast City", "BHD"],
  ["Newcastle", "NCL"],
  ["Leeds/Bradford", "LBA"],
  ["Bristol", "BRS"],
  ["Liverpool", "LPL"],
  ["Vancouver", "YVR"],
  ["Tenerife", "TFS"],
  ["Barbados", "BGI"],
  ["San Juan", "SJU"],
  ["Copenhagen", "CPH"],
  ["Marseille", "MRS"],
  ["Nice", "NCE"],
  ["Hamburg", "HAM"],
  ["Athens", "ATH"],
  ["Heraklion", "HER"],
  ["Bari", "BRI"],
  ["Genoa", "GOA"],
  ["Milan", "MXP"],
  ["Pisa", "PSA"],
  ["Rome", "FCO"],
  ["Naples", "NAP"],
  ["Venice", "VCE"],
  ["Barcelona", "BCN"],
  ["Verona", "VRN"],
  ["Amsterdam", "AMS"],
  ["Valencia", "VLC"],
  ["Palma De Mallorca", "PMI"],
  ["Barcelona Reus", "REU"],
  ["Dubai", "DXB"],
  ["New Orleans", "MSY"],
  ["Boston", "BOS"],
  ["New York (JFK)", "JFK"],
  ["New York Newark", "EWR"],
  ["Tampa", "TPA"],
  ["Fort Lauderdale", "FLL"],
  ["Miami", "MIA"],
  ["Orlando", "MCO"],
  ["Los Angeles", "LAX"],
  ["San Diego", "SAN"],
  ["San Francisco", "SFO"],
  ["Seattle", "SEA"],
  ["Buenos Aires", "EZE"],
  ["Santiago", "SCL"],
  ["Rotterdam", "RTM"],
  ["Montreal", "YUL"],
  ["Anchorage", "ANC"],
  ["Roma Ciampino", "CIA"],
  ["Rome Fiumicino", "FCO"],
  ["Dublin", "DUB"],
  ["Norwich", "NWI"],
  ["Humberside", "HUY"],
  ["Cork", "ORK"],
  ["Saloniki", "SKG"],
  ["Rhodes", "RHO"],
  ["Majorca", "PMI"],
  ["Milan Malpensa", "MXP"],
  ["Zakynthos", "ZTH"],
  ["Sao Paulo", "GRU"],
  ["Las Vegas", "LAS"],
  ["Singapore", "SIN"],
  ["Hong Kong", "HKG"],
  ["Sydney", "SYD"],
  ["Stockholm", "ARN"],
  ["Durban", "DUR"],
  ["Brisbane", "BNE"],
  ["Malaga", "AGP"],
  ["Lisbon", "LIS"],
  ["Rio de Janeiro", "GIG"],
  ["Izmir", "ADB"],
  ["Larnaca", "LCA"],
  ["Cape Town", "CPT"],
  ["Tokyo Haneda Airport", "HND"],
  ["Gran Canaria", "LPA"],
  ["Madeira", "FNC"],
  ["Istanbul", "IST"]
] as const;

interface AirportNameProps {
  code: string;
}

const AirportName = ({ code }: AirportNameProps) => {
  return <>{airportCodes.find(([, c]) => c === code)?.[0]}</>;
};

export default AirportName;

