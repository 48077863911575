'use client'
import React, { useState } from 'react'
import styles from './ImageSlider.module.css';
import { storyblokEditable } from "@storyblok/react/rsc"
import { ImageSliderStoryblok } from '@/app/types/component-types-sb';
import PageSection from '../../globals/page_section';
import HorizontalScrollView from '../../globals/carousels/custom_carousel';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandBackPointUp } from '@awesome.me/kit-d4c82d9167/icons/classic/solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { render } from 'storyblok-rich-text-react-renderer';
import { blokReslover } from '../content_split_2_images/ContentSplitTwoImageTextImage';
import Container from '../../globals/container';
import ImageSliderItem from './image_slider_item';

const ImageSlider = ({ blok }: { blok: ImageSliderStoryblok }) => {
    const [isScrolled, setisScrolled] = useState(false)
    return (
        <PageSection>
            <div {...storyblokEditable(blok)}>
                {blok.header && (
                    <motion.h2
                        className='section-header'
                        {...{
                            initial: { opacity: 0, y: 25 },
                            whileInView: { opacity: 1, y: 0 },
                            viewport: { once: true, margin: "-100px" },
                            transition: { duration: 0.6, ease: "easeInOut" }
                        }}
                    >
                        {blok.header}
                    </motion.h2>
                )}
                {blok.introduction && (
                    <Container className={styles.introduction}>
                        <motion.div
                            {...{
                                initial: { opacity: 0, y: 25 },
                                whileInView: { opacity: 1, y: 0 },
                                viewport: { once: true, margin: "-100px" },
                                transition: { duration: 0.6, ease: "easeInOut" }
                            }}
                        >
                            {render(blok.introduction, {
                                defaultBlokResolver: (name, props) => blokReslover(name, props)
                            })}
                        </motion.div>
                    </Container>
                )}
                <div style={{ position: 'relative' }}>
                    <HorizontalScrollView className={styles.horizontalScroll} onDrag={() => {
                        setisScrolled(true)
                    }}>
                        {blok.slides.map((slide, index) => (
                            <motion.div
                                key={slide._uid}
                                {...{
                                    initial: { opacity: 0, x: 25 },
                                    whileInView: { opacity: 1, x: 0 },
                                    viewport: { once: true },
                                    transition: {
                                        duration: 0.6,
                                        delay: Math.min(-0.15 + (0.15 * index), 0.6),
                                        ease: "easeInOut"
                                    }
                                }}
                            >
                                <ImageSliderItem blok={slide} />
                            </motion.div>
                        ))}
                    </HorizontalScrollView>
                    {!isScrolled && (
                        <div className={styles.swipe}>
                            <div className={styles.path}></div>
                            <FontAwesomeIcon icon={faHandBackPointUp as IconProp} className={styles.scrollRight} />
                        </div>
                    )}
                </div>
            </div>
        </PageSection>
    )
}

export default ImageSlider