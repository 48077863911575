import React, { createContext, useState, useContext, ReactNode } from 'react';

type FormContextType<T> = {
  value: T | null;
  defaultValue: T | null;
  setDefaultValue: (options: T | ((prev: T) => T)) => void;
  clearOptions: () => void;
  setValue: (newValue: T| ((prev: T) => T)) => void;
  clearValue: () => void;
} | undefined;

const FormContext = createContext<FormContextType<any>>(undefined);

interface FormProviderProps<T> {
  children: ReactNode;
  initialValue?: T | null;
}

export function FormProvider<T>({ 
  children, 
  initialValue = null 
}: FormProviderProps<T>) {
  const [value, setValue] = useState<T | null>(initialValue);
  const [defaultValue, setDefaultValue] = useState<T | null>(null);

  const contextValue = {
    value,
    setValue,
    defaultValue,
    setDefaultValue,
    clearOptions: () => setDefaultValue(null),
    clearValue: () => setValue(null),
  };

  return (
    <FormContext.Provider value={contextValue}>
      {children}
    </FormContext.Provider>
  );
}

export function useFormProvider<T>() {
  const context = useContext(FormContext) as FormContextType<T>;
  
  if (context === undefined) {
    throw new Error('useFormProvider must be used within a FormProvider');
  }
  
  return context;
}
