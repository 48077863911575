"use client"
import React, { useRef, useState, useEffect, ReactElement } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './MapBox.module.css'
import {
  AlertDialog,
  AlertDialogContent,
} from '../alert-dialog/AlertDialog';
import Image from '../image';
import Button from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"
import CustomLink from '../link';

type Props = {
  points: {
    lng: number;
    lat: number;
    label?: string;
    description?: string
    image_path?: string
  }[]
}


const INITIAL_ZOOM = 10.12


/**
 * A Mapbox component that displays a map with markers at the given points.
 *
 * @param {Props} props
 * @param {Array<{lng: number, lat: number, label?: string, description?: string, url?: string, image_path?: string}>} props.points
 * @returns {ReactElement}
 */
const MapBox = ({ points }: Props): ReactElement => {
  const mapRef = useRef<mapboxgl.Map | null>(null)
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<mapboxgl.Popup | null>(null)

  const [, setCenter] = useState<[number, number]>([
    points[0].lng,
    points[0].lat
  ])
  const [selectedPoint, setSelectedPoint] = useState<{ lng: number, lat: number, label?: string, description?: string, url?: string, image_path?: string } | null>(null);
  const [zoom, setZoom] = useState<number>(INITIAL_ZOOM)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [interacted, setInteracted] = useState(false)

  // Detect if device is mobile/tablet or desktop/laptop
  useEffect(() => {
    const checkDeviceType = () => {
      // Check for touch capability
      const hasTouchScreen = (
        'ontouchstart' in window || 
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0
      );
      
      // Check for pointer capability (mouse/trackpad)
      const hasPointerFine = window.matchMedia('(pointer: fine)').matches;
      
      // Check for user agent patterns (last resort)
      const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      
      // Logic to determine device type:
      // 1. If device has fine pointer, likely a laptop/desktop even if it has touch
      // 2. If device has touch but no fine pointer, likely mobile/tablet
      // 3. Fall back to user agent if needed
      const isMobileOrTablet = (!hasPointerFine && hasTouchScreen) || 
                               (mobileUserAgent && hasTouchScreen);
      
      setIsMobile(isMobileOrTablet);
    };
    
    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);
    
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  useEffect(() => {
    if (!mapContainerRef.current) return;

    mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAP_BOX_ACCESS_TOKEN || ''
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      zoom: zoom,
      scrollZoom: false,
      projection: { name: 'mercator' },
      minZoom: 2,
      maxPitch: 0,
      bearing: 0
    });

    const bounds = new mapboxgl.LngLatBounds();

    if (!mapRef.current) return;

    points.forEach(point => {
      if (!mapRef.current) return;

      if (isNaN(point.lng) || isNaN(point.lat)) return

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25
      }).setHTML(`
        <div style="padding: 8px">
          <strong style="color: #452325">${point.label || ''}</strong>
        </div>
      `);

      bounds.extend([point.lng, point.lat]);
      const marker = new mapboxgl.Marker({ color: '#f4081f' })
        .setLngLat([point.lng, point.lat])
        .addTo(mapRef.current)
        .getElement()

      marker.style.cursor = 'pointer';

      marker.addEventListener('mouseenter', () => {
        if (!mapRef.current) return;

        // Remove existing popup if any
        if (popupRef.current) popupRef.current.remove();

        // Set and show new popup
        popupRef.current = popup;
        popup.addTo(mapRef.current)
          .setLngLat([point.lng, point.lat]);
      });

      // Hide popup when mouse leaves
      marker.addEventListener('mouseleave', () => {
        if (popupRef.current) {
          popupRef.current.remove();
          popupRef.current = null;
        }
      });

      marker.addEventListener('click', () => {
        setInteracted(true);
        setSelectedPoint(point);
        // Optionally remove popup when clicking
        if (popupRef.current) {
          popupRef.current.remove();
          popupRef.current = null;
        }
      });
    });

    if (!bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds, {
        padding: 50,
        duration: 1000
      });
    }

    // Set up different interactions for desktop vs mobile
    if (isMobile) {
      // Mobile behavior: disable scrollZoom and dragPan by default
      mapRef.current.scrollZoom.disable();
      mapRef.current.dragPan.disable();
      
      // Handle mobile touch behavior
      let touchStarted = false;

      mapRef.current.on('touchstart', (e) => {
        if (e.points.length === 2) {
          setInteracted(true);
          touchStarted = true;
          mapRef.current?.dragPan.enable();
          mapRef.current?.scrollZoom.enable();
        }
      });

      mapRef.current.on('touchmove', (e) => {
        if (!touchStarted && e.points.length === 1) {
          // Single finger scroll - keep map interactions disabled
          mapRef.current?.dragPan.disable();
          mapRef.current?.scrollZoom.disable();
        }
      });

      mapRef.current.on('touchend', () => {
        touchStarted = false;
        mapRef.current?.dragPan.disable();
        mapRef.current?.scrollZoom.disable();
      });
    } else {
      // Desktop behavior: enable dragPan by default, but keep scrollZoom disabled
      mapRef.current.dragPan.enable();
      mapRef.current.scrollZoom.disable();
      
      // Desktop interaction events
      mapRef.current.on('mousedown', () => {
        setInteracted(true);
      });
      
      // Enable scroll zoom only when ctrl key is pressed
      mapRef.current.on('wheel', (e) => {
        if (e.originalEvent.ctrlKey) {
          setInteracted(true);
          mapRef.current?.scrollZoom.enable();
        } else {
          mapRef.current?.scrollZoom.disable();
          e.preventDefault();
        }
      });
    }

    // Only track map center and zoom changes, but don't count automatic moves as interactions
    mapRef.current.on('movestart', (e) => {
      // Only count user-initiated moves as interactions
      if (e.originalEvent) {
        setInteracted(true);
      }
    });

    mapRef.current.on('move', () => {
      if (!mapRef.current) return;

      const mapCenter = mapRef.current.getCenter();
      const mapZoom = mapRef.current.getZoom();

      setCenter([mapCenter.lng, mapCenter.lat]);
      setZoom(mapZoom);
    });

    // Additional events that indicate user interaction
    mapRef.current.on('dragstart', () => setInteracted(true));
    mapRef.current.on('zoomstart', () => setInteracted(true));

    return () => {
      if (!mapRef.current) return;
      mapRef.current.remove();
    };
  }, [isMobile]); // Re-initialize map when isMobile changes

  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div id='map-container' ref={mapContainerRef} style={{
          width: '100%',
          height: '100%'
        }} />
        {!interacted && (
          <div style={{
            position: 'absolute', 
            bottom: '50px', 
            right: '50px', 
            backgroundColor: 'white', 
            padding: '10px 15px', 
            borderRadius: '50px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
            fontSize: '14px',
            transition: 'opacity 0.3s ease',
            opacity: 0.9
          }}>
            {isMobile ? 'Use Two Fingers to Zoom' : 'Drag to Move | Use Ctrl + Scroll to Zoom'}
          </div>
        )}
      </div>
      {selectedPoint && (
        <AlertDialog open={!!selectedPoint} onOpenChange={() => setSelectedPoint(null)}>
          <AlertDialogContent showBackdrop={false}>
            <div className={styles.popUp}>
              <div className={styles.alertDialogMain}>
                <div className={styles.highlightImage}>
                  <Image alt={selectedPoint.label} lazyLoad={false} className={styles.higlightBackgroundOverlay} asset={selectedPoint?.image_path || ''} size={{
                    width: 1280,
                    height: 720
                  }} />
                </div>
                <div className={styles.overlay} />

                <div className={styles.alertDialogInformation}>
                  <h1 className={styles.highlightDialogTitle}>{selectedPoint.label}</h1>
                  <div className={styles.alertDescription}>
                    <p>
                      {selectedPoint.description}
                    </p>
                  </div>
                  <Button variant='transparent' onClick={() => setSelectedPoint(null)} className={styles.alertDialogClose}>
                    <FontAwesomeIcon size='3x' icon={faXmark as IconProp} className={styles.alertDialogCloseIcon} />
                  </Button>
                </div>

                {(selectedPoint.url && (selectedPoint.url.trim().length > 0)) && (
                  <CustomLink
                    className={styles.alertDialogBtn}
                    asButton
                    storyBlokLink
                    buttonSize='large'
                    href={selectedPoint.url}
                  >
                    Explore {selectedPoint.label}
                  </CustomLink>
                )}
              </div>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  )
}

export default MapBox