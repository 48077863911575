import Button from "../button";
import PaginationLink from "./PaginationLink";
import styles from './Pagination.module.css';
import { faChevronLeft, faChevronRight } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  page: number;
  setPage?: (page: number) => void;
  pages: number;
  setPages?: (pages: number) => void;
  loading?: boolean;
  showOnFirst?: boolean;
}

const Pagination = ({ page, setPage, pages, /*setPages,*/ loading, showOnFirst = true }: Props) => {
  const firstPage = 1;
  const lastPage = pages;

  const getPageOffset = (page: number) => {
    if (page <= 2) {
      return 1;
    } else if (page >= lastPage - 2) {
      return lastPage - 4;
    } else {
      return page - 2;
    }
  }

  const centerOffset = getPageOffset(page);

  return (
    (page === 1 && showOnFirst) || page > 1 ?
      <div className={styles.wrapper}>
        <Button
          className={styles.paginationPrevious}
          disabled={page === 1 || loading}
          aria-label="Previous Page"
          variant='outline'
          onClick={() => {
            if (!loading && page > 1 && setPage) {
              setPage(page - 1)
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft as IconProp} />
          <span className={styles.nextPreviousLabel}>Previous</span>
        </Button>

        <ul className={styles.list}>
          {pages === 1 ?
            <PaginationLink
              page={page}
              disabled={loading}
              active={true}
            />
            : null}
          {pages <= 5 && pages > 1 ?
            Array.from({ length: pages }, (_, i) => i + 1).map(number => (
              <PaginationLink
                key={number}
                page={number}
                disabled={loading}
                active={number === page}
                onClick={() => {
                  if (setPage) {
                    setPage(number)
                  }
                }}
              />
            )) : null
          }
          {pages > 5 ?
            <>
              <PaginationLink
                key={firstPage}
                page={firstPage}
                disabled={loading}
                active={firstPage === page}
                onClick={() => {
                  if (setPage) {
                    setPage(firstPage)
                  }
                }}
              />
              {page > 3 ? <li><span className={styles.ellipsis}>&hellip;</span></li> : null}
              {Array.from({ length: 3 }, (_, i) => i + 1).map(number => (
                <PaginationLink
                  key={number + centerOffset}
                  page={number + centerOffset}
                  disabled={loading}
                  active={number + centerOffset === page}
                  onClick={() => {
                    if (setPage) {
                      setPage(number + centerOffset)
                    }
                  }}
                />
              ))}
              {page < pages - 2 ? <li><span className={styles.ellipsis}>&hellip;</span></li> : null}
              <PaginationLink
                key={lastPage}
                page={lastPage}
                disabled={loading}
                active={lastPage === page}
                onClick={() => {
                  if (setPage) {
                    setPage(lastPage)
                  }
                }}
              />
            </> : null
          }
        </ul>
        <Button
          className={styles.paginationNext}
          variant='outline'
          disabled={page === lastPage || loading}
          aria-label="Next Page"
          onClick={() => {
            if (!loading && page < pages && setPage) {
              setPage(page + 1)
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight as IconProp} />
          <span className={styles.nextPreviousLabel}>Next</span>
        </Button>
      </div> : null
  )
}

export default Pagination;