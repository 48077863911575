import { ShipDetailsStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React from 'react'
import { Barsandlounge } from '@/app/types/lambda-types'
import { getShipDetailsByTTID } from '@/utils/queries';
import styles from './ShipDetails.module.css'
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import HorizontalScrollView from '../../globals/carousels/custom_carousel';
import ThingsToDoItem from '../things_to_do/things_to_do_item';
import Carousel from '../slider/Carousel';
import { EmblaOptionsType } from "embla-carousel";
import SliderItem from './SliderItem';
import withErrorHandling from '@/app/lib/withErrorHandling';

const OPTIONS: EmblaOptionsType = {
  loop: true,
  containScroll: 'trimSnaps',
}

const ShipDetails = async ({ blok }: { blok: ShipDetailsStoryblok }) => {
  let fetchingData = true;


  const fetchShipDetails = async () => {
    try {
      const response = await getShipDetailsByTTID(parseInt(blok.ship_tt_id || '0'));
      return response
    } catch (e) {
      console.error(e);
    } finally {
      fetchingData = false;
    }
  }

  const shipDetail = await fetchShipDetails();


  const renderDynamicCarousel = (): React.ReactNode => {
    if (!shipDetail?.restaurants) return <></>
    if (shipDetail?.restaurants.length === 0) {
      return <></>
    } else if (shipDetail?.restaurants.length === 1) {
      return (
        <Container>
          <SliderItem
            image={shipDetail?.restaurants[0].image}
            title={shipDetail?.restaurants[0].name}
            description={shipDetail?.restaurants[0].description}
          />
        </Container>
      )
    } else if (shipDetail?.restaurants.length === 2) {
      return (
        <Carousel options={OPTIONS}>
          {shipDetail?.restaurants?.map((item, index) => (
            <SliderItem
              key={index}
              image={item.image}
              title={item.name}
              description={item.description}
            />
          ))}
          {shipDetail?.restaurants?.map((item, index) => (
            <SliderItem
              key={index}
              image={item.image}
              title={item.name}
              description={item.description}
            />
          ))}
        </Carousel>
      )
    } else {
      return (
        <Carousel options={OPTIONS}>
          {shipDetail?.restaurants?.map((item, index) => (
            <SliderItem
              key={index}
              image={item.image}
              title={item.name}
              description={item.description}
            />
          ))}
        </Carousel>
      )
    }
  }



  return (
    <div {...storyblokEditable(blok)}>
      {
        !fetchingData && shipDetail && shipDetail?.restaurants?.length > 0 && (
          <PageSection>
            <Container>
                <h1 className={styles.title}>Restaurants</h1>
            </Container>
            {!fetchingData ? (
              <>
                {renderDynamicCarousel()}
              </>
            ) : (
              <Container>
                <div className={styles.sliderShimmer} />
              </Container>
            )}
          </PageSection>
        )
      }

      {
        !fetchingData && shipDetail && shipDetail?.barsandlounges?.length > 0 && (
          <PageSection>
            <Container>
              <h1 className={styles.title}>Bars and Lounges</h1>
            </Container>
            {!fetchingData ? (
              <HorizontalScrollView className={styles.horizontalScroll}>
                {shipDetail?.barsandlounges.map((overview: Barsandlounge) => (
                  <ThingsToDoItem key={overview.name} items={{
                    title: overview.name,
                    description: overview.description,
                    background_image: overview.image,
                  }} />
                ))}
              </HorizontalScrollView>
            ) : (
              <HorizontalScrollView className={styles.horizontalScroll}>
                <div className={styles.tallShimmer} />
                <div className={styles.tallShimmer} />
                <div className={styles.tallShimmer} />
              </HorizontalScrollView>
            )}
          </PageSection>
        )
      }

      {
        !fetchingData && shipDetail && shipDetail?.activities.length > 0 && (
          <PageSection>
            <Container>
              <h1 className={styles.title}>Activities</h1>
            </Container>
            {!fetchingData ? (
              <HorizontalScrollView className={styles.horizontalScroll}>
                {shipDetail?.activities.map((overview: Barsandlounge) => (
                  <ThingsToDoItem key={overview.name} items={{
                    title: overview.name,
                    description: overview.description,
                    background_image: overview.image,
                  }} height='short' />
                ))}
              </HorizontalScrollView>
            ) : (
              <HorizontalScrollView className={styles.horizontalScroll}>
                <div className={styles.shortShimmer} />
                <div className={styles.shortShimmer} />
                <div className={styles.shortShimmer} />
              </HorizontalScrollView>
            )}
          </PageSection>
        )
      }
    </div>
  )
}

export default withErrorHandling(ShipDetails, "Could not load the ship details")