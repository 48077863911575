/**
 * A simple in-memory store that can be used to store data on the server-side.
 * The store is a singleton and can be accessed via the {@link getInstance} method.
 * The store uses a Map to store data, where each key is associated with an object that contains the value and a timestamp.
 * The timestamp is used to determine when the data should be removed from the store.
 * The store also has a cleanup interval, which is used to periodically remove old data from the store.
 * The default cleanup interval is 5 minutes, but it can be changed by setting the `cleanupInterval` property.
 */
class ServerStore {
  /**
   * The instance of the store.
   * @private
   */
  private static instance: ServerStore;

  /**
   * The Map that stores the data.
   * @private
   */
  private store: Map<string, { value: any; timestamp: number }>;

  /**
   * The interval at which the store should be cleaned up.
   * @private
   */
  private cleanupInterval: number = 5 * 60 * 1000; // 5 minutes

  /**
   * Creates a new instance of the store.
   * @private
   */
  private constructor() {
    this.store = new Map();
    this.startCleanup();
  }

  /**
   * Gets the instance of the store.
   * @returns The instance of the store.
   */
  public static getInstance(): ServerStore {
    if (!ServerStore.instance) {
      ServerStore.instance = new ServerStore();
    }
    return ServerStore.instance;
  }

  /**
   * Starts the cleanup interval.
   * @private
   */
  private startCleanup() {
    setInterval(() => {
      const now = Date.now();
      Array.from(this.store.entries()).forEach(([key, { timestamp }]) => {
        if (now - timestamp > this.cleanupInterval) {
          this.store.delete(key);
        }
      });
    }, this.cleanupInterval);
  }

  /**
   * Sets a value in the store.
   * @param key The key to set the value for.
   * @param value The value to set.
   * @param ttl The time to live for the value in milliseconds.
   * @returns void
   */
  public set(key: string, value: any, /*ttl: number = this.cleanupInterval*/): void {
    this.store.set(key, {
      value,
      timestamp: Date.now()
    });
  }

  /**
   * Gets a value from the store.
   * @param key The key to get the value for.
   * @returns The value associated with the key, or null if the key does not exist.
   */
  public get(key: string): any | null {
    const item = this.store.get(key);
    if (!item) return null;

    const now = Date.now();
    if (now - item.timestamp > this.cleanupInterval) {
      this.store.delete(key);
      return null;
    }

    return item.value;
  }

  /**
   * Deletes a value from the store.
   * @param key The key to delete the value for.
   * @returns void
   */
  public delete(key: string): void {
    this.store.delete(key);
  }
}

/**
 * The instance of the store.
 */
export const serverStore = ServerStore.getInstance();
