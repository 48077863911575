'use client'
import React, { useState } from 'react'
import TextField from '@/app/components/globals/textfield';
import Button from '@/app/components/globals/button';
import styles from "./Header.module.css"
import { faSearch, faArrowRight } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import SearchFilterDisplay from './SearchFilterDisplay';
import { useRouter } from 'next/navigation';
import { Article, KnowledgeBaseHeaderSearchFilter } from '@/app/types/lambda-types';
import removeMd from 'remove-markdown';
import { knowledgeBaseSearchFilter } from '@/utils/queries';
import { motion, MotionStyle } from 'framer-motion'

const FullHeaderSearch = () => {
  const [searchContent, setSearchContent] = useState<KnowledgeBaseHeaderSearchFilter>();
  const [search, setSearch] = useState('');
  const router = useRouter()
  const [searching, setSearching] = useState(false)

  const spinTransition = {
    loop: Infinity,
    ease: "linear",
    duration: 1,
  }

  const circleStyle = {
    display: "block",
    width: "28px",
    height: "28px",
    border: "0.2rem solid #e9e9e9",
    borderTop: "0.2rem solid #3498db",
    borderRadius: "50%",
    position: "absolute",
    boxSizing: "border-box",
    top: 5,
    left: 5
  };

  const containerStyle = {
    position: "relative",
    width: "40px",
    height: "40px",
    display: "flex",
  };


  const generateSubstringFromArticle = (article: Article, value: string) => {
    const descriptionWithoutMarkdown = removeMd(article.description);
    const indexOfSearch = descriptionWithoutMarkdown.toLowerCase().indexOf(value.toLowerCase());
    const substring = descriptionWithoutMarkdown.substring(indexOfSearch, indexOfSearch + value.length);
    const description = "..." + descriptionWithoutMarkdown.substring(indexOfSearch - 45, indexOfSearch) +
      `<span style="font-weight: bolder">${substring}</span>` +
      descriptionWithoutMarkdown.substring(indexOfSearch + value.length, indexOfSearch + 45) + "...";

    article.description = description

    return article;
  }


  const onSearch = async (value: string) => {
    const response = await knowledgeBaseSearchFilter(value);
    setSearchContent(response);
    setSearch(value);
  }

  const onSearchResults = async (value: string) => {
    setSearching(true)
   router.push(`/knowledge-base/search/${value}`);
  }

  return (
    <div className={styles.mainSearch}>
      <TextField
        placeholder="Have a question? Search here"
        searchable
        icon={faSearch}
        onSearch={onSearch}
        onKeyUp={e => e.key === 'Enter' && onSearchResults(search)}
        options={
          searchContent?.articles?.map((article) => (
            <SearchFilterDisplay
              key={article.id}
              article={generateSubstringFromArticle(article, search)}
            />
          ))
        }
        className={styles.search}
      />
      <Button onClick={() => onSearchResults(search)} className={styles.searchButton}>
        {searching === true ? (
          <div style={containerStyle as any}>
            <motion.span
              style={circleStyle as MotionStyle}
              animate={{ rotate: 360 }}
              transition={spinTransition}
            />
          </div>
        ) : (
          <>
            Search
            <FontAwesomeIcon icon={faArrowRight as IconProp} /></>
        )}
      </Button>
    </div>
  )
}

export default FullHeaderSearch