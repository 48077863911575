'use client'
import { KnowledgeBaseArticleViewType, Article as ArticleType } from "@/app/types/lambda-types";
import styles from "./ArticleView.module.css";
import React, { useState } from "react";
import Container from "@/app/components/globals/container";
import PageSection from "@/app/components/globals/page_section";
import Article from "../category_view/Article";
import RelatedCategory from "../category_view/RelatedCategory";
import Button from "@/app/components/globals/button";
import Showdown from 'showdown';

interface ArticleProps{
  result: KnowledgeBaseArticleViewType
}

const ArticleView = ({ result }: ArticleProps) => {
  const [response,] = useState<KnowledgeBaseArticleViewType>(result);
  const [showAll, setShowAll] = useState(false);

  const getCombinedResults = () => {
    const articles = response?.articles || [];
    const categories = response?.categories || [];
    const combined = [...articles, ...categories];
    
    return showAll ? combined : combined.slice(0, 5);
  };

  const createMarkup = () => {
    const converter = new Showdown.Converter();
    return { __html: converter.makeHtml(response?.content ?? "") };
  };

  return (
    <PageSection>
      <Container>
        <h1 className={styles.title}>
          {response?.breadcrumbs?.[response?.breadcrumbs?.length - 1]?.name}
        </h1>
        <div className={styles.articleView}>
          <div className={styles.articleContent}
          dangerouslySetInnerHTML={createMarkup()}
          />
          <div className={styles.relatedArticles}>
            <h3>Related Articles</h3>
            {getCombinedResults().map(item => 
              'children' in item ? (
                <RelatedCategory key={item.uuid} category={item} />
              ) : (
                <Article key={item.uuid} article={item as ArticleType} />
              )
            )}
            {(response?.articles?.length || 0) + (response?.categories?.length || 0) > 5 && (
              <Button 
                onClick={() => setShowAll(!showAll)}
                variant="transparent"
              >
                {showAll ? "Show Less" : "Show More"}
              </Button>
            )}
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default ArticleView;

