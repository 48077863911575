'use client'
import { AssetStoryblok, SliderStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import React, { useState } from "react";
import SliderItem from "./SliderItem";
import styles from "./Slider.module.css";
import PageSection from "../../globals/page_section";
import Container from "../../globals/container";
import Carousel from "./Carousel";
import { EmblaOptionsType } from "embla-carousel";

const OPTIONS: EmblaOptionsType = { 
    loop: true,
    containScroll: 'trimSnaps',
}
const Slider = ({ blok }: { blok: SliderStoryblok }) => {
  const [selectedImage, setSelectedImage] = useState<AssetStoryblok[]>([])
  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <div className={styles.sliderText}>
            <h2>{blok.title}</h2>
            <p>{blok.description}</p>
          </div>
        </Container>
        <Carousel blok={blok.items || []} options={OPTIONS} setSelectedImage={setSelectedImage}>
          {blok.items?.map((item, index) => (
            <SliderItem key={item._uid} blok={item} image={selectedImage && selectedImage[index]} />
          ))}
        </Carousel>
      </PageSection>
    </div>
  );
};

export default Slider;
