'use client'
import { FullSizeCarouselSlideStoryblok, FullSizeCarouselStoryblok } from '@/app/types/component-types-sb';
import { storyblokEditable } from '@storyblok/react';
import useEmblaCarousel from 'embla-carousel-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import React from 'react';
import Image from '../../globals/image';
import CustomLink from '../../globals/link';
import styles from './FullSizeCarousel.module.css';
import SearchBar from '../../globals/search-bar';
import useResponsive from '@/utils/hooks/useResponsive';

const FullSizeCarousel = ({ blok }: { blok: FullSizeCarouselStoryblok }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center'
  });
  const {width} = useResponsive();

  return (
    <div {...storyblokEditable(blok)} className={styles.outterContainer}>
      <FontAwesomeIcon icon={faChevronLeft as IconProp} onClick={() => emblaApi?.scrollPrev()} color='white' size={width <= 768 ? '4x' : '5x'} className={styles.prev} aria-hidden='true' />
      <FontAwesomeIcon icon={faChevronRight as IconProp} onClick={() => emblaApi?.scrollNext()} color='white' size={width <= 768 ? '4x' : '5x'} className={styles.next} aria-hidden='true' />
      <div className={styles.searchBarContainer}>
        <div className='container container-expanded'>
          <SearchBar />
        </div>
      </div>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {
            blok.slides?.map((nestedBlok: FullSizeCarouselSlideStoryblok, index) => (
              <div key={index} className={styles.slide}>
                <div className={styles.overlay}>
                </div>
                <CustomLink link={nestedBlok.link}>
                  <Image asset={nestedBlok.background || ''} />
                  <Image asset={nestedBlok.foreground || ''} className={`${styles.foreground} container container--expanded`} />
                </CustomLink>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default FullSizeCarousel;