import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Container from '../container';
import PageSection from '../page_section';
import ReloadButton from './ReloadButton';

const ErrorComponent = ({ message = "Could not load component", error, reset }: { message?: string, error?: any, reset?: any }) => {


  const showError = () => {
    if (!error) return <></>

    const errorMessage = error.message || 'An unknown error occurred';
    const errorName = error.name || 'Error';
    const errorStack = error.stack;

    return (
      <div className="flex flex-col items-center space-y-4" role="alert">
        <h3 className="mb-1 mt-5">{errorName}</h3>
        <p>{errorMessage}</p>
        {errorStack && (
          <details>
            <summary>Error Details</summary>
            <pre>{errorStack}</pre>
          </details>
        )}
      </div>
    )
  }

  return (
    <PageSection>
      <Container>
        <div className="flex items-center justify-center w-full p-6 border border-red-200 rounded-md bg-red-50">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="rounded-full p-3 bg-red-100">
              <FontAwesomeIcon size='3x' icon={faCircleExclamation as IconProp} className="text-red-500" />
            </div>

            <div className="flex flex-col items-center">
              <h3 className="text-lg font-medium text-red-700">Component Error</h3>
              <p className="text-red-600">{message}</p>
              {showError()}
            </div>
            <ReloadButton reset={reset} />
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default ErrorComponent;