import React from "react";
import { storyblokEditable, StoryblokServerComponent, SbBlokData } from "@storyblok/react/rsc";
import type { GridLayoutStoryblok } from "@/app/types/component-types-sb";
import styles from './grid-layout.module.css';
import clsx from "clsx";
import Container from "../../globals/container";

const GridLayout = ({ blok }: { blok: GridLayoutStoryblok }) => {
  const getColumnClass = (type: string, columns?: string) => {
    switch (columns) {
      case "1":
        return styles[`${type}-columns-1`];
      case "2":
        return styles[`${type}-columns-2`];
      case "3":
        return styles[`${type}-columns-3`];
      case "4":
        return styles[`${type}-columns-4`];
      case "5":
        return styles[`${type}-columns-5`];
      case "6":
        return styles[`${type}-columns-6`];
      default:
        return "desktop-columns-4";
    }
  }

  return (
    <div className="page-section">
      <Container>
        {
          blok.title ?
            <h2 className="section-header">{blok.title}</h2>
            : null
        }
        <div {...storyblokEditable(blok)} className={
          clsx(
            styles.container,
            getColumnClass("desktop", blok.columns_desktop),
            getColumnClass("tablet", blok.columns_tablet),
            getColumnClass("mobile", blok.columns_mobile)
          )
        }>
          {
            blok.grid_items?.map((nestedBlok: SbBlokData) => (
              <StoryblokServerComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))
          }
        </div>
      </Container>
    </div>
  );
}

export default GridLayout;