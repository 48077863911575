'use client'
import { GroupStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import Container from '../../globals/container'
import { Filter, GroupResults, Holiday } from '@/app/types/lambda-types'
import { getDynamicGroups, getFeaturedDeals, getPhoneNumber } from '@/utils/queries'
import DynamicGroup from '../../globals/dynamic_group'
import { getCookie } from '@/utils/cookies'
import Terms from './Terms'
import { render } from 'storyblok-rich-text-react-renderer'
import { blokReslover } from '../content_split_2_images/ContentSplitTwoImageTextImage'
import styles from './Group.module.css'
import clsx from 'clsx'
interface GroupProps {
  blok: GroupStoryblok
  initGroup: GroupResults | undefined | Holiday[]
  initialFilters?: Filter
}

const GroupBlok = ({ blok, initGroup, initialFilters }: GroupProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [group, setGroup] = useState<GroupResults | Holiday[] | undefined>(initGroup);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  // Memoize the fetch function to prevent unnecessary recreations
  const fetchDynamicGroups = useCallback(async () => {
    if (!blok.group_uuid) return;

    setIsLoading(true);
    try {
      if (blok.data_field === "2") {
        const result = await getFeaturedDeals(blok.group_uuid);
        if (result) {
          setGroup(result);
        }
      } else {
        const result = await getDynamicGroups(
          blok.group_uuid,
          blok.page_size || '12',
          filters.sort_by ? filters.sort_by : blok.sort_by || undefined,
          blok.sort_direction || 'ASC',
          true,
          page,
          filters.ship === "*" ? undefined : filters.ship,
          filters.region === "*" ? undefined : filters.region,
          filters.date === "*" ? undefined : filters.date
        );
        if (result) {
          setGroup(result);
          // Scroll after data is loaded, but only if page > 1
          if (page > 1) {
            containerRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      setIsLoading(false);
    }
  }, [
    blok.group_uuid,
    blok.data_field,
    blok.page_size,
    blok.sort_by,
    blok.sort_direction,
    filters.sort_by,
    filters.ship,
    filters.region,
    filters.date,
    page
  ]);

  // Fetch phone number only once on mount
  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const phoneNumberResponse = await getPhoneNumber(getCookie('aff'));
        setPhoneNumber(phoneNumberResponse);
      } catch (error) {
        console.error('Error fetching phone number:', error);
      }
    };
    fetchPhoneNumber();
  }, []);

  // Fetch groups when dependencies change
  useEffect(() => {
    fetchDynamicGroups();
  }, [fetchDynamicGroups]);

  return (
    <div ref={containerRef} className="page-section story-blokgroups" {...storyblokEditable(blok)}>
      <Container>
        {((blok.show_title !== false) || (blok.show_title == undefined)) && (
          <h2 className="section-header">
            {blok.title && blok.title !== '' ? blok.title : 'Amazing Holiday Deals'}
          </h2>
        )}
        <Container notExpanded={true}>
          {((blok.show_introduction !== false) || (blok.show_introduction == undefined)) && (
            blok.introduction?.content![0]?.content ?
              <div className={clsx(styles.intro, "text-content")}>
                {render(blok.introduction,
                  {
                    defaultBlokResolver: (name, props) => blokReslover(name, props)
                  })}
              </div> : blok.show_introduction == undefined
          )}
        </Container>
        <DynamicGroup
          group={group}
          data_field={blok.data_field}
          uuid={blok.group_uuid}
          initSort={blok.sort_by}
          show_pagination={blok.show_pagination}
          initialFilters={initialFilters}
          onPageChange={setPage}
          onFiltersChange={setFilters}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <Terms phoneNumber={phoneNumber} />
      </Container>
    </div>
  )
}

export default GroupBlok