import clsx from 'clsx';
import styles from './FromPrice.module.css';

type Props = {
  price: number | string;
  fontSize?: string; // Optional prop for changing font size of price text
  style?: 'result' | 'starbuy';
}

const FromPrice = ({ price, fontSize, style }: Props) => {
  return (
    <div
      className={clsx(styles.container, style === 'starbuy' ? styles.starbuy : '')}
      style={{
        fontSize: fontSize || '64px'
      }}
    >
      <span className={styles.from}>
        From
      </span>
      <span
        className={styles.price}
        style={{
          fontSize: fontSize || '64px'
        }}
      >
        {
          price ?
            `£${price}`
            : null
        }
      </span>
      <span className={styles.PP}>PP*</span>
    </div>
  )
}

export default FromPrice;