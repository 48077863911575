import { useState, useEffect } from 'react';
import { throttle } from '../actions';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const breakPoints = [
  'xs',
 'sm',
 'md',
 'lg',
 'xl',
 '2xl',
]

export interface BreakpointConfig {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  '2xl': number;
}

export const defaultBreakpoints: BreakpointConfig = {
  xs: 576,
  sm: 580,
  md: 768,
  lg: 1024,
  xl: 1200,
  '2xl': 1500,
};

export interface ResponsiveOutput {
  breakpoint: Breakpoint;
  width: number;
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  is2Xl: boolean;
  isSmAndUp: boolean;
  isMdAndUp: boolean;
  isLgAndUp: boolean;
  isXlAndUp: boolean;
}

/**
 * useResponsive
 * 
 * A React hook for determining the current breakpoint from a given set of breakpoints
 * and returning a ResponsiveOutput object with the current breakpoint and a set of booleans
 * for each breakpoint.
 * 
 * @param customBreakpoints - An object with custom breakpoints. The object should have the same shape as
 * the defaultBreakpoints object.
 * @returns A ResponsiveOutput object with the current breakpoint and a set of booleans for each breakpoint.
 * 
 * @example const { isMd } = useResponsive();
 */
const useResponsive = (customBreakpoints?: Partial<BreakpointConfig>): ResponsiveOutput => {
  const breakpoints = { ...defaultBreakpoints, ...customBreakpoints };
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    const throttleResize = throttle(handleResize, 500);
    window.addEventListener('resize', throttleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', throttleResize);
    };
  }, []);

  if (typeof width === 'undefined') {
    // Return default values during SSR
    return {
      breakpoint: 'md', // Or whatever default breakpoint you want
      width: 0,
      isXs: false,
      isSm: false,
      isMd: true, // Set your default state here
      isLg: false,
      isXl: false,
      is2Xl: false,
      isSmAndUp: true,
      isMdAndUp: true,
      isLgAndUp: false,
      isXlAndUp: false,
    };
  }

  const getBreakpoint = (): Breakpoint => {
    if (width >= breakpoints['2xl']) return '2xl';
    if (width >= breakpoints.xl) return 'xl';
    if (width >= breakpoints.lg) return 'lg';
    if (width >= breakpoints.md) return 'md';
    if (width >= breakpoints.sm) return 'sm';
    return 'xs';
  };

  return {
    breakpoint: getBreakpoint(),
    width,
    isXs: width >= breakpoints.xs && width < breakpoints.sm,
    isSm: width >= breakpoints.sm && width < breakpoints.md,
    isMd: width >= breakpoints.md && width < breakpoints.lg,
    isLg: width >= breakpoints.lg && width < breakpoints.xl,
    isXl: width >= breakpoints.xl && width < breakpoints['2xl'],
    is2Xl: width >= breakpoints['2xl'],
    isSmAndUp: width >= breakpoints.sm,
    isMdAndUp: width >= breakpoints.md,
    isLgAndUp: width >= breakpoints.lg,
    isXlAndUp: width >= breakpoints.xl,
  };
};

export default useResponsive;