import { GroupStoryblok } from '@/app/types/component-types-sb';
import { fetchFilters, getDynamicGroups, getFeaturedDeals } from '@/utils/queries';
import React from 'react'
import GroupBlok from './Group';
import withErrorHandling from '@/app/lib/withErrorHandling';

const Group = async ({ blok }: { blok: GroupStoryblok, currentTab?: number }) => {

  //TODO: Understand what withImages is meant to do
  async function fetchDynamicGroups() {
    if (!blok.group_uuid) return;

    if (blok.data_field === "2") {
      const result = await getFeaturedDeals(
        blok.group_uuid
      );
      return result
    } else {
      const result = await getDynamicGroups(
        blok.group_uuid,
        blok.page_size || '12',
        blok.sort_by || undefined,
        blok.sort_direction || 'ASC',
        true,
        1,
        undefined,
        undefined,
        undefined
      );
      return result
    }
  }

  const groups = await fetchDynamicGroups();

  const responseFilters = await fetchFilters(blok.group_uuid);

  return (
    <GroupBlok blok={blok} initGroup={groups} initialFilters={responseFilters} />
  )
}

export default withErrorHandling(Group, "Could not load the Group component")