import React from 'react';
import HolidayResult from '@/app/components/globals/holiday/result';
import { StarBuyStoryblok } from '@/app/types/component-types-sb';
import { getStarBuyHoliday } from '@/utils/queries';
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@awesome.me/kit-d4c82d9167/icons/classic/regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './StarBuy.module.css';
import withErrorHandling from '@/app/lib/withErrorHandling';

const StarBuy = async ({ blok }: { blok: StarBuyStoryblok }) => {
  if (!blok.group) return null;

  const response = await getStarBuyHoliday(blok.group);
  if (!response.holiday) return null;

  return (
    <PageSection>
      <Container>
        <h2 className={clsx("section-header", styles.header)}>
          <FontAwesomeIcon icon={faStar as IconProp} />
          <span>Star Buy</span>
        </h2>
        <HolidayResult holiday={response.holiday} portGuideImageIndex={blok.preferred_image} style="starbuy" />
      </Container>
    </PageSection>
  )
};

export default withErrorHandling(StarBuy, 'Star Buy failed to load');
