import React, { useState, useRef, useEffect } from 'react';
import styles from './ExpandableHeading.module.css';

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

interface ExpandableHeadingProps {
  text: string;
  level?: HeadingLevel;
  className?: string;
}

const ExpandableHeading = ({
  text,
  level = 'h3',
  className = ''
}: ExpandableHeadingProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const element = headingRef.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const HeadingTag = level;

  return (
    <div className={styles.wrapper}>
      <HeadingTag
        ref={headingRef}
        className={`${!isExpanded ? styles.clamp : ''} ${className}`}
      >
        {text}
      </HeadingTag>

      {isOverflowing && !isExpanded && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={styles.expand}
          aria-label="Expand text"
        >
          +
        </button>
      )}
    </div>
  );
};

export default ExpandableHeading;