import { formatDate } from "@/utils/configs";
import getOrdinal from "@/utils/data-manipulation/getOrdinal";
import React from 'react';

const FormattedDate: React.FC<{ date: string }> = ({ date }) => {
  const monthYear = formatDate(date).format('MMM YY');
  const day = formatDate(date).date();
  const dayWithOrdinal = getOrdinal(day);
  const matches = dayWithOrdinal.match(/(\d+)(.+)/);

  if (!matches) {
    return <>{dayWithOrdinal} {monthYear}</>;
  }

  const [, num, suffix] = matches;
  return (
    <span>
      {num}<sup>{suffix}</sup> {monthYear}
    </span>
  );
};

// For backwards compatibility and non-React contexts
export const getOfferDateFormat = (date: string): string => {
  const monthYear = formatDate(date).format('MMM YYYY');
  const day = formatDate(date).date();
  const dayWithOrdinal = getOrdinal(day);
  return `${dayWithOrdinal} ${monthYear}`;
};

export default FormattedDate; 