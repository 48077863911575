import { useEffect, useRef, useState } from "react";

/**
 * Checks if the content of a container is overflowing its bounds.
 *
 * Returns an object with two properties:
 * - `containerRef`: a reference to the container element to check for overflow.
 * - `isOverflowing`: a boolean indicating whether the container is overflowing.
 *
 * Note that this hook also listens for window resize events and updates the
 * `isOverflowing` state accordingly.
 */
const useIsScrollable = () => {
  const containerRef = useRef<HTMLElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (containerRef.current) {
      const hasOverflow = containerRef.current.scrollWidth > containerRef.current.clientWidth;
      
      if (hasOverflow !== isOverflowing) {
        setIsOverflowing(hasOverflow);
      }
    }
  };

  useEffect(() => {
    checkOverflow();
    
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [containerRef.current?.children]);

  return { containerRef, isOverflowing };
}

export default useIsScrollable