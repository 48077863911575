'use client'
import React, { useState } from 'react'
import styles from '../ImageSlider.module.css';
import { storyblokEditable } from "@storyblok/react/rsc"
import { ImageSliderItemStoryblok } from '@/app/types/component-types-sb';
import Image from '@/app/components/globals/image';
import Button from '@/app/components/globals/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
} from '@/app/components/globals/alert-dialog/AlertDialog';
import useResponsive from '@/utils/hooks/useResponsive';

const ImageSliderItem = ({ blok }: { blok: ImageSliderItemStoryblok }) => {
  const [showDialog, setShowDialog] = useState(false);
  const { width } = useResponsive()

  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className={styles.highlight}>

        {/* Content that appears on hover */}

        {/* Modal Dialog */}
        <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
          <AlertDialogTrigger asChild>
            <div className={styles.content}>
              <Image
                asset={blok.background_image ?? ''}
                size={{
                  width: 460,
                  height: 460
                }}
              />
              <div className={styles.imageBackground}>
                <FontAwesomeIcon
                  icon={faSearch as IconProp}
                  size='3x'
                  color='white'
                  className={styles.searchIcon}
                />
              </div>
            </div>
          </AlertDialogTrigger>
          <AlertDialogContent showBackdrop={true}>
            <div className={styles.alertDialogContent}>
              <div className={styles.alertDialogMain}>
                {/* Modal Background Image */}
                <div className={styles.highlightImage}>
                  <Image
                    className={styles.higlightBackgroundOverlay}
                    asset={blok.background_image ?? ''}
                    size={{
                      width: 1280,
                      height: 720
                    }}
                  />
                </div>
                <Button
                  variant='transparent'
                  onClick={() => setShowDialog(false)}
                  className={styles.alertDialogClose}
                >
                  <FontAwesomeIcon
                    size={width > 400 ? '3x' : '2x'}
                    icon={faXmark as IconProp}
                    className={styles.alertDialogCloseIcon}
                  />
                </Button>
              </div>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  )
}

export default ImageSliderItem