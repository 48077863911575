import { AssetStoryblok, SliderItemStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import styles from './Slider.module.css'
import Image from '../../globals/image'


const SliderItem = ({ blok, image }: { blok: SliderItemStoryblok, image?: AssetStoryblok }) => {
  return (
    <div className={styles.sliderItem} {...storyblokEditable(blok)}>
      <div className={styles.imageWrapper}>
        {blok.background_image ?
          <Image
          size={{ width: 1920, height: 800 }}
           asset={image ? image : blok.background_image}
            /> : null
        }
      </div>
      <div className={styles.sliderItemOverlay} />
      <div className={styles.sliderItemContent}>
        <h2 className={styles.sliderItemTitle}>{blok.title}</h2>

        <p className={styles.sliderItemDescription}>{blok.description}</p>
      </div>
    </div>
  )
}

export default SliderItem