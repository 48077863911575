import { FieldStoryblok, FormSectionStoryblok, InputfieldStoryblok, SectionSelectionStoryblok, SelectStoryblok, TextareaStoryblok } from '@/app/types/component-types-sb';
import { useEffect, useState, RefObject } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

interface UseCheckFormReturn {
  hasFormParent: boolean;
  fieldPath: string;
}

/**
 * useIsOutOfView
 * @description Hook to determine if an element is out of view.
 * @param {RefObject<T>} ref - The ref to the element to check.
 * * @param {FieldStoryblok | InputfieldStoryblok | SelectStoryblok | TextareaStoryblok | FormSectionStoryblok | SectionSelectionStoryblok} blok - The blok to check.
 * @param {UseFormSetValue<FieldValues>} setValue - The setValue function from react-hook-form.
 * @param {string} key - The key to update in the form.
 * @param {Function} updateValue - The function to update the value in the form.
 * @param {Function} setDefaultValue - The function to set the default value in the form.
 * @returns {UseCheckFormReturn} - An object with a single property `isOutOfView` which is a boolean indicating if the element is out of view.
 */
const useCheckForm = <T extends HTMLElement>(
  componentRef: RefObject<T>,
  blok: FieldStoryblok | InputfieldStoryblok | SelectStoryblok | TextareaStoryblok | FormSectionStoryblok | SectionSelectionStoryblok,
  setValue?: UseFormSetValue<FieldValues>,
  key?: string,
  updateValue?: (newValue: {
    [fieldPath: string]: string;
} | ((prev: {
    [fieldPath: string]: string;
}) => {
    [fieldPath: string]: string;
})) => void,
  setDefaultValue?: (options: {
    [fieldPath: string]: string;
} | ((prev: {
    [fieldPath: string]: string;
}) => {
    [fieldPath: string]: string;
})) => void
): UseCheckFormReturn => {
  const [hasFormParent, setHasFormParent] = useState<boolean | null>(null)
  const [fieldPath, setFieldPath] = useState('')
  const branch = process.env.NEXT_PUBLIC_BRANCH

  // Check if any parent element is a form
  useEffect(() => {
    if (componentRef.current) {
      // For form parent check
      let currentElementForForm = componentRef.current.parentElement;
      let hasForm = false;

      while (currentElementForForm) {
        if (currentElementForForm.tagName.toLowerCase() === 'form') {
          hasForm = true;
          break;
        }
        currentElementForForm = currentElementForForm.parentElement;
      }

      setHasFormParent(hasForm);

      if (blok.name) {
        // For building the complete field path
        let currentElement = componentRef.current.parentElement;
        const fieldParts = [];

        // Traverse up the DOM tree to collect all data-field-name attributes
        while (currentElement) {
          const fieldNameAttr = currentElement.getAttribute('data-field-name');
          if (fieldNameAttr) {
            // Add to the beginning of our parts array (since we're going up the tree)
            fieldParts.unshift(fieldNameAttr);
          }
          currentElement = currentElement.parentElement;
        }

        // Add the current field name to the path
        const fieldName = blok.name.toLowerCase().replaceAll(" ", "_");

        // Build the complete path
        let completePath;
        if (fieldParts.length > 0) {
          // Join all parent paths and add current field
          completePath = [...fieldParts, fieldName].join('.');
        } else {
          completePath = fieldName;
        }

        setFieldPath(completePath);
      }

      if (setDefaultValue && updateValue && key) {
        if (blok.option && blok.option.length > 0) {
          const defaultOptionValue = blok.option[0].section_identifier.toLowerCase().replaceAll(" ", "_")
          
          // Update default value while preserving other fields
          setDefaultValue(prev => {
            const updatedDefaults = { ...(prev || {}) }
            updatedDefaults[key] = defaultOptionValue
            return updatedDefaults
          })
          
          // Also set the current value if it's not already set
          updateValue(prev => {
            const currentValues = { ...(prev || {}) }
            if (!currentValues[key]) {
              currentValues[key] = defaultOptionValue
            }
            return currentValues
          })
        }
      }
    }
  }, []);

  useEffect(() => {
    if (hasFormParent === false) {
      if (branch === 'staging' || branch === 'dev') {
        console.warn(`This warning is only visible in staging and dev environments
              and will not affect the functionality of the component`)
        console.warn(`${blok.component} with name: ${blok.name} is not wrapped in a Form component`)

        throw new Error(`${blok.component} with name: ${blok.name} is not wrapped in a Form component, please wrap it in a Form component`)
      }
    }
  }, [hasFormParent, branch, blok.name])

  useEffect(() => () => {
    if (!setValue) return
    setValue(fieldPath || blok.name.toLowerCase().replaceAll(" ", "_"), undefined)
  }, [fieldPath]);

  if ((hasFormParent === false) && (branch !== 'staging' && branch !== 'dev')) return { hasFormParent, fieldPath }

  return { hasFormParent: hasFormParent || false, fieldPath };
};

export default useCheckForm;