import { KnowledgeBaseChildrenRoutes } from "@/app/types/lambda-types";
import { getKnowledgeBaseChildrenRoutes } from "@/utils/queries";
import React from "react";
import CategoryView from "./CategoryView";
import { CategoryViewStoryblok } from "@/app/types/component-types-sb";
import { serverStore } from "@/utils/lib/serverstore";
import withErrorHandling from "@/app/lib/withErrorHandling";

const CategoryWrapper = async ({ blok }: { blok: CategoryViewStoryblok }) => {
  const uuid = serverStore.get('uuid');

  const fetchData = async () => {
    const data = await getKnowledgeBaseChildrenRoutes(uuid as string);
    return data
  };

  const result: KnowledgeBaseChildrenRoutes = await fetchData();

  return (
    <CategoryView result={result} blok={blok} />
  );
};

export default withErrorHandling(CategoryWrapper, "Could not load the category");