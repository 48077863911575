"use client"

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import * as ThinIcons from "@awesome.me/kit-d4c82d9167/icons/classic/thin";
import * as LightIcons from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import * as RegularIcons from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import * as SolidIcons from "@awesome.me/kit-d4c82d9167/icons/classic/solid";

type IconStyle = 'thin' | 'light' | 'regular' | 'solid';
type IconCollections = {
  thin: typeof ThinIcons;
  light: typeof LightIcons;
  regular: typeof RegularIcons;
  solid: typeof SolidIcons;
};

const iconCollections: IconCollections = {
  thin: ThinIcons,
  light: LightIcons,
  regular: RegularIcons,
  solid: SolidIcons
};

interface DynamicIconProps {
  iconName: string;
  className?: string;
  style?: IconStyle;
}

const DynamicIcon: React.FC<DynamicIconProps> = ({
  iconName,
  className,
  style = 'light'  // Default to light style
}) => {
  const formattedIconName = `fa${iconName.split('-').map(part =>
    part.charAt(0).toUpperCase() + part.slice(1)
  ).join('')}` as keyof typeof LightIcons;

  const icon = iconCollections[style][formattedIconName] as IconDefinition;

  if (!icon) return null;
  return <FontAwesomeIcon icon={icon} className={className} />;
};

export default DynamicIcon;