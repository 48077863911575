import styles from './PricePerNight.module.css';
import clsx from 'clsx';

const PricePerNight = ({ nights, price, style }: { nights: number, price: number, style: 'result' | 'starbuy' | undefined }) => {

  // calculates price per night rounded to the nearest whole number
  const per_per_night = Math.round(price / nights);

  return (
    <div className={styles.pricePerNight}>
      That's only <span className={clsx(style === 'starbuy' && styles.starbuy)}>£{per_per_night}</span> Per Night!
    </div>
  );
};

export default PricePerNight;
