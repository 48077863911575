'use client'
import React, { useRef } from 'react'
import { storyblokEditable } from "@storyblok/react/rsc"
import { InputfieldStoryblok } from '@/app/types/component-types-sb'
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from '../../globals/form'
import TextField from '../../globals/forms/text-field'
import { useFormContext } from 'react-hook-form'
import { Switch } from '../../globals/form/Switch'
import useCheckForm from '@/utils/hooks/useCheckForm'

const FormInputField = ({ blok }: { blok: InputfieldStoryblok }) => {
  const { control, setValue } = useFormContext()
  const componentRef = useRef<HTMLDivElement>(null)
  const branch = process.env.NEXT_PUBLIC_BRANCH

  const {hasFormParent, fieldPath} = useCheckForm(componentRef, blok, setValue);
  if ((hasFormParent === false) && (branch !== 'staging' && branch !== 'dev')) return null


  if (blok.type === 'switch') {
    return (
      <div ref={componentRef} {...storyblokEditable(blok)} data-field-name={blok.name.toLowerCase().replaceAll(" ", "_")}>
        <FormField
          control={control}
          name={fieldPath || blok.name.toLowerCase().replaceAll(" ", "_")}
          render={({ field }) => (
            <FormItem>
              {blok.showLabel !== false && (
                <FormLabel className='font-bold'>{blok.label} {blok.required && <span className="text-destructive-foreground">*</span>}</FormLabel>
              )}
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} required={blok.required} {...field} />
              </FormControl>
              {blok.showLabel !== false && (
                <FormDescription>
                  {blok.description}
                </FormDescription>
              )}
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    )
  }

  if ((blok.type === 'checkbox') || (blok.type === 'file')) {
    return (
      <div ref={componentRef} {...storyblokEditable(blok)} data-field-name={blok.name.toLowerCase().replaceAll(" ", "_")}>
        <FormField
          control={control}
          name={fieldPath || blok.name.toLowerCase().replaceAll(" ", "_")}
          render={({ field }) => (
            <FormItem>
              {blok.showLabel !== false && (
                <FormLabel className='font-bold'>{blok.label} {blok.required && <span className="text-destructive-foreground">*</span>}</FormLabel>
              )}
              <FormControl>
                <input type={blok.type} {...field} required={blok.required} />
              </FormControl>
              {blok.showLabel !== false && (
                <FormDescription>
                  {blok.description}
                </FormDescription>
              )}
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    )
  }

  return (
    <div ref={componentRef} {...storyblokEditable(blok)} data-field-name={blok.name.toLowerCase().replaceAll(" ", "_")}>
      <FormField
        control={control}
        name={fieldPath || blok.name.toLowerCase().replaceAll(" ", "_")}
        render={({ field }) => (
          <FormItem>
            {blok.showLabel !== false && (
              <FormLabel className='font-bold'>{blok.label} {blok.required && <span className="text-destructive-foreground">*</span>}</FormLabel>
            )}
            <FormControl>
              <TextField inputName={blok.name.toLowerCase().replaceAll(" ", "_")} type={blok.type || 'text'} {...field} required={blok.required} />
            </FormControl>
            {blok.showLabel !== false && (
              <FormDescription>
                {blok.description}
              </FormDescription>
            )}
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export default FormInputField