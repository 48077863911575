import { forwardRef } from 'react';
import styles from './Select.module.css';
interface Props {
  className?: string;
  options?: Array<{ label: string, value: string | number }>; // Added number to support your ID type
  value?: string;
  label?: string;
  onChange?: (value: string) => void;
  ['aria-label']?: string;
  disabled?: boolean;
  error?: string;
  required?: boolean
}

const Select = forwardRef<HTMLSelectElement, Props>(({
  // className,
  options,
  value,
  onChange,
  "aria-label": ariaLabel,
  disabled,
  label,
  error,
  required
}, ref) => {
  return (
    <div className={styles.mainWrapper}>
      <label className={styles.labelStyle}>
        {label}
        <div
        {...(label && { style: { marginTop: 10 }  })}
        className={styles.wrapper}>
          <select
            ref={ref}
            className={styles.select}
            value={value}
            aria-label={ariaLabel}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
            required={required}
          >
            {
              options?.map((option, index) => (
                <option aria-label={option.label} key={index} value={option.value}>
                  {option.label}
                </option>
              )) || null
            }
          </select>
          {error && <div className={styles.error}>{error}</div>}
        </div>
      </label>
    </div>
  )
})

// Add display name for better debugging
Select.displayName = 'Select';

export default Select;