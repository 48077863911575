'use client'
import React, {useRef} from 'react'
import { storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc";
import { FieldStoryblok } from '@/app/types/component-types-sb'
import { useFormContext } from 'react-hook-form'
import useCheckForm from '@/utils/hooks/useCheckForm';

const FormFieldBlok = ({ blok }: { blok: FieldStoryblok }) => {
  const { setValue } = useFormContext()
  const componentRef = useRef<HTMLDivElement>(null)
  const branch = process.env.NEXT_PUBLIC_BRANCH

  const {hasFormParent, fieldPath} = useCheckForm(componentRef, blok, setValue);
  
    if ((hasFormParent === false) && (branch !== 'staging' && branch !== 'dev')) return null

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 10
    }}  ref={componentRef} {...storyblokEditable(blok)}
    data-field-name={fieldPath || blok.name.toLowerCase().replaceAll(" ", "_")}>
      {blok.fields?.map((nestedBlok) => (
        <StoryblokServerComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </div>
  )
}

export default FormFieldBlok