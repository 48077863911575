'use client'
import React, { useRef } from 'react'
import { storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc";
import { FormSectionStoryblok } from '@/app/types/component-types-sb'
import { useFormProvider } from '@/utils/providers/storyblok-form-provider';
import useCheckForm from '@/utils/hooks/useCheckForm';

const FormSectionBlok = ({ blok }: { blok: FormSectionStoryblok }) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const branch = process.env.NEXT_PUBLIC_BRANCH
  const { value, defaultValue } = useFormProvider<{
    [fieldPath: string]: string
  }>()

  const {hasFormParent} = useCheckForm(componentRef, blok);

  if ((hasFormParent === false) && (branch !== 'staging' && branch !== 'dev')) return null

  if (blok.identifier) {
    if (value === null) {
      if (defaultValue === null) return null

      if ((Object.values(defaultValue).includes(blok.identifier))) {
        return (
          <div style={
            blok.direction === 'row' ? {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 20
            } : {}
          } ref={componentRef} {...storyblokEditable(blok)} data-section-name={blok.identifier.toLowerCase().replaceAll(" ", "_")}>
            {blok.fields?.map((nestedBlok) => (
              <StoryblokServerComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
              />
            ))}
          </div>
        )
      }
    }
    if (!(Object.values(value || {}).includes(blok.identifier.toLowerCase().replaceAll(" ", "_")))) return null
  }

  return (
    <div style={
      blok.direction === 'row' ? {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20
      } : {}
    } ref={componentRef} {...storyblokEditable(blok)}>
      {blok.fields?.map((nestedBlok) => (
        <StoryblokServerComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </div>
  )
}

export default FormSectionBlok