'use client'
import React, { HTMLAttributes, MouseEventHandler, useRef } from 'react';
import styles from './Carousel.module.css';
import { useDraggable } from "react-use-draggable-scroll";
import clsx from 'clsx';
import { mergeRefs } from '@/utils/actions';

type Props = {
  children: React.ReactNode
  className?: string
  scrollRef?: React.RefObject<HTMLDivElement>
  onDrag?: MouseEventHandler<HTMLDivElement>
}

const HorizontalScrollView = ({ children, className, scrollRef, onDrag, ...props }: Props & HTMLAttributes<HTMLDivElement>) => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const touchStartX = useRef<number>(0);
  const touchStartY = useRef<number>(0);
  
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
  });

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.touches[0];
    touchStartX.current = touch.clientX;
    touchStartY.current = touch.clientY;
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!touchStartX.current || !touchStartY.current) return;

    const touch = event.touches[0];
    const deltaX = touch.clientX - touchStartX.current;
    const deltaY = touch.clientY - touchStartY.current;

    // Check if the swipe is more horizontal than vertical
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Convert touch event to mouse event for compatibility with useDraggable
      const mouseEvent = new MouseEvent('mousedown', {
        clientX: touch.clientX,
        clientY: touch.clientY,
        bubbles: true
      });

      events.onMouseDown(mouseEvent as unknown as React.MouseEvent<HTMLElement, MouseEvent>);
      
      if (onDrag) {
        onDrag(mouseEvent as any);
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
    touchStartY.current = 0;
  };

  const childrenArray = React.Children.toArray(children);

  const combinedRef = mergeRefs([ref, scrollRef]);

  return (
    <div className={styles.scrollWrapper}>
      <div
        className={clsx(styles["scroll-container"], className)}
        ref={scrollRef ?  combinedRef : ref}
        {...events}
        {...props}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={(event) => {
          events.onMouseDown(event);
          if (onDrag) {
            onDrag(event);
          }
        }}
      >
        {childrenArray.map((item, index) => (
          <div className={styles["scroll-item"]} key={index}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScrollView;