import React from 'react'
import styles from './Search.module.css'
import { Article, Category } from '@/app/types/lambda-types'
import DynamicIcon from '@/app/components/globals/dynamic-icon'
import Link from 'next/link'

type Props = {
  article?: Article
  category?: Category
}

const SearchFilterDisplay = ({ article, category }: Props) => {
  return (
    <Link href={article ? `/knowledge-base/article/${article.uuid}` : `/knowledge-base/category/${category?.uuid}`}>
      <div className={styles.searchFilterItem}>
        <DynamicIcon iconName={article?.icon || category?.icon || ''} style="solid" />
        {article ? (
          <>
            {article.name}
            <p dangerouslySetInnerHTML={{ __html: article.description }} />
          </>
        ) : <>
          {category?.name}
        </>}
      </div>
    </Link>
  )
}

export default SearchFilterDisplay