import { HotRightNowStoryblok, HotRightNowFeaturedDealsStoryblok } from '@/app/types/component-types-sb';
import React from 'react';
import Container from '@/app/components/globals/container';
import styles from './HotRightNow.module.css';
import { storyblokEditable } from '@storyblok/react';
import Image from '../../globals/image';
import HotRightNowFeaturedDeals from './HotRightNowFeaturedDeals';
import CustomLink from '../../globals/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@awesome.me/kit-d4c82d9167/icons/classic/regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


const HotRightNow = ({ blok }: { blok: HotRightNowStoryblok }) => {
  return (
    <div className='page-section' {...storyblokEditable(blok)}>
      <Container>
        <h2 className='section-header'>
          Hot Right Now
        </h2>
        <div className={styles.groupWrapper}>
          {blok.groups?.map((nestedGroup: HotRightNowFeaturedDealsStoryblok, key) => (
            <div className={styles.group} key={key} {...storyblokEditable(nestedGroup)}>
              <CustomLink className={styles.groupHeader} link={nestedGroup.link} storyBlokLink>
                <Image asset={nestedGroup.background} size={{ width: 600, height: 300 }} />
                <div className={styles.darkGradientOverlay}></div>
                <h3>{nestedGroup.title}</h3>
              </CustomLink>
              <HotRightNowFeaturedDeals blok={nestedGroup} />
              <div className={styles.groupLink}>
                <CustomLink link={nestedGroup.link} asButton={true} variant='primary' storyBlokLink>
                  View more deals like this <FontAwesomeIcon icon={faArrowRight as IconProp} />
                </CustomLink>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default HotRightNow;