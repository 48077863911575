'use client'
import styles from './WeatherChart.module.css';
import { WeatherChartStoryblok } from '@/app/types/component-types-sb';
import { storyblokEditable } from '@storyblok/react/rsc';
import { sortAndPrioritizeName } from '@/utils/actions';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import Select from '@/app/components/globals/select';
import { useState, useEffect } from 'react';
import { PortGuide, RegionWeather } from '@/app/types/lambda-types';
import { getWeatherChartData } from '@/utils/queries';
import clsx from 'clsx';
import useResponsive from '@/utils/hooks/useResponsive';


type Props = {
  blok: WeatherChartStoryblok
  initData?: RegionWeather
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
);


const WeatherChartClient = ({ blok }: Props) => {
  const lineColours = {
    border: '#F7BD02',
    background: '#F7BD02'
  }

  const barColours = {
    border: '#f4081f',
    background: '#f4081f'
  }

  const [data, setData] = useState<RegionWeather | undefined>()
  const [search, setSearch] = useState<string | undefined>(undefined);
  const {width} = useResponsive();

  useEffect(() => {
    let isMounted = true;
/**
 * Fetches weather data for the specified region and port.
 *
 * This function retrieves weather data based on the provided region and sub-region IDs.
 * It optionally considers the default port for prioritized sorting. The data is then
 * fetched again for the prioritized port ID if applicable and updates the state with
 * the fetched data.
 *
 * The function ensures that the data is only set if the component is still mounted.
 * It logs the initial response for debugging purposes.
 *
 * @returns {void}
 * @throws {Error} If the fetch operation fails.
 */

    const fetchData = async () => {

      const region_id = blok.region_id ? blok.region_id.toString().trim().length > 0 ? blok.region_id : undefined : undefined
      const sub_region_id = blok.sub_region_id ? blok.sub_region_id.toString().trim().length > 0 ? blok.sub_region_id : undefined : undefined
      const port = blok.default_port && (blok.default_port).toString().trim().length > 0 ? blok.default_port : undefined

      const response = await getWeatherChartData(region_id, sub_region_id, search)

      if (!isMounted) return;

      const dataSort = port ? sortAndPrioritizeName(response.port_guide || [], sub_region_id ? "port_guide_id" : "id", port || '') : {
        sortedArray: [],
        exists: true
      };

      if (port && (port).toString().trim().length > 0) {
        const dataResponse = await getWeatherChartData(region_id, sub_region_id, String((dataSort.sortedArray as PortGuide[])[0][sub_region_id ? "port_guide_id" : "id"]));


        if (!isMounted) return;

        setData({
          port_guide: dataSort.sortedArray as PortGuide[],
          weather: dataResponse.weather
        })
      } else {
        setData(response)
      }
    }

    fetchData()

    return () => {
      isMounted = false;
    };
  }, [])


  useEffect(() => {
    if (search === undefined)
      return;
    const fetchData = async () => {
      const region_id = blok.region_id ? blok.region_id.toString().trim().length > 0 ? blok.region_id : undefined : undefined
      const sub_region_id = blok.sub_region_id ? blok.sub_region_id.toString().trim().length > 0 ? blok.sub_region_id : undefined : undefined

      const response = await getWeatherChartData(region_id, sub_region_id, search)

      if (data?.port_guide && (data.port_guide.length > 0)) {
        setData({
          ...data,
          weather: response.weather
        })
      } else {
        setData(response)
      }
    }

    fetchData()
  }, [search])

  return (
    <div className={clsx(
      styles.weather_chart,
      {
        [styles.loading_chart]: !data
      }
    )} {...storyblokEditable(blok)}>
      {data ? (
        <>
          <Chart
            type="bar"
            {...(width <= 974) ? { height: 350, width: 350, } : {}}
            data={{
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              datasets: [
                {
                  type: 'line',
                  label: 'Precipitation Per Month (mm)',
                  data: data?.weather.map((data) => Math.floor(parseFloat(data.precip))),
                  borderColor: lineColours.border,
                  backgroundColor: lineColours.background,
                  borderWidth: 2,
                  fill: false,
                  tension: 0.2
                },
                {
                  type: 'bar',
                  label: 'Temperature (°C)',
                  data: data?.weather.map((data) => parseInt(data.average_temperature)),
                  backgroundColor: [
                    barColours.background,
                  ],
                  borderColor: [
                    barColours.border
                  ],
                  borderWidth: 0
                },
              ]
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}
          />
          <Select
            onChange={(value: string) => setSearch(value)}
            options={data?.port_guide?.map((data) => ({
              value: blok.sub_region_id ? (data.port_guide_id || 0) : data.id,
              label: data.name
            })) || []}
          />
        </>
      ) : (
        <div className={styles.loading} />
      )}
    </div>
  )
}

export default WeatherChartClient