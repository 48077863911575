'use client'
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarDay,
  faLocationDot,
  faShip,
  faChevronDown
} from "@awesome.me/kit-d4c82d9167/icons/classic/light";

import {
  faSearch
} from "@awesome.me/kit-d4c82d9167/icons/classic/solid"

import DestinationDropdown from './DestinationDropdown';
import LineShipDropdown from './LineShipDropdown';
import DateDropdown from './DateDropdown';

import startPorts from '@/data/start-ports';
import visitPorts from '@/data/visiting-ports';

import styles from './SearchBar.module.css';
import { faXmarkLarge } from '@awesome.me/kit-d4c82d9167/icons/classic/regular';

interface ApiShip {
  id: number;
  name: string;
  lineid: string;
}

export type Destination = {
  label: string;
  value: string;
  selected?: boolean;
};

export type CruiseLine = {
  label: string;
  value: string;
  selected?: boolean;
};

export type Ship = {
  label: string;
  value: string;
  group: number;
  selected?: boolean;
};

export type Port = {
  label: string;
  value: string | null;
  selected?: boolean;
};

export type DateOption = {
  label: string;
  value: string | null;
  fullDate?: string;
};

type PassengerConfig = {
  adults: number;
  children: number;
  childAges: number[];
  maxPassengers: number;
  minAdults: number;
  minChildren: number;
  minChildAge: number;
  maxChildAge: number;
};

type SearchOptions = {
  destination: Destination;
  line: CruiseLine;
  ship: Ship | null;
  date: DateOption;
  passengers: PassengerConfig;
  duration: string;
  flexibility: number;
  regionid: string | null;
  lineid: string | null;
  monthyear: string | null;
  startingPort: Port | null;
  visitingPort: Port | null;
  formno: number;
  perpage: number;
  product: string;
  sid: number;
  sortby: string;
  result_type?: "" | "any" | "csi" | "cruiseonly" | undefined;
};

export type NullableSearchOptions = {
  [K in keyof SearchOptions]: SearchOptions[K] | null;
} & {
  result_type?: "" | "any" | "csi" | "cruiseonly";
};

interface SearchBarProps {
  prefilledSearch?: NullableSearchOptions;
}

interface SearchOptionProps {
  icon: any;
  label: string;
  value: string;
  onClick: () => void;
  isOpen: boolean;
  required?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ prefilledSearch }) => {
  const [ships, setShips] = useState<Ship[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const mergePrefilledSearchOptions = () => {
    // Merge prefilled values with defaults if they exist
    if (prefilledSearch) {
      initialSearchOptions = {
        ...initialSearchOptions,
        ...Object.fromEntries(
          Object.entries(prefilledSearch)
            .filter(([, value]) => value !== null)
            .map(([key, value]) => [key, value])
        )
      };
    }
  }

  let initialSearchOptions: SearchOptions = {
    destination: { label: 'Any Destination', value: '' },
    line: { label: 'Any Cruise Line', value: '' },
    ship: null,
    date: { label: 'Select a Date*', value: null },
    passengers: {
      adults: 2,
      children: 0,
      childAges: [1, 1, 1],
      maxPassengers: 4,
      minAdults: 1,
      minChildren: 0,
      minChildAge: 1,
      maxChildAge: 11
    },
    duration: '0',
    flexibility: -1,
    regionid: null,
    lineid: null,
    monthyear: null,
    startingPort: null,
    visitingPort: null,
    formno: 1,
    perpage: 20,
    product: 'cruise',
    sid: 8218,
    sortby: 'departdate'
  };

  // Merge prefilled values with defaults if they exist
  mergePrefilledSearchOptions();

  const [selectedOptions, setSelectedOptions] = useState<SearchOptions>(initialSearchOptions);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [smartCode, setSmartCode] = useState('');
  const [isSmartCodeValid, setIsSmartCodeValid] = useState(false);

  const destinations: Destination[] = [
    { label: "Any Destination", value: "" },
    { label: "Mediterranean", value: "7" },
    { label: "Caribbean", value: "2" },
    { label: "Far East", value: "5" },
    { label: "Transatlantic", value: "11" },
    { label: "Alaska", value: "13" },
    { label: "Dubai & Emirates", value: "23" },
    { label: "South America", value: "10" },
    { label: "Africa", value: "17" },
    { label: "Australasia", value: "14" },
    { label: "Baltic", value: "20" },
    { label: "Black Sea", value: "21" },
    { label: "Canaries", value: "1" },
    { label: "Central America", value: "3" },
    { label: "China", value: "24" },
    { label: "Europe", value: "4" },
    { label: "Egypt & Red Sea", value: "25" },
    { label: "Hawaii", value: "6" },
    { label: "Iberia", value: "22" },
    { label: "Middle East", value: "19" },
    { label: "North America", value: "8" },
    { label: "Pacific", value: "15" },
    { label: "Polar Regions", value: "18" },
    { label: "Scandinavia", value: "9" },
    { label: "United Kingdom", value: "16" },
    { label: "Worldwide", value: "12" }
  ];

  const cruiseLines: CruiseLine[] = [
    { label: "Any Cruise Line", value: "" },
    { label: "MSC", value: "16" },
    { label: "Norwegian", value: "17" },
    { label: "Princess", value: "20" },
    { label: "Virgin Voyages", value: "734" },
    { label: "Costa", value: "9" },
    { label: "Celebrity", value: "3" },
    { label: "Royal Caribbean", value: "22" },
    { label: "Carnival", value: "8" },
    { label: "Holland America", value: "15" },
    { label: "AmaWaterways", value: "63" },
    { label: "Ambassador", value: "848" },
    { label: "Azamara", value: "66" },
    { label: "Celestyal", value: "495" },
    { label: "Crystal", value: "10" },
    { label: "Cunard", value: "5" },
    { label: "Disney", value: "73" },
    { label: "Explora Journeys", value: "849" },
    { label: "Fred Olsen", value: "13" },
    { label: "Marella", value: "28" },
    { label: "Oceania", value: "48" },
    { label: "P&O", value: "1" },
    { label: "Regent Seven Seas", value: "21" },
    { label: "Seabourn", value: "24" },
    { label: "Silversea", value: "25" }
  ];

  const startingPorts: Port[] = startPorts;

  const visitingPorts: Port[] = visitPorts;

  const durations = [
    { label: 'Any Duration', value: '0', selected: true },
    { label: '1-5 Nights', value: '1', selected: false },
    { label: '6-10 Nights', value: '2', selected: false },
    { label: '11-16 Nights', value: '3', selected: false },
    { label: '17-30 Nights', value: '4', selected: false },
    { label: '31 Nights+', value: '5', selected: false }
  ];

  const flexibility = [
    { label: 'Days +/-', value: -1, selected: true },
    { label: '0 Days', value: 0, selected: false },
    { label: '+/- 1 Day', value: 1, selected: false },
    { label: '+/- 2 Days', value: 2, selected: false },
    { label: '+/- 3 Days', value: 3, selected: false },
    { label: '+/- 4 Days', value: 4, selected: false },
    { label: '+/- 5 Days', value: 5, selected: false }
  ];

  const SearchOption: React.FC<SearchOptionProps> = ({
    icon,
    label,
    value,
    onClick,
    isOpen,
    required
  }) => (
    <div className={`${styles.option} ${isOpen ? styles.optionOpen : ''}`}>
      <button onClick={onClick} className={styles.optionButton}>
        <span className={styles.optionLabel}>
          <FontAwesomeIcon icon={icon} className={styles.optionIcon} />
          <span className={styles.optionText}>
            <span className={styles.optionLabelText}>{label}{required && '*'}</span>
            <span className={styles.optionLabelValue}>{value}</span>
          </span>
        </span>
        <span className={styles.optionChevron}>
          <FontAwesomeIcon icon={faChevronDown as IconProp} />
        </span>
      </button>
    </div>
  );

  const validateSmartCode = (code: string): boolean => {
    return code.length === 11 && /[A-Z]{3}\d{7}[CIS]/.test(code);
  };

  const handleSmartCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase().replace(' ', '');
    setSmartCode(value);
    setIsSmartCodeValid(validateSmartCode(value));
  };

  const handleDestinationChange = (destination: Destination) => {
    setSelectedOptions(prev => ({
      ...prev,
      destination,
      regionid: destination.value
    }));
    // setOpenDropdown(null);
  };

  const handleStartingPortChange = (port: Port) => {
    setSelectedOptions(prev => ({
      ...prev,
      startingPort: port
    }));
  };

  const handleVisitingPortChange = (port: Port) => {
    setSelectedOptions(prev => ({
      ...prev,
      visitingPort: port
    }));
  };

  const handleLineChange = (line: CruiseLine) => {
    setSelectedOptions(prev => ({
      ...prev,
      line,
      lineid: line.value,
      ship: null // Reset ship when line changes
    }));
    // setOpenDropdown(null);
  };

  const handleShipChange = (ship: Ship) => {
    setSelectedOptions(prev => ({
      ...prev,
      ship
    }));
  };

  const handleDateChange = (date: DateOption) => {
    setSelectedOptions(prev => ({
      ...prev,
      date,
      monthyear: date.value
    }));
  };

  const handleSearch = () => {
    if (window.innerWidth <= 992 && !mobileOpen) {
      return setMobileOpen(true);
    }

    if (!selectedOptions.date.value) {
      setOpenDropdown('date');
      return;
    }

    let urlParams = 'adults=2&children=0&product=cruise&type=any&formno=1&page=1&sid=8218&sortby=start_date';

    if (selectedOptions.date.value)
      urlParams += `&monthyear=${selectedOptions.date.value}`;
    if (selectedOptions.line.value != '')
      urlParams += `&lineid=${selectedOptions.line.value}`;
    if (selectedOptions.ship && selectedOptions.ship?.value != '')
      urlParams += `&shipid=${selectedOptions.ship.value}`;
    if (selectedOptions.startingPort && selectedOptions.startingPort?.value && selectedOptions.startingPort?.value != '')
      urlParams += `&startport=${selectedOptions.startingPort.value}`;
    if (selectedOptions.visitingPort && selectedOptions.visitingPort?.value && selectedOptions.visitingPort?.value != '')
      urlParams += `&port=${selectedOptions.visitingPort.value}`;
    if (selectedOptions.date.fullDate) {
      urlParams += `&date=${selectedOptions.date.fullDate}`;
      urlParams += `&day=${selectedOptions.date.fullDate.split('-')[2]}`
    } else {
      const monthYearSplit = selectedOptions.date.value.split('_');
      urlParams += `&date=${monthYearSplit[1]}-${monthYearSplit[0]}-01`;
    }
    if (selectedOptions.regionid && selectedOptions.regionid != '')
      urlParams += `&regionid=${selectedOptions.regionid}`;
    urlParams += `&duration=${selectedOptions.duration}`
    if (selectedOptions.flexibility === -1)
      urlParams += `&daysplusminus=${3}`;
    else
      urlParams += `&daysplusminus=${selectedOptions.flexibility}`;
    if (selectedOptions.result_type === undefined || selectedOptions.result_type === 'any')
      urlParams += `&type=any`;
    else
      urlParams += `&type=${selectedOptions.result_type}`;

    /*
    &type=any
    */


    window.location.href = `https://cruisenation.com/search-results?${urlParams}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openDropdown && !(event.target as Element).closest(`.${styles.searchBar}`)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [openDropdown]);

  useEffect(() => {
    const fetchShips = async () => {
      try {
        const response = await fetch('https://api.cruisenation.com/api/ships/');
        if (!response.ok) {
          throw new Error('Failed to fetch ships');
        }
        const data: ApiShip[] = await response.json();

        const transformedShips: Ship[] = data.map(ship => ({
          label: ship.name,
          value: ship.id.toString(),
          group: parseInt(ship.lineid),
          selected: false
        }));

        setShips(transformedShips);
        if (initialSearchOptions.ship && initialSearchOptions.ship.label === "") {
          const selectedShip = transformedShips.find(ship => ship.value === `${initialSearchOptions.ship?.value}`);
          const newSelectedOptions = selectedOptions;
          newSelectedOptions.ship = selectedShip || null;
          setSelectedOptions(newSelectedOptions);
        }
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch ships');
        setIsLoading(false);
      }
    };

    fetchShips();
  }, []);

  return (
    <>
      <div className={`${styles.advancedRegion} ${!mobileOpen ? styles.advancedRegionMobileClosed : styles.advancedRegionMobileOpen}`}>
        <div className={styles.innerWrapper}>
          {openDropdown && (
            <div className={styles.dropdownExit} onClick={() => setOpenDropdown(null)} />
          )}
          <div className={`${styles.searchBar} ${openDropdown ? styles.searchBarOpen : ''}`}>
            <div className={styles.options}>
              <SearchOption
                icon={faLocationDot}
                label={selectedOptions.destination.value === '' && selectedOptions.startingPort ? 'Sailing From' : 'Destination'}
                value={selectedOptions.destination.value === '' && selectedOptions.startingPort ? selectedOptions.startingPort.label : selectedOptions.destination.label}
                onClick={() => setOpenDropdown(openDropdown === 'destination' ? null : 'destination')}
                isOpen={openDropdown === 'destination'}
              />
              <SearchOption
                icon={faShip}
                label={selectedOptions.ship ? "Cruise Ship" : "Cruise Line"}
                value={selectedOptions.ship ? selectedOptions.ship.label : selectedOptions.line.label}
                onClick={() => setOpenDropdown(openDropdown === 'line' ? null : 'line')}
                isOpen={openDropdown === 'line'}
              />
              <SearchOption
                icon={faCalendarDay}
                label="Date"
                value={selectedOptions.date.label}
                onClick={() => setOpenDropdown(openDropdown === 'date' ? null : 'date')}
                isOpen={openDropdown === 'date'}
                required
              />

              {openDropdown && (
                <div className={styles.dropdown}>
                  {openDropdown === 'destination' && (
                    <DestinationDropdown
                      destinations={destinations}
                      startingPorts={startingPorts}
                      visitingPorts={visitingPorts}
                      selectedDestination={selectedOptions.destination}
                      selectedStartingPort={selectedOptions.startingPort}
                      selectedVisitingPort={selectedOptions.visitingPort}
                      onDestinationChange={handleDestinationChange}
                      onStartingPortChange={handleStartingPortChange}
                      onVisitingPortChange={handleVisitingPortChange}
                    />
                  )}

                  {openDropdown === 'line' && (
                    <LineShipDropdown
                      cruiseLines={cruiseLines}
                      ships={ships}
                      isLoading={isLoading}
                      error={error}
                      selectedLine={selectedOptions.line}
                      selectedShip={selectedOptions.ship}
                      onLineChange={handleLineChange}
                      onShipChange={handleShipChange}
                    />
                  )}

                  {openDropdown === 'date' && (
                    <DateDropdown
                      selectedDate={selectedOptions.date}
                      onDateChange={handleDateChange}
                      durations={durations}
                      flexibility={flexibility}
                      selectedDuration={selectedOptions.duration}
                      selectedFlexibility={selectedOptions.flexibility}
                      onDurationChange={(duration) =>
                        setSelectedOptions(prev => ({ ...prev, duration }))
                      }
                      onFlexibilityChange={(flexibility) =>
                        setSelectedOptions(prev => ({ ...prev, flexibility }))
                      }
                    />
                  )}
                </div>
              )}
            </div>

            <button aria-label='Search' onClick={handleSearch} className={styles.searchButton}>
              <FontAwesomeIcon icon={faSearch as IconProp} className="mr-2" />
              SEARCH
            </button>
          </div>

          <button className={styles.mobileClose} onClick={() => setMobileOpen(false)}>
            <FontAwesomeIcon icon={faXmarkLarge as IconProp} />
          </button>

          <div className={styles.extras} style={{ display: 'none' }}>
            <div className={`${styles.extrasSection} ${isSmartCodeValid ? styles.extrasSectionValid : ''}`}>
              <input
                type="text"
                maxLength={11}
                className={`${styles.smartCode} ${smartCode ? styles.smartCodeHasContent : ''}`}
                placeholder="Search by Smart Code"
                value={smartCode}
                onChange={handleSmartCodeChange}
              />
              <button
                className={styles.smartCodeButton}
                aria-label='Look-up Smart Code'
                disabled={!isSmartCodeValid}
              >
                LOOK-UP
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;