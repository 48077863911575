import Image from '@/app/components/globals/image';
import styles from './PackageIcon.module.css';

interface PackageIconProps {
  holidayType: 'csi' | 'cruiseonly' | 'static'
}

const PackageIcon = ({ holidayType }: PackageIconProps) => {
  const asset = holidayType === 'cruiseonly' ?
    'https://a.storyblok.com/f/306333/229x150/f6dc22dfee/cruise-only-icon-white.svg' :
    'https://a.storyblok.com/f/306333/116x150/80a9d24de6/smart-icon-white.svg';

  return (
    <div className={styles.wrapper}>
      <Image asset={asset} className={styles.image} />
    </div>
  )
}

export default PackageIcon;
