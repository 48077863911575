import React from 'react';
import { storyblokEditable, StoryblokServerComponent } from "@storyblok/react/rsc";
import type { CardStoryblok } from '@/app/types/component-types-sb';
import { blokReslover } from '../content_split_2_images/ContentSplitTwoImageTextImage';
import { render } from 'storyblok-rich-text-react-renderer';
import styles from './Card.module.css';
import Image from '../../globals/image';

const Card = ({ blok }: { blok: CardStoryblok }) => {
  return (
    <div className={styles.card} {...storyblokEditable(blok)}>
      <div className={styles.title}>
      <div className={styles.titleSection}>
        {blok.icon && (
          <div className={styles.icon}>
            <Image
          useSource={true}
          asset={blok.icon}
          />
          </div>)}
        {blok.title && <h3>{blok.title}</h3>}
      </div>
      {blok.description && (
        <div className={styles.description}>
          {render(blok.description, {
            defaultBlokResolver: (name, props) => blokReslover(name, props),
          })}
        </div>
      )}
      </div>
      {blok.children?.map((nestedBlok) => (
        <StoryblokServerComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </div>
  );
}

export default Card;