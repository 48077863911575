'use client'
import { CategoryViewStoryblok } from "@/app/types/component-types-sb";
import React, { useState } from "react";
import styles from "./CategoryView.module.css";
import { storyblokEditable } from "@storyblok/react/rsc";
import PageSection from "@/app/components/globals/page_section";
import Container from "@/app/components/globals/container";
import type {
  KnowledgeBaseChildrenRoutes,
  Category as CategoryType, Article as ArticleType
} from "@/app/types/lambda-types";
import Category from "./Category";
import Article from "./Article";

interface CategoryProps{
  result: KnowledgeBaseChildrenRoutes
  blok: CategoryViewStoryblok
}

const CategoryView = ({ result, blok }: CategoryProps) => {
  const [response,] = useState<KnowledgeBaseChildrenRoutes>(result);

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h1>{blok.title}</h1>
          <div className={styles.categories}>
            {response &&
              response.articles.map((article: ArticleType) => (
                <Article article={article} key={article.uuid} />
              ))}
          </div>
          <br />
          <br />
          <div className={styles.categories}>
            {response &&
              response.categories.map((category: CategoryType) => (
                <Category category={category} key={category.uuid} />
              ))}
          </div>
        </Container>
      </PageSection>
    </div>
  );
};

export default CategoryView;
