import React from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import type { GridItemStoryblok } from "@/app/types/component-types-sb";
import styles from '../grid-layout.module.css';
import { render } from "storyblok-rich-text-react-renderer";
import CustomLink from "@/app/components/globals/link";
import Image from "@/app/components/globals/image";
import clsx from "clsx";

const GridItem = ({ blok }: { blok: GridItemStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} id={blok.header || undefined} className={clsx(styles.gridItem, 'grid_item')}>
      {
        blok.header_image ?
          <CustomLink link={blok.link} className={styles.image} storyBlokLink>
            <Image
              asset={blok.header_image}
              alt={blok.header_image.alt || ''}
              size={{ width: 540, height: 300 }}
              useSource={false}
              sourceSet={{
                '767': {  // Add tablet breakpoint
                  size: {
                    width: 700,
                    height: 300
                  },
                  crop: true
                },
                '1024': {  // Desktop size
                  size: {
                    width: 640,
                    height: 300
                  },
                  crop: true
                },
                '1224': {  // Desktop size
                  size: {
                    width: 540,
                    height: 300
                  },
                  crop: false
                }
              }}
            />
          </CustomLink>
          : null
      }
      {
        blok.header ?
          <h2 className={styles.header}>
            <CustomLink link={blok.link} className={styles.link} storyBlokLink>
              {blok.header}
            </CustomLink>
          </h2>
          : null
      }
      {
        blok.excerpt ?
          <div className={styles.excerpt}>
            {render(blok.excerpt)}
          </div>
          : null
      }
      {
        blok.link ?
          <p>
            <CustomLink link={blok.link} className={styles.link} storyBlokLink>{blok.link_text}</CustomLink>
          </p>
          : null
      }
    </div>
  );
}

export default GridItem;