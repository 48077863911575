import React from 'react'
import styles from './DynamicGroup.module.css'
import Button from '../button'

type Props = {
  current?: string
  setSort: (sort: string) => void
}

const SortButtons = ({ current, setSort }: Props) => {
  return (
    <div className={styles.sort}>
      <label>Sort By</label>
      <div className={styles.sortButtons}>
        <Button onClick={() => setSort('price_pp')} variant={current === 'price_pp' ? 'primary' : 'outline'}>Price</Button>
        <Button onClick={() => setSort('start_date')} variant={current === 'start_date' ? 'primary' : 'outline'}>Date</Button>
        <Button onClick={() => setSort('duration')} variant={current === 'duration' ? 'primary' : 'outline'}>Duration</Button>
      </div>
    </div>
  )
}

export default SortButtons