import { XOfTheWeekStoryblok, XOfTheWeekSlideStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';
import React from 'react';
import Image from '../../globals/image';
import CustomLink from '../../globals/link';
import styles from './XOfTheWeek.module.css';
import { blokReslover } from "../content_split_2_images/ContentSplitTwoImageTextImage";


const XOfTheWeek = ({ blok }: { blok: XOfTheWeekStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className="page-section">
      {blok.slides?.map((nestedBlok: XOfTheWeekSlideStoryblok, index) => (
        <div className={styles.slide} key={index}>
          <div className={styles.imageContainer}>
            <Image asset={nestedBlok.image} />
          </div>
          <div className="container container--expanded">
            <div className={styles.textContainer}>
              <h2>{nestedBlok.title}</h2>
              {render(nestedBlok.content, {
                defaultBlokResolver: (name, props) => blokReslover(name, props)
              })}
              <CustomLink link={nestedBlok.link} className={styles.link} storyBlokLink>View Deals</CustomLink>
            </div>
          </div>
        </div>
      ))}

    </div>
  )
}

export default XOfTheWeek;