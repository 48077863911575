import React from 'react'
import ErrorComponent from '../components/globals/error-component'

/**
 * A HOC that wraps a ServerComponent with error handling.
 *
 * If the ServerComponent throws, it will be caught and an ErrorComponent will be rendered
 * instead, with the given message and error (if showError is true).
 * If the current branch is not staging or dev, the error will be swallowed and null will be
 * rendered. COOL RIGHT??????? ✌🏼👌🏽😎
 *
 * @param ServerComponent - The server component to wrap
 * @param message - The message to display in the ErrorComponent
 * @param showError - Whether to include the error in the ErrorComponent props
 * @returns A wrapped version of the ServerComponent
 */
const withErrorHandling = (ServerComponent: any, message: string, showError: boolean = true) => {
  const branch = process.env.NEXT_PUBLIC_BRANCH

  const WrappedComponent =  async (props: any) => {
    try {
      // Try to render the original component
      return await ServerComponent(props);
    } catch (error) {
      console.error(`Error in ${ServerComponent.name || 'server component'}:`, error);
      if (branch !== 'staging' && branch !== 'dev') return null
      return (
        <ErrorComponent message={message} {...(
          showError ? { error } : {}
        )} />
      );
    }
  };

  WrappedComponent.displayName = `withErrorHandling(${ServerComponent.name || 'Component'})`;
  return WrappedComponent;
}

export default withErrorHandling