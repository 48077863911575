import { BlogPostsStoryblok } from "@/app/types/component-types-sb"
import { storyblokEditable } from '@storyblok/react'
import Container from "../../globals/container"
import { getBlogPosts } from "@/utils/queries"
import he from 'he'

import styles from './BlogPosts.module.css';
import Image from "../../globals/image"
import CustomLink from "../../globals/link"
import { WordPressPost } from "@/app/types/wordpress-types"
import withErrorHandling from "@/app/lib/withErrorHandling"

const BlogPosts = async ({ blok }: { blok: BlogPostsStoryblok }) => {

  const perPage = blok.per_page ? parseInt(blok.per_page, 10) : undefined;
  const page = blok.page ? parseInt(blok.page, 10) : undefined;
  const blogPosts: WordPressPost[] = await getBlogPosts(perPage, page);

  return (
    <div className="page-section" {...storyblokEditable(blok)}>
      <Container>
        <h3>From the blog...</h3>
        <div className={styles.posts}>
          {blogPosts.map((post, key) => (
            <CustomLink key={key} className={styles.post} href={post.link}>
              <Image
                alt={post._embedded?.["wp:featuredmedia"]?.[0].media_details.image_meta.caption}
                asset={post._embedded?.["wp:featuredmedia"]?.[0].source_url || ''}
              />
              <div className={styles.darkGradientOverlay}></div>
              <h4 className={styles.header}>{he.decode(post.title.rendered)}</h4>
            </CustomLink>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default withErrorHandling(BlogPosts, "Could not display the BlogPosts component");