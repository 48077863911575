import Feature from './storyblok-globals/feature';
import Grid from './storyblok-globals/grid';
import Page from './storyblok-globals/page';
import Teaser from './storyblok-globals/teaser';
import Header from './storyblok-globals/header';
import Content from './storyblok-globals/content';
import Column from './storyblok-globals/column';
import VerticalSplit from './storyblok-globals/vertical_split';
import StackedImages from './storyblok-globals/stacked_images';
import MediumSlider from './storyblok-globals/medium_slider';
import MediumSliderSlide from './storyblok-globals/medium_slider/MediumSliderSlide';
import LargeSlider from './storyblok-globals/large_slider';
import LargeSliderSlide from './storyblok-globals/large_slider/LargeSliderSlide';
import FullWidthImage from './storyblok-globals/full_width_image';
import StarBuy from './storyblok-globals/star_buy';
import Promotion from './storyblok-globals/promotion';
import Fleet from './storyblok-globals/fleet';
import Group from './storyblok-globals/group';
import FAQ from './storyblok-globals/faq/';
import VideoBackground from './storyblok-globals/video_background';
import InspirationalIntro from './storyblok-globals/inspirational_intro';
import DestinationHightlight from './storyblok-globals/destination_highlights/destination_highlight';
import DestinationHightlights from './storyblok-globals/destination_highlights';
import WeatherChart from './storyblok-globals/weather_chart';
import ThingsToDo from './storyblok-globals/things_to_do';
import ThingsToDoItem from './storyblok-globals/things_to_do/things_to_do_item';
import StoryBlokTable from './storyblok-globals/table_storyblok';
import ContentSplit2Images from './storyblok-globals/content_split_2_images';
import ContentTextImageSurround from './storyblok-globals/content_text_image_surround';
import StoryBlokMapBox from './storyblok-globals/story_blok_mapbox';
import KnowledgeBaseHeader from './storyblok-globals/knowledge-base/header';
import Categories from './storyblok-globals/knowledge-base/categories';
import Search from './storyblok-globals/knowledge-base/search';
import SearchResults from './storyblok-globals/knowledge-base/search_results';
import ShipDetails from './storyblok-globals/ship_details';
import Slider from './storyblok-globals/slider';
import SearchBarComponent from './storyblok-globals/search-bar';
import TabbedFeaturedDeals from './storyblok-globals/tabbed_featured_deals';
import FullSizeCarousel from './storyblok-globals/full_size_carousel';
import WhyCruiseNation from './storyblok-globals/why_cruise_nation';
import CruiseLinePromoCarousel from './storyblok-globals/cruise_line_promo_carousel';
import XOfTheWeek from './storyblok-globals/x_of_the_week';
import HotRightNow from './storyblok-globals/hot_right_now';
import BlogPosts from './storyblok-globals/blog-posts';
import ShipOverview from './storyblok-globals/ship_overview';
import ArticleWrapper from './storyblok-globals/knowledge-base/article_view';
import CategoryWrapper from './storyblok-globals/knowledge-base/category_view';
import WhatsIncludedData from './storyblok-globals/whats_included';
import SignupForm from './storyblok-globals/signup-form';
import TabbedGroupCollection from './storyblok-globals/tabbed_group_collection';
import VideoYouTube from './storyblok-globals/yotube_video';
import ArticleGroup from './storyblok-globals/knowledge-base/article_group';
import StoryblokForm from './storyblok-globals/form';
import FormTextArea from './storyblok-globals/form/TextArea';
import FormSelect from './storyblok-globals/form/Select';
import FormFieldBlok from './storyblok-globals/form/Field';
import FormInputField from './storyblok-globals/form/InputField';
import FormSectionBlok from './storyblok-globals/form/FormSection';
import FormSectionSelection from './storyblok-globals/form/SectionSelection';
import Card from './storyblok-globals/card';
import GridLayout from './storyblok-globals/grid-layout';
import GridItem from './storyblok-globals/grid-layout/grid-item';
import ImageSlider from './storyblok-globals/image_slider';
import FuzzySearch from './storyblok-globals/fuzzy-search';
import ScheduledContent from './storyblok-globals/scheduled_content';

const components = {
    feature: Feature,
    grid: Grid,
    page: Page,
    header: Header,
    teaser: Teaser,
    content: Content,
    inspirational_intro: InspirationalIntro,
    column: Column,
    vertical_split: VerticalSplit,
    stacked_images: StackedImages,
    medium_slider: MediumSlider,
    medium_slider_slide: MediumSliderSlide,
    destination_highlight: DestinationHightlight,
    destination_highlights: DestinationHightlights,
    large_slider: LargeSlider,
    large_slider_slide: LargeSliderSlide,
    things_to_do: ThingsToDo,
    ship_overview: ShipOverview,
    things_to_do_item: ThingsToDoItem,
    ['content-split-2-images']: ContentSplit2Images,
    ['content-text-image-surround']: ContentTextImageSurround,
    slider: Slider,
    form: StoryblokForm,
    card: Card,
    inputfield: FormInputField,
    form_section: FormSectionBlok,
    section_selection: FormSectionSelection,
    textarea: FormTextArea,
    select: FormSelect,
    tabbed_featured_deals: TabbedFeaturedDeals,
    category_view: CategoryWrapper,
    whats_included_section: WhatsIncludedData,
    field: FormFieldBlok,
    article_view: ArticleWrapper,
    search_results: SearchResults,
    ship_details: ShipDetails,
    knowledge_base_search: Search,
    knowledge_base_header: KnowledgeBaseHeader,
    article_group: ArticleGroup,
    categories: Categories,
    image_slider: ImageSlider,
    mapbox: StoryBlokMapBox,
    star_buy: StarBuy,
    group: Group,
    table: StoryBlokTable,
    full_width_image: FullWidthImage,
    promotion: Promotion,
    weather_chart: WeatherChart,
    fleet: Fleet,
    faq: FAQ,
    video_background: VideoBackground,
    search_bar: SearchBarComponent,
    fuzzy_search: FuzzySearch,
    full_size_carousel: FullSizeCarousel,
    why_cruise_nation: WhyCruiseNation,
    cruise_line_promo_carousel: CruiseLinePromoCarousel,
    x_of_the_week: XOfTheWeek,
    hot_right_now: HotRightNow,
    blog_posts: BlogPosts,
    signup_form: SignupForm,
    tabbed_group_collection: TabbedGroupCollection,
    youtube_video: VideoYouTube,
    grid_layout: GridLayout,
    grid_item: GridItem,
    scheduled_content: ScheduledContent,
};

export default components;