import { Filter, GroupResults, Holiday } from '@/app/types/lambda-types'
import React, { useEffect, useState } from 'react'
import Pagination from '../pagination'
import GroupItem from './GroupItem'
import styles from './DynamicGroup.module.css'
import clsx from 'clsx'
import { generate1DArray } from '@/utils/actions'
import SortButtons from './SortButtons'
import Select from '../select'

type Props = {
  group?: GroupResults | Holiday[]
  data_field: "" | "1" | "2"
  isLoading?: boolean
  initSort?: string
  onPageChange?: (page: number) => void
  onFiltersChange?: (filters: { [key: string]: string }) => void
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  show_pagination?: boolean
  initialFilters?: Filter
  uuid?: string
}

const DynamicGroup = ({ group, onPageChange, /*uuid,*/ isLoading, show_pagination, data_field, initSort, onFiltersChange, setIsLoading, initialFilters }: Props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [sort, setSort] = useState<string | undefined>(initSort);

  useEffect(() => {
    setPages((group as GroupResults)?.pages || 1);
    setPage(1);
  }, [(group as GroupResults)?.pages || 1]);

  useEffect(() => {
    onPageChange?.(page);
    setIsLoading?.(true);
  }, [page]);

  useEffect(() => {
    onFiltersChange?.(filters);
  }, [filters]);


  const getNextMonths = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startMonth = start.getMonth();
    const startYear = start.getFullYear();
    const endMonth = end.getMonth();
    const endYear = end.getFullYear();

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const result = [];

    for (let year = startYear; year <= endYear; year++) {
      const monthStart = year === startYear ? startMonth : 0;
      const monthEnd = year === endYear ? endMonth : 11;

      for (let month = monthStart; month <= monthEnd; month++) {
        result.push(`${months[month]} ${year}`);
      }
    }

    return result;
  }

  const parseDate = (date: string) => {
    const monthMap = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    const [month, year] = date.split(' ');

    if (!monthMap[month as keyof typeof monthMap] || isNaN(year as unknown as number)) {
      throw new Error(`Invalid month-year format: ${date}`);
    }

    // Return formatted date
    return `${year}-${monthMap[month as keyof typeof monthMap]}-01`;
  }

  return (
    <div className={styles.container}>

      {data_field !== "2" && (
        <div className={styles.filterAndSort}>
          <div className={styles.filters}>
            <Select
              onChange={(value) => {
                setFilters({ ...filters, ship: value })
                setIsLoading?.(true);
              }}
              label='Filter By Ship'
              aria-label='Select Ship'
              options={
                [
                  {
                    value: "*",
                    label: "Show All Ship"
                  },
                  ...initialFilters!.ships.map((ship) => (
                    { value: ship.value, label: ship.name }
                  ))
                ]
              }
            />

            <Select
              onChange={(value) => {
                setFilters({ ...filters, region: value })
                setIsLoading?.(true);
              }}
              label='Filter By Region'
              aria-label='Select Region'
              options={
                [
                  {
                    value: "*",
                    label: "Show All Regions"
                  },
                  ...initialFilters!.regions.map((region) => (
                    { value: region.value, label: region.name }
                  ))
                ]
              }
            />

            <Select
              onChange={(value) => {
                setFilters({ ...filters, date: value })
                setIsLoading?.(true);
              }}
              label='Filter By Date'
              aria-label='Select Date'
              options={
                [
                  {
                    value: "*",
                    label: "Show All Dates"
                  },
                  ...getNextMonths(initialFilters!.dates.earliest_date, initialFilters!.dates.latest_date).map((date) => (
                    { value: parseDate(date), label: date }
                  ))
                ]
              }
            />
          </div>

          <SortButtons current={sort} setSort={(change) => {
            setSort(change);
            setFilters({ ...filters, sort_by: change })
            setIsLoading?.(true);
          }} />
        </div>
      )}
      {(data_field !== "2" && (show_pagination === undefined || show_pagination === true)) &&
        (
          <Pagination page={page} setPage={setPage} pages={pages} loading={isLoading} />
        )
      }
      {isLoading === true ? (
        <div className={styles["grid-container"]}>
          {generate1DArray(3, 4).map((_, rowIndex) => (
            <div key={rowIndex} className={clsx(styles["grid-item"], styles["shimmer"])}></div>
          ))}
        </div>
      ) : (
        <div className={styles.holidays}>
          {data_field === "2" ? (
            <>
              {group && (Array.isArray(group)) && (group as Holiday[])?.map((holiday, index) => (
                <GroupItem holiday={holiday} key={index} />
              ))}
            </>
          ) : (
            <>
              {(group as GroupResults)?.collection && (group as GroupResults).collection.map((holiday, index) => (
                <GroupItem holiday={holiday} key={index} />
              ))}
            </>
          )}
        </div>
      )}
      {(data_field !== "2" && (show_pagination !== false)) && (
        <Pagination page={page} setPage={setPage} pages={pages} loading={isLoading} />
      )}
    </div>
  )
}

export default DynamicGroup