'use client'
import React from 'react'
import Button from '../button'
import { useRouter } from 'next/navigation'

type Props = {
  reset: any
}

const ReloadButton = ({reset}: Props) => {
  const router = useRouter();

  const handleRetry = () => {
    if (reset && typeof reset === 'function') {
      reset();
    } else {
      router.refresh();
    }
  };
  return (
    <Button onClick={handleRetry}>
      Try Again
    </Button>
  )
}

export default ReloadButton