'use client'
import { WhatsIncludedItemStoryblok, WhatsIncludedSectionStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React from 'react'
import PageSection from '../../globals/page_section';
import styles from './WhatsIncluded.module.css';
import Container from '../../globals/container';
import { WhatsIncludedType } from '@/app/types/lambda-types';
import WhatsIncludedItem from './WhatsIncludedItem';
import { motion } from 'framer-motion';

interface Props {
  blok: WhatsIncludedSectionStoryblok
  whatsIncluded: WhatsIncludedType[] | WhatsIncludedItemStoryblok[]
}

const WhatsIncluded = ({ blok, whatsIncluded }: Props) => {

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <motion.h2
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className={styles.title}
          >
            {blok.title}
          </motion.h2>
          <div className={styles.whatsIncluded}>
            {whatsIncluded.map((item, index) => (
              <motion.div
                {...{
                  initial: { opacity: 0, y: 25 },
                  whileInView: { opacity: 1, y: 0 },
                  viewport: { once: true },
                  transition: { duration: 0.6, delay: Math.min(-0.075 + (0.075 * index), 0.6), ease: "easeInOut" }
                }}
                key={index}
              >
                <WhatsIncludedItem blok={item} />
              </motion.div>
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  )
}

export default WhatsIncluded