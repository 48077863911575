import { Holiday } from "@/app/types/lambda-types"
import styles from './ShipModal.module.css';
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/thin";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Image from '@/app/components/globals/image';
import CustomLink from "../../link";

type Props = {
  holiday: Holiday,
  clickPosition?: { x: number, y: number }
}

const ShipModal = ({ holiday, clickPosition }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const ship = holiday.ship;

  // Modal handling functions
  const getScrollbarWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
  };

  const lockScroll = () => {
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    document.body.classList.add('lock-scroll');
  };

  const unlockScroll = () => {
    document.body.classList.remove('lock-scroll');
    document.body.style.removeProperty('--scrollbar-width');
  };

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.classList.add(styles.closing);
      dialogRef.current.addEventListener('animationend', () => {
        dialogRef.current?.close();
        dialogRef.current?.classList.remove(styles.closing);
        unlockScroll();
      }, { once: true });
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const dialogDimensions = dialogRef.current?.getBoundingClientRect();
    if (dialogDimensions) {
      const clickedInDialog = e.clientY >= dialogDimensions.top &&
        e.clientY <= dialogDimensions.bottom &&
        e.clientX >= dialogDimensions.left &&
        e.clientX <= dialogDimensions.right;

      if (!clickedInDialog) {
        handleClose();
      }
    }
  };

  // Effects
  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    // Watch for dialog open attribute
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
          if (dialog.hasAttribute('open')) {
            lockScroll();
          }
        }
      });
    });

    observer.observe(dialog, { attributes: true });

    return () => {
      observer.disconnect();
      unlockScroll(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') handleClose();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (dialogRef.current && clickPosition) {
      const translateX = clickPosition.x - (window.innerWidth / 2);
      const translateY = clickPosition.y - (window.innerHeight / 2);

      dialogRef.current.style.setProperty('--start-x', `${translateX}px`);
      dialogRef.current.style.setProperty('--start-y', `${translateY}px`);
    }
  }, [clickPosition]);

  if (!ship) return null;

  return (
    <dialog
      id={`ship-modal-${holiday.cn_uid}`}
      ref={dialogRef}
      className={styles.modal}
      onClick={handleClick}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h4>{ship.name}</h4>
          <button
            onClick={handleClose}
            className={styles.closeButton}
          >
            <FontAwesomeIcon icon={faXmark as IconProp} />
          </button>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.shipImage}>
            <Image asset={ship.search_results_image || ''} alt={ship.name} />
          </div>

          <div className={styles.shipDetails}>
            <div className={styles.shipInfo}>
              <h5>About {ship.name}</h5>
              <p>{ship.description || 'No description available.'}</p>
            </div>

            <div className={styles.shipSpecs}>
              <h5>Ship Specifications</h5>
              <div className={styles.specsGrid}>
                <div className={styles.specItem}>
                  <span className={styles.specLabel}>Passenger Capacity</span>
                  <span className={styles.specValue}>{ship.capacity || 'N/A'}</span>
                </div>
                <div className={styles.specItem}>
                  <span className={styles.specLabel}>Crew Members</span>
                  <span className={styles.specValue}>{ship.crew || 'N/A'}</span>
                </div>
                <div className={styles.specItem}>
                  <span className={styles.specLabel}>Tonnage</span>
                  <span className={styles.specValue}>{ship.tonnage || 'N/A'}</span>
                </div>
                <div className={styles.specItem}>
                  <span className={styles.specLabel}>Length</span>
                  <span className={styles.specValue}>{ship.length || 'N/A'}</span>
                </div>
                <div className={styles.specItem}>
                  <span className={styles.specLabel}>Speed</span>
                  <span className={styles.specValue}>{ship.speed || 'N/A'}</span>
                </div>
                <div className={styles.specItem}>
                  <span className={styles.specLabel}>Launched</span>
                  <span className={styles.specValue}>{ship.launched || 'N/A'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.modalFooter}>
          <CustomLink href={holiday.ship?.override_url} variant="primary" color="red" className={styles.viewButton} asButton>
            View Ship
          </CustomLink>
        </div>
      </div>
    </dialog>
  );
}

export default ShipModal; 